<div>
  <div class="card card-container">
    <img id="profile-img" src="/assets/images/avatar_2x.png" class="profile-img-card" />
    <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <mat-form-field class="form-group">
        <mat-label for="name">Name</mat-label>
        <input matInput type="text" class="form-control" id="name" name="name" [(ngModel)]="form.name" required
          minlength="3" maxlength="20" #name="ngModel" />
      </mat-form-field>
      <mat-form-field class="form-group">
        <mat-label for="surname">Surname</mat-label>
        <input matInput type="text" class="form-control" id="surname" name="surname" [(ngModel)]="form.surname" required
          minlength="3" maxlength="20" #surname="ngModel" />
      </mat-form-field>
      <mat-form-field class="form-group">
        <mat-label for="username">Username</mat-label>
        <input matInput type="text" class="form-control" id="username" name="username" [(ngModel)]="form.username"
          required minlength="3" maxlength="20" #username="ngModel" />
        <mat-error *ngIf="username.errors && username.errors['required']">Username is required</mat-error>
        <mat-error *ngIf="username.errors && username.errors['minlength']">
          Username must be at least 3 characters
        </mat-error>
        <mat-error *ngIf="username.errors && username.errors['maxlength']">
          Username must be at most 20 characters
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-group">
        <mat-label for="email">Email</mat-label>
        <input matInput type="email" class="form-control" id="email" name="email" [(ngModel)]="form.email" required
          email #email="ngModel" />
        <mat-error *ngIf="email.errors && email.errors['required']">Email is required</mat-error>
        <mat-error *ngIf="email.errors && email.errors['email']">
          Email must be a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-group">
        <mat-label for="password">Password</mat-label>
        <input matInput type="password" class="form-control" id="password" name="password" [(ngModel)]="form.password"
          required minlength="6" #password="ngModel" />
        <mat-error *ngIf="password.errors && password.errors['required']">Password is required</mat-error>
        <mat-error *ngIf="password.errors && password.errors['minlength']">
          Password must be at least 6 characters
        </mat-error>
      </mat-form-field>
      <div class="form-group">
        <button mat-flat-button color="primary" class="btn btn-primary btn-block">Sign Up</button>
      </div>
      <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
        Signup failed!<br />{{ errorMessage }}
      </div>
    </form>

    <div class="alert alert-success" *ngIf="isSuccessful">
      Your registration is successful!
    </div>
  </div>
</div>