<div class="version-container">
    <ng-container *ngIf="!sortedResourceVersions">
        <span>{{selectedVersionToShow(selectedVersion)}}</span>
    </ng-container>
    <ng-container *ngIf="sortedResourceVersions">
        <mat-select [(value)]="selectedVersion" class="selected-version">
            <mat-option *ngFor="let rv of sortedResourceVersions" [value]="rv.version" (click)="viewResourceOfVersion(rv)">
                <span>{{selectedVersionToShow(rv.version)}}</span>
                <span *ngIf="isLatest(rv)"> ({{ 'WdgHub.Latest' | translate }})</span>
            </mat-option>
        </mat-select>
    </ng-container>
</div>
