import { ResourceKey } from "src/app/services/resource/ResourceKey";
import {UpgradeCompatibilityOutcome, Version} from "../../../../services/resource/InstalledResource";
import {ResourceDetails} from "../../ResourceDetails";
import {TranslateService} from "@ngx-translate/core";
import { ResourceInstallerHandlerService } from "src/app/services/resource/resource-installer-handler.service";
import { takeUntil } from 'rxjs';
import { DestroyService } from "src/app/services/destroy/destroy-service";

export class ListItem {

    public id: string | undefined;
    public name: string | undefined;
    public categoryPath: string | undefined;
    public lastUpdate: number | undefined;
    public version: string | undefined;
    public state: string | undefined;
    public linkChangelog: string | undefined;
    public logicalName: string | undefined;
    public isInstallingStatus: boolean | undefined;

    constructor(
        public resource: ResourceDetails,
        public installedVersion: string,
        public compatibilityOutcome: UpgradeCompatibilityOutcome,
        private translateService: TranslateService,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService,
        private destroyService: DestroyService
    ) {
        this.id = resource.id;
        this.name = resource.name;
        this.categoryPath = resource.categoryPath;
        this.lastUpdate = resource.detail?.updateMillis;
        this.version = resource.detail ? new Version(resource.detail.version).getDisplayVersion(this.translateService) : '';
        this.state = resource.state;
        this.linkChangelog = resource.linkChangelog;
        this.logicalName = resource.detail?.logicalName;
        this.isInstallingStatus = false;

        let resourceKey = ResourceKey.fromResourceDetails(resource);
        if (resourceKey) {
            const obsInstalligStatus = this.resourceInstallerHandlerService.obsInstallingStatus(resourceKey);
            if (obsInstalligStatus) {
                obsInstalligStatus.pipe(takeUntil(this.destroyService)).subscribe({
                    next: (isInstallingStatus) => {
                        this.isInstallingStatus = isInstallingStatus;
                    },
                    error: (e) => console.error(e)
                });
            }
        }
    }
}
