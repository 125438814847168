import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { ResourceService } from '../../../services/resource.service';
import { LoadingStatusService } from '../../../../../services/loading/loading-status.service';
import { CategoryPathService } from '../../../services/categoryPath/category-path.service';
import { SnackBarMessageService, SnackBarType } from 'projects/snack-bar-message/src/public-api';
import { ConfirmationDialogService } from 'src/app/dialogs/confirmation/services/confirmation-dialog.service';
import { TranslateService } from "@ngx-translate/core";
import { RoleName } from "../../../../../security/entities/role/RoleName";
import { PermissionService } from "../../../../../security/services/permission/permission.service";

@Component({
    selector: 'app-rd-header',
    templateUrl: './rd-header.component.html',
    styleUrls: ['./rd-header.component.scss']
})
export class RdHeaderComponent implements OnInit {

    constructor(private router: Router,
        public authService: AuthService,
        public resourceService: ResourceService,
        private confirmationDialogService: ConfirmationDialogService,
        private snackBarMessageService: SnackBarMessageService,
        private loadingStatusService: LoadingStatusService,
        public permissionService: PermissionService,
        private categoryPathService: CategoryPathService,
        private translateService: TranslateService
    ) {
    }

    ngOnInit(): void {
    }

    deleteResource() {
        this.confirmationDialogService.showConfirmDialog(this.translateService.instant('WdgHub.AskDeleteWidget')).subscribe(result => {
            if (result) {
                this.loadingStatusService.startLoading();
                this.resourceService.deleteResource().then(result => {
                    this.router.navigate([this.categoryPathService.getResourcePathRoute()]);
                }, err => {
                    this.snackBarMessageService.openSnackBar(err.message, SnackBarType.ERROR);
                }).finally(() => {
                    this.loadingStatusService.stopLoading();
                });
            }
        });

    }

    canDeleteResource(): boolean {
        return this.authService.isLoggedIn && this.permissionService.hasAnyRole([RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR]);
    }
}
