import { Injectable, Injector } from '@angular/core';
import { SnackBarMessageService, SnackBarType } from 'projects/snack-bar-message/src/public-api';
import { BehaviorSubject, firstValueFrom, map, Observable, takeUntil } from 'rxjs';
import { DacCommunicationLayerService } from 'src/app/dacAPIs/dac-communication-layer.service';
import { DAC_MESSAGING_IDS } from 'src/app/dacAPIs/dac-messages-ids';
import { ResourceDefinition, UpgradeCompatibilityChecker, UpgradeCompatibilityOutcome } from './InstalledResource';
import { WdataEvent } from '../wdata/enums/wdata-event';
import { WdataService } from '../wdata/wdata.service';
import { WdataEventResult } from '../wdata/enums/wdata-event-result';
import { ResourceDetails } from '../../entities/resource/ResourceDetails';
import { EnvironmentBootstrapperService } from "../../startup/environment-bootstrapper.service";
import { ResourceService } from "../../entities/resource/services/resource.service";
import { ConfirmationDialogService } from "../../dialogs/confirmation/services/confirmation-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { ResourceKey } from "./ResourceKey";
import { LoadingStatusService } from '../loading/loading-status.service';
import { DestroyService } from '../destroy/destroy-service';
import { SmartSemaphore, SmartSemaphoreLock } from '@decisyon/common-utils-lib';

@Injectable({
    providedIn: 'root'
})
export class ResourceInstallerHandlerService {

    constructor(
        private snackBarMessageService: SnackBarMessageService,
        private wdataService: WdataService,
        public resourceService: ResourceService,
        private confirmationDialogService: ConfirmationDialogService,
        private loadingStatusService: LoadingStatusService,
        private translateService: TranslateService,
        private injector: Injector,
        private destroyService: DestroyService
    ) {
    }

    private dacCommunicationService!: DacCommunicationLayerService;

    private installedItemsCache = new Map<string, ResourceDefinition>();
    public observableInstalledItems = new BehaviorSubject(<Map<string, ResourceDefinition>>{});
    private _obsInstallingStatus = new Map<string, BehaviorSubject<boolean>>();

    public upgradeCompatibilityOutcomeObservable = new BehaviorSubject(new Map<string, UpgradeCompatibilityOutcome>());
    public countUpgradeAvailable = new BehaviorSubject(0);
    private upgradeCompatibilityCheckDone = false;

    private installedItemsReceivedResolve: any;
    private installedItemsReceivedReject: any;
    private installedItemsReceivedPromise = new Promise<Observable<Map<string, ResourceDefinition>>>((resolve, reject) => {
        this.installedItemsReceivedResolve = resolve;
        this.installedItemsReceivedReject = reject;
    });
    private installedItemsReceivedSemaphore = false;
    private previousResource?: ResourceDefinition;
    private installingResource?: ResourceDetails;
    private isUpgrade = false;
    private installationSemaphore = new SmartSemaphore('installationSemaphore');

    public reloadInstalledResources(isToNotifyAnalyzing?: boolean) {
        if (this.dacCommunicationService == undefined)
            throw new Error('Undefined dacCommunicationService');

        console.log('Requesting installed widgets to DAC');
        let startTime = Date.now();
        this.loadingStatusService.startLoading();
        console.log('WdgHub: reloadInstalledResources');
        if (isToNotifyAnalyzing) {
            this.snackBarMessageService.openSnackBar(this.translateService.instant('WdgHub.AnalyzingInstalledWidgets'), SnackBarType.INFO, 4800, 'bottom', 'left');
        }

        this.dacCommunicationService.sendMessageAndWait(DAC_MESSAGING_IDS.METHOD_GET_PUB_WIDGETS)
            .then((result) => {
                console.log('Received information from DAC (After ' + (Date.now() - startTime) + ' ms)');
            })
            .catch((rejects) => {
                console.error('Timeout communication with dac. Communication with dac: DAC_MESSAGING_IDS.METHOD_GET_PUB_WIDGETS message timeout', rejects);
                throw new Error("COMMUNICATION_TIMEOUT");
            }).finally(() => {
            this.loadingStatusService.stopLoading();
        });
    }

    public setCommunicationServiceInstance(dacCommunicationService: DacCommunicationLayerService) {
        this.dacCommunicationService = dacCommunicationService;
    }

    public setInstalledItems(successData: any) {
        try {
            this.installedItemsCache.clear();
            const doubleWidgetsLN = new Array<string>();
            for (let wdgToAdd of Object.values(successData)) {
                let resourceToAdd = new ResourceDefinition(wdgToAdd);
                if (resourceToAdd.logicalName != '') {
                    console.debug('Detected installed widget:' + resourceToAdd.toString());

                    if (this.installedItemsCache.has(resourceToAdd.logicalName)) {
                        // ERROR: double installed widget defined on DAC.
                        // This case is resolved considering all duplicated widget as not installed al all.
                        doubleWidgetsLN.push(resourceToAdd.logicalName);
                    }
                    else {
                        this.installedItemsCache.set(resourceToAdd.logicalName, resourceToAdd);
                    }
                } else {
                    console.error('Skipped empty widget from installed, no logical name', resourceToAdd);
                }
            }
            if (doubleWidgetsLN.length > 0) {
                this.cleanCacheAndShowDoubleWidgetErrorMessage(doubleWidgetsLN);
            }
        } catch (e) {
            console.error('Error during acquiring installed widgets', e);
            throw e;
        } finally {
            this.pushObservedInstalledItems();
            if (!this.installedItemsReceivedSemaphore) {
                this.installedItemsReceivedSemaphore = true;
                this.installedItemsReceivedResolve(this.observableInstalledItems);
            }
            this.upgradeCompatibilityCheck().then(
                value => {
                    this.upgradeCompatibilityCheckDone = true;
                    console.debug('upgradeCompatibilityCheck done successfully');
                }
            );
        }
    }

    private cleanCacheAndShowDoubleWidgetErrorMessage(doubleWidgetsLN: string[]) {
        console.error('One or more double installed widgets, having same logical name, found on DAC: ', doubleWidgetsLN);
        console.error('Multiple versions related to widget {doubleWidgetName} seems installed on DAC. ' +
            'These widgets will be considered as not installed at all. ' +
            'Please resolve this problem on DAC environment.');

        for (let doubleWidgetLN of doubleWidgetsLN) {
            this.installedItemsCache.delete(doubleWidgetLN);
        }
        this.resourceService.getResources().then(bs => {
                for (let i = 0; i < doubleWidgetsLN.length; i++) {
                    const targetDoubleResource = bs.getValue().find(r => r.detail?.logicalName === doubleWidgetsLN[i]);
                    if (targetDoubleResource) {
                        this.snackBarMessageService.openSnackBar(
                            this.translateService.instant('WdgHub.DoubleInstalledWidget',
                                {doubleWidgetName: targetDoubleResource.name}),
                            SnackBarType.ERROR);
                        return;
                    }
                }
            }
        );
    }

    private async upgradeCompatibilityCheck() {
        const outcomes = new Map<string, UpgradeCompatibilityOutcome>();
        const hubResources = (await this.resourceService.getResources()).getValue();
        let countUpdateAvailable = 0;
        hubResources.forEach(value => {
            const compatibilityOutcome = this.doUpgradeCheck(value.detail);
            const key = JSON.stringify(ResourceKey.fromResourceDetails(value));
            outcomes.set(key, compatibilityOutcome);
            if (compatibilityOutcome.updateAvailable && value.detail?.resourceType !== "SHARED_LIB") {
                console.debug('countUpdateAvailable++ for ' + value.detail?.logicalName);
                countUpdateAvailable++;
            }
        })
        console.debug('countUpdateAvailable.next ' + countUpdateAvailable);
        this.countUpgradeAvailable.next(countUpdateAvailable);
        console.debug('upgradeCompatibilityOutcomeObservable.next ', outcomes);
        this.upgradeCompatibilityOutcomeObservable.next(outcomes);
    }

    public getUpgradeCompatibility(resource: ResourceDetails): UpgradeCompatibilityOutcome {
        let result = undefined;
        const resourceKey = ResourceKey.fromResourceDetails(resource);
        if (resourceKey) {
            const key = JSON.stringify(resourceKey);
            const outcomes = this.upgradeCompatibilityOutcomeObservable.getValue();
            result = outcomes.get(key);
            if (!result) {
                // try hot calculation
                result = this.doUpgradeCheck(resource.detail);
                outcomes.set(key, result);
                console.debug('upgradeCompatibilityOutcomeObservable.next ', outcomes);
                this.upgradeCompatibilityOutcomeObservable.next(outcomes);
            }
        }
        return result ?? new UpgradeCompatibilityOutcome();
    }

    public upgradeWdgDefAfterInstall(message: any) {
        if (this.installingResource) {
            let label: string | undefined;
            switch (message.method) {
                case DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET:
                    label = this.installingResource.detail?.resourceType !== "SHARED_LIB" ? 'WdgHub.InstallSuccess' : 'WdgHub.ShLibInstallSuccess';
                    this.wdataService.collectWdataAndSend(WdataEvent.INSTALL, WdataEventResult.SUCCESS, this.installingResource, this.previousResource);
                    this.snackBarMessageService.openSnackBar(this.translateService.instant(label) + ': ' + this.installingResource.name, SnackBarType.SUCCESS);
                    break;
                case DAC_MESSAGING_IDS.METHOD_UPGRADE_WIDGET:
                    this.wdataService.collectWdataAndSend(WdataEvent.UPGRADE, WdataEventResult.SUCCESS, this.installingResource, this.previousResource);
                    label = this.isUpgrade
                        ? this.installingResource.detail?.resourceType !== "SHARED_LIB" ? 'WdgHub.UpgradeSuccess' : 'WdgHub.ShLibUpgradeSuccess'
                        : this.installingResource.detail?.resourceType !== "SHARED_LIB" ? 'WdgHub.DowngradeSuccess' : 'WdgHub.ShLibDowngradeSuccess';
                    this.snackBarMessageService.openSnackBar(this.translateService.instant(label) + ': ' + this.installingResource.name, SnackBarType.SUCCESS);
                    break;
            }
            let resourceToAdd = new ResourceDefinition(message);
            if (resourceToAdd.logicalName != '') {
                console.log('Detected installed widget:' + resourceToAdd.toString());
                this.installedItemsCache.set(resourceToAdd.logicalName, resourceToAdd);
                this.pushObservedInstalledItems();
                this.upgradeCompatibilityCheck().then(
                    value => console.debug('upgradeCompatibilityCheck done successfully')
                );
            } else {
                console.log('Reloading all widget list');
                this.reloadInstalledResources();
            }
        }
        else {
            console.error('Method upgradeWdgDefAfterInstall wrong invocation due to installing widget parameter ' +
                'is undefined. System continue to run reloading all widgets' );
            this.reloadInstalledResources();
        }
    }

    public upgradeWdgDefAfterError(errMsg: string, messageMethod: string) {
        if ((messageMethod === DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET ||
            messageMethod === DAC_MESSAGING_IDS.METHOD_UPGRADE_WIDGET) && this.installingResource) {
            this.wdataService.collectWdataAndSend(
                messageMethod === DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET
                    ? WdataEvent.INSTALL
                    : WdataEvent.UPGRADE,
                WdataEventResult.FAILED,
                this.installingResource,
                this.previousResource,
                errMsg);
            console.error('Installation of widget failed from dac: ', errMsg);
        }
    }

    public doUpgradeCheck(libraryRawItem: any): UpgradeCompatibilityOutcome {
        const installedWdg = this.getInstalledWidget(libraryRawItem?.logicalName);
        const environmentService = this.injector.get(EnvironmentBootstrapperService);
        const libraryResDef = libraryRawItem instanceof ResourceDefinition ? libraryRawItem : new ResourceDefinition(libraryRawItem);
        const checkOutcome = new UpgradeCompatibilityChecker().existsUpdatedFor(installedWdg, libraryResDef, environmentService);
        return checkOutcome;
    }

    /**
     * Only for local test. See installSingleResource and upgradeSingleResource
     * @param logicalName
     * @param widgetUrl
     */
    public async refreshDACEnvironmentInfo(logicalName: string, widgetUrl: string) {
        let message = {
            'logicalName': logicalName,
            'referenceUrl': encodeURI(widgetUrl)//,
        };
        await this.sleep(10000);
        console.log('Get environment info');
        return this.dacCommunicationService.sendMessageAndWait(DAC_MESSAGING_IDS.METHOD_GET_ENV_INFO, message)
            .then(value => {
                console.log('Environment info retrieved:', value);
            })
            .catch((reject) => {
                console.error('Error after request DAC environment info, trying to reload all installed resources', reject);
                throw new Error("ENV_INFO_FAILED");
            });
    }

    /**
     * Only for local test
     * @param ms
     * @private
     */
    private sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public async requestWidgetInstall(logicalName: string, widgetUrl: string) {
        let message = {
            'method': DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET,
            'logicalName': logicalName,
            'referenceUrl': encodeURI(widgetUrl)//,
            //'md5Code'	: item.md5Code //TO_DO
        };
        this.previousResource = this.getInstalledWidget(logicalName);
        return this.dacCommunicationService.sendMessageAndWait(DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET, message)
            .catch((reject) => {
                console.error('Error after request of widget install, trying to reload all installed resources', reject);
                this.reloadInstalledResources();
                throw new Error("WIDGET_INSTALL_FAILED");
            });
    }

    public async requestWidgetUpgrade(logicalName: string, widgetUrl: string): Promise<any> {
        let message = {
            'method': DAC_MESSAGING_IDS.METHOD_UPGRADE_WIDGET,
            'logicalName': logicalName,
            'referenceUrl': encodeURI(widgetUrl)//,
            //'md5Code'	: item.md5Code //TO_DO
        };
        this.previousResource = this.getInstalledWidget(logicalName);
        return this.dacCommunicationService.sendMessageAndWait(DAC_MESSAGING_IDS.METHOD_UPGRADE_WIDGET, message)
            .catch((reject) => {
                console.error('Error after request of widget upgrading, trying to reload all installed resources', reject);
                this.reloadInstalledResources();
                throw new Error("WIDGET_UPGRADE_FAILED");
            });
    }

    /**
     * @param logicalName
     * @returns undefined if wdg is not installed
     */
    public getInstalledWidget(logicalName: string | undefined): ResourceDefinition | undefined {
        if (!logicalName) {
            return undefined;
        }
        return this.installedItemsCache.get(logicalName);
    }

    public installedWidgetAvailable(): boolean {
        return this.installedItemsCache.size != 0;
    }

    private pushObservedInstalledItems() {
        this.observableInstalledItems.next(this.installedItemsCache);
    }

    public getInstalledItems(): Promise<Observable<Map<string, ResourceDefinition>>> {
        return this.installedItemsReceivedPromise;
    }

    public obsInstallingStatus(resourceKey: ResourceKey): BehaviorSubject<boolean> | undefined {
        const key = JSON.stringify(resourceKey);
        if (!this._obsInstallingStatus.has(key)) {
            this._obsInstallingStatus.set(key, new BehaviorSubject<boolean>(false));
        }
        return this._obsInstallingStatus.get(key);
    }

    public notInstallingStatus(resourceKey: ResourceKey): Promise<boolean> {
        if (!this.isInstallingStatus(resourceKey)) {
            return Promise.resolve(true);
        }

        return firstValueFrom((this.obsInstallingStatus(resourceKey) ?? new BehaviorSubject(true))
            .pipe(takeUntil(this.destroyService), map(installing => !installing
            )));
    }

    public setInstallingStatus(resourceKey: ResourceKey, status: boolean) {
        console.debug(`setInstallingStatus, status: ${status}, resourceKey: `, resourceKey);
        this.obsInstallingStatus(resourceKey)?.next(status);
    }

    public isInstallingStatus(resourceKey?: ResourceKey): boolean {
        return (!!resourceKey && this.obsInstallingStatus(resourceKey)?.getValue()) ?? false;
    }

    public isAnyInstallingStatus(): boolean {
        for (const [key, status] of this._obsInstallingStatus) {
            if (status?.getValue()) {
                return true;
            }
        }
        return false;
    }

    public isInstallingStatusByLN(logicalName?: string): boolean {
        if (logicalName) {
            for (let key of this._obsInstallingStatus.keys()) {
                const rKey = JSON.parse(key) as ResourceKey;
                if (rKey.logicalName == logicalName && this.isInstallingStatus(rKey)) {
                    return true;
                }
            }
        }
        return false;
    }

    async fixSharedLibResource(
        shName: string | undefined,
        hubResources: ResourceDetails[]
    ) {
        console.log("Looking on Widget Hub for lib with logicalName " + shName);
        const shHubResource = hubResources.find(r =>
            r.detail?.logicalName === shName
            && r.state === 'APPROVED_PUBLISHED'
            && r.detail?.resourceType === "SHARED_LIB");
        console.log("Lib from hub ", shHubResource);
        const shDacResource = this.getInstalledWidget(shName);
        console.log("Lib from dac ", shDacResource);
        const environmentService = this.injector.get(EnvironmentBootstrapperService);
        const checkOutcome = new UpgradeCompatibilityChecker()
            .existsUpdatedFor(
                shDacResource,
                ResourceDefinition.build(shHubResource?.detail),
                environmentService);
        console.log("Missing lib from Hub: " + checkOutcome.missingResourceFromHub);
        console.log("Lib already installed on Dac: " + checkOutcome.resourceInstalled);
        console.log("Lib upgrade available: " + checkOutcome.updateAvailable);
        if (checkOutcome.missingResourceFromHub) {
            console.error("Missing resource shared lib on hub. Unable to install dependence : " + shName);
            if (!checkOutcome.resourceInstalled) {
                console.error("Missing shared lib on Dac. Widget will be installed/upgraded but cannot work properly");
            }
            if (shHubResource && shHubResource.id && shName) {
                this.installingResource = shHubResource;
            } else {
                console.error('Illegal state: shHubResource must not be undefined');
            }
            throw new Error("UNABLE_TO_INSTALL_SHARED_LIB_NOT_FOUND_ON_HUB");
        } else if (!checkOutcome.resourceInstalled) {
            if (shHubResource && shHubResource.id && shName) {
                this.installingResource = shHubResource;
                await this.installSingleResource(new ResourceKey(shHubResource.id, shName));
                console.log("Shared lib with logicalName " + shName + ' installed successfully');
            } else {
                console.error('Illegal state: shHubResource must not be undefined');
                throw new Error("UNDEFINED_SH_RESOURCE_ON_HUB");
            }
        } else if (checkOutcome.updateAvailable) {
            if (shHubResource && shHubResource.id && shName) {
                this.installingResource = shHubResource;
                await this.upgradeSingleResource(new ResourceKey(shHubResource.id, shName));
                console.log("Shared lib with logicalName " + shName + ' upgraded successfully');
            } else {
                console.error('Illegal state: shHubResource must not be undefined');
                throw new Error("UNDEFINED_SH_RESOURCE_ON_HUB");
            }
        } else {
            console.log("Dependence already installed and updated to the last available version.");
        }
    }

    private async fixDependences(hubResources: ResourceDetails[], resource: ResourceDetails) {
        const sharedLibs = resource.detail?.sharedLibs;
        if (sharedLibs) {
            // before install/upgrade widget, all shared libs must be installed
            console.log("Fixing dependencies if required.");
            for (const shName of sharedLibs) {
                await this.fixSharedLibResource(shName, hubResources);
            }
        }
    }

    async installSingleResource(resourceKey: ResourceKey) {
        console.log("Request install resource on dac (LN,ID)" + resourceKey.logicalName + "," + resourceKey.id);
        await this.requestWidgetInstall(resourceKey.logicalName, this.resourceService.getAbsoluteDwpUri(resourceKey.id));
        // await this.refreshDACEnvironmentInfo(resourceKey.logicalName, this.resourceService.getAbsoluteDwpUri(resourceKey.id)); // leave it only for test in local
    }

    async upgradeSingleResource(resourceKey: ResourceKey) {
        console.log("Request upgrade resource on dac (LN,ID)" + resourceKey.logicalName + "," + resourceKey.id);
        await this.requestWidgetUpgrade(resourceKey.logicalName, this.resourceService.getAbsoluteDwpUri(resourceKey.id));
        // await this.refreshDACEnvironmentInfo(resourceKey.logicalName, this.resourceService.getAbsoluteDwpUri(resourceKey.id)); // leave it only for test in local
    }

    installResource(resource?: ResourceDetails) {
        this.confirmationDialogService.showConfirmDialog(this.translateService.instant('WdgHub.AskInstallWidget'))
            .subscribe(async result => {
                if (result) {
                    if (resource?.detail) {
                        let resourceKey = ResourceKey.fromResourceDetails(resource);
                        if (resourceKey) {
                            let lock : SmartSemaphoreLock | undefined;
                            try {
                                await this.notInstallingStatus(resourceKey);
                                this.setInstallingStatus(resourceKey, true);
                                lock = await this.installationSemaphore.acquire();
                                const hubResources = (await this.resourceService.getResources()).getValue();
                                await this.fixDependences(hubResources, resource);
                                this.installingResource = resource;
                                await this.installSingleResource(resourceKey);
                                this.pushInstalledResourceOnCache(resource);
                            } catch (e) {
                                const transLabel = this.translateService.instant('WdgHub.ErrorInstallingWidget')
                                this.snackBarMessageService.openSnackBar(
                                    transLabel + ': ' + this.installingResource?.name, SnackBarType.ERROR);
                                console.error('Error installing widget ' + resource.detail.logicalName, e);
                            } finally {
                                this.setInstallingStatus(resourceKey, false);
                                if (lock) {
                                    lock.release();
                                }
                            }
                        }
                    } else {
                        console.error('Installation failed. Trying to install an undefined widget!');
                    }
                }
            });
    }

    upgradeResource(isUpgrade: boolean, resource?: ResourceDetails) {
        const label = isUpgrade ?
            'WdgHub.AskUpgradeWidget' :
            'WdgHub.AskDowngradeWidget';

        this.confirmationDialogService.showConfirmDialog(this.translateService.instant(label))
            .subscribe(async result => {
                if (result) {
                    if (resource?.detail) {
                        let resourceKey = ResourceKey.fromResourceDetails(resource);
                        if (resourceKey) {
                            let lock : SmartSemaphoreLock | undefined;
                            try {
                                await this.notInstallingStatus(resourceKey);
                                this.setInstallingStatus(resourceKey, true);
                                lock = await this.installationSemaphore.acquire();
                                const hubResources = (await this.resourceService.getResources()).getValue();
                                console.log("List of resources from hub: ", hubResources);
                                await this.fixDependences(hubResources, resource);
                                this.installingResource = resource;
                                this.isUpgrade = isUpgrade;
                                await this.upgradeSingleResource(resourceKey);
                                this.pushInstalledResourceOnCache(resource);
                            } catch (e) {
                                const transErrorLabel = isUpgrade
                                    ? this.translateService.instant('WdgHub.ErrorUpgradingWidget')
                                    : this.translateService.instant('WdgHub.ErrorDowngradingWidget');
                                this.snackBarMessageService.openSnackBar(
                                    transErrorLabel + ': ' + this.installingResource?.name, SnackBarType.ERROR);
                                console.error('Error upgrading widget ' + resource.detail.logicalName, e);
                            } finally {
                                this.setInstallingStatus(resourceKey, false);
                                if (lock) {
                                    lock.release();
                                }
                            }
                        }
                    } else {
                        console.error('Upgrade failed. Trying to upgrade an undefined widget!');
                    }
                }
            });
    }

    private pushInstalledResourceOnCache(resource: ResourceDetails) {
        const rDef = ResourceDefinition.build(resource?.detail);
        if (rDef) {
            console.log('Synchronous push installed widget:' + rDef.toString());
            this.installedItemsCache.set(rDef.logicalName, rDef);
            this.pushObservedInstalledItems();
            this.upgradeCompatibilityCheck().then(
                value => console.debug('upgradeCompatibilityCheck done successfully after push')
            );
        }
    }

    private compatibilityCheckAllowed(): boolean {
        return this.upgradeCompatibilityCheckDone;
    }

    private isEmbeddedOnDac(): boolean {
        return !!this.dacCommunicationService?.isEmbeddedOnDac();
    }

    isToShowInstall(upgradeCompatibilityOutcome: UpgradeCompatibilityOutcome) {
        return this.isEmbeddedOnDac() &&
            this.compatibilityCheckAllowed() &&
            upgradeCompatibilityOutcome &&
            !upgradeCompatibilityOutcome.resourceInstalled;
    }

    isToShowUpgrade(upgradeCompatibilityOutcome: UpgradeCompatibilityOutcome) {
        return this.isEmbeddedOnDac() &&
            this.compatibilityCheckAllowed() &&
            upgradeCompatibilityOutcome &&
            upgradeCompatibilityOutcome.updateAvailable;
    }

    isToShowDowngrade(upgradeCompatibilityOutcome: UpgradeCompatibilityOutcome) {
        return this.isEmbeddedOnDac() &&
            upgradeCompatibilityOutcome &&
            !upgradeCompatibilityOutcome.updateAvailable &&
            upgradeCompatibilityOutcome.resourceDifferentVersion;
    }

    isToShowInstalled(upgradeCompatibilityOutcome: UpgradeCompatibilityOutcome, includeDowngrade: boolean) {
        return this.isEmbeddedOnDac() &&
            this.compatibilityCheckAllowed() &&
            upgradeCompatibilityOutcome &&
            upgradeCompatibilityOutcome.resourceInstalled &&
            !upgradeCompatibilityOutcome.updateAvailable &&
            (includeDowngrade || !upgradeCompatibilityOutcome.resourceDifferentVersion);
    }

    compatibilityProblemFound(upgradeCompatibilityOutcome: UpgradeCompatibilityOutcome): boolean {
        return this.compatibilityCheckAllowed() &&
            upgradeCompatibilityOutcome &&
            !upgradeCompatibilityOutcome.compatibleLegacyWidget &&
            (upgradeCompatibilityOutcome.newerDACApiLevelVersionRequired ||
                upgradeCompatibilityOutcome.olderDACApiLevelVersionRequired);
    }

    getCompatibilityProblemMessage(upgradeCompatibilityOutcome: UpgradeCompatibilityOutcome) {
        if (this.compatibilityCheckAllowed()) {
            if (upgradeCompatibilityOutcome.newerDACApiLevelVersionRequired) {
                return this.translateService.instant('WdgHub.NewerDACApiLevelVersionRequired',
                    {minApiLevel: upgradeCompatibilityOutcome.dacApiLevelCompatible});
            }
            if (upgradeCompatibilityOutcome.olderDACApiLevelVersionRequired) {
                return this.translateService.instant('WdgHub.OlderDACApiLevelVersionRequired',
                    {maxApiLevel: upgradeCompatibilityOutcome.dacApiLevelCompatible});
            }
        }
    }

    isInstallationBlocked(resource: ResourceDetails | undefined, upgradeCompatibilityOutcome: UpgradeCompatibilityOutcome): boolean {
        return !!resource &&
            (this.isInstallingStatusByLN(resource.detail?.logicalName) ||
                this.compatibilityProblemFound(upgradeCompatibilityOutcome));
    }

}
