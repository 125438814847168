import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    exports: [
        MatSnackBarModule,
        MatIconModule,
    ]
})
export class MaterialModule {}
