import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { PermissionService } from "../../services/permission/permission.service";
import { RoleName } from "../../entities/role/RoleName";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  host: {
      class:'card-centered'
  }
})
export class ChangePasswordComponent implements OnInit {

  form: any = {};
  isSuccessful = false;
  isChgPwdFailed = false;
  errorMessage = '';

  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if (!this.isAdministrator()) {
      this.form.username = this.authService.getUser().username;
    }
  }

  onSubmit(): void {
    this.authService.chgPwd(this.form).subscribe(
      data => {
        console.log(data);
        this.isSuccessful = true;
        this.isChgPwdFailed = false;
      },
      err => {
        this.errorMessage = err.error.message;
        this.isChgPwdFailed = true;
      }
    );
  }

  isAdministrator() {
    return this.permissionService.hasRole(RoleName.ROLE_ADMIN);
  }

  getTargetUsernameLabel() {
    return this.translateService.instant('WdgHub.TargetUsername');
  }

  getConfirmPasswordLabel() {
    return this.translateService.instant('WdgHub.ConfirmPassword');
  }

  getNewPasswordLabel() {
    return this.translateService.instant('WdgHub.NewPassword');
  }

}
