export enum CategoryPath {
    ALL = 'ALL',
    UPGRADE_AVAILABLE = 'UPGRADE_AVAILABLE',
    OTHERS = 'OTHERS'
}

export function isCategoryPath(path: string): boolean {
    for (let key in CategoryPath) {
        if (CategoryPath[key as keyof typeof CategoryPath].toLowerCase() == path.toLowerCase()) {
            return true;
        }
    }
    return false;
}

export function getCategoryPathByValue(value?: string): CategoryPath | undefined {
    if (value) {
        for (let key in CategoryPath) {
            let v = CategoryPath[key as keyof typeof CategoryPath];
            if (v == value) {
                return v;
            }
        }
    }
    return undefined;
}
