import {EnvironmentBootstrapperService} from "../../startup/environment-bootstrapper.service";
import {TranslateService} from "@ngx-translate/core";

export class Version {

    private version: string = '';

    private firstN: number = 0;
    private secondN: number = 0;
    private thirdN: number = 0;
    private fourthN: number = 0;
    private fourthNToCompare: number = 0;
    private patchNumber: number = 0;

    // For version having star it's the position where the * character has been found, starting from 0.
    // Value of -1 means that there's no any star in version.
    // i.e. : for version 2.* the position is 1
    private starPosition = -1;

    constructor(public stringToParse: string) {
        this.version = stringToParse;
        if (this.version)
            this.init();
    }

    private init(): void {
        let values: String[] = this.version.split(".");
        this.firstN = this.getNumber(values, 0);
        this.secondN = this.getNumber(values, 1);
        this.thirdN = this.getNumber(values, 2);
        this.fourthN = this.getNumber(values, 3);
        this.fourthNToCompare = this.getNumber(values, 3, Number.MAX_VALUE);
        this.patchNumber = this.getNumber(values, 4);
    }

    private getNumber(valuesArray: String[], ordinal: number, defaultValue = 0): number {
        let result = this.starPosition == -1 && valuesArray.length > ordinal ? Number(valuesArray[ordinal]) : defaultValue;
        if (isNaN(result)) {
            if ("*" == (valuesArray[ordinal])) {
                this.starPosition = ordinal;
            }
            result = defaultValue;
        }
        return result;
    }

    public getParsedVersion(precision = 3) {
        if (precision < 1 || precision > 4) {
            throw new Error('Invalid precision, unable to parse version');
        }
        let version = '' + this.getFirstN();
        if (precision > 1) {
            version += '.' + this.getSecondN();
        }
        if (precision > 2) {
            version += '.' + this.getThirdN();
        }
        if (precision > 3) {
            version += '.' + this.getFourthN();
        }
        return version;
    }

    private compareTo(o: Version): number {
        if (this.starPosition == 0 || o.starPosition == 0) return 0;
        let comp: number = this.compare(this.getFirstN(), o.getFirstN());
        if (comp != 0) return comp;
        if (this.starPosition == 1 || o.starPosition == 1) return 0;
        comp = this.compare(this.getSecondN(), o.getSecondN());
        if (comp != 0) return comp;
        if (this.starPosition == 2 || o.starPosition == 2) return 0;
        comp = this.compare(this.getThirdN(), o.getThirdN());
        if (comp != 0) return comp;
        if (this.starPosition == 3 || o.starPosition == 3) return 0;
        comp = this.compare(this.getComparingFourthN(), o.getComparingFourthN());
        if (comp != 0) return comp;
        if (this.starPosition == 4 || o.starPosition == 4) return 0;
        comp = this.compare(this.getPatchNumber(), o.getPatchNumber());
        //if (comp != 0) return comp;
        //if (this.starPosition == 5 || o.starPosition == 5) return 0;
        //comp = this.compare(this.getBuildNumber(), o.getBuildNumber());
        return comp;
    }

    private compare(x: number, y: number): number {
        return (x < y) ? -1 : ((x == y) ? 0 : 1);
    }

    public isHigherThan(o: Version): boolean {
        return o == null || this.compareTo(o) > 0;
    }

    public isLowerThan(o: Version): boolean {
        return o != null && this.compareTo(o) < 0;
    }

    public isEquals(o: Version): boolean {
        return o != null && this.compareTo(o) == 0;
    }

    public getVersion(): string {
        return this.version;
    }

    public getFirstN(): number {
        return this.firstN;
    }


    public getSecondN(): number {
        return this.secondN;
    }


    public getThirdN(): number {
        return this.thirdN;
    }


    public getFourthN(): number {
        return this.fourthN;
    }


    private getComparingFourthN(): number {
        return this.fourthNToCompare;
    }


    public existsFourthN(): boolean {
        return this.fourthNToCompare != Number.MAX_VALUE;
    }

    public getPatchNumber(): number {
        return this.patchNumber;
    }

    public getDisplayVersion(translateService: TranslateService): string {
        if (this.existsFourthN()) {
            return new Version(
                this.getFirstN() + '.' +
                this.getSecondN() + '.' +
                this.getThirdN() + '.' +
                translateService.instant('WdgHub.VersionBeta') + '.' +
                this.getFourthN()
            ).getVersion();
        }
        return this.getVersion();
    }

}

export class ResourceDefinition {
    id: number | undefined;
    //name: string | undefined; //to ne evaluated
    //description: string | undefined; //no relevant for already installed widgets
    details: string | undefined;
    //categoryPath: string | undefined; //no relevant for already installed widgets
    logicalName: string = '';
    minApiLevel: Version | undefined;
    maxApiLevel: Version | undefined;
    targetAPILevel: Version | undefined;
    resourceVersion: Version;
    connectorApiIDMatch: string | undefined;
    dataConnectorType: string | undefined;
    type: string | undefined; // note that can change based on platform, not enumerable
    useDataConnector: boolean | undefined;

    constructor(rawDacObject: any) {
        if (rawDacObject.logicalName != undefined)
            this.logicalName = rawDacObject.logicalName;

        this.resourceVersion =  rawDacObject.widgetVersion ? new Version(rawDacObject.widgetVersion) : new Version(rawDacObject.version); //resources of library items has version field, dac response has widgetVersion field
        this.minApiLevel = new Version(rawDacObject.minApiLevel);
        this.maxApiLevel = new Version(rawDacObject.maxApiLevel);
        this.targetAPILevel = new Version(rawDacObject.targetAPILevel);
        this.id = rawDacObject.id;
        this.connectorApiIDMatch = rawDacObject.connectorApiIdMatch;
        this.dataConnectorType = rawDacObject.dataConnectorType;
        this.type = rawDacObject.type;
        this.useDataConnector = rawDacObject.useDataConnector;
    }

    public toString(): string {
        return this.logicalName + ' '+ this.resourceVersion?.getVersion() + ' (type:'+this.type+')';
    }

    static build(rawDacObject: any | undefined): ResourceDefinition | undefined {
        if (!rawDacObject) {
            return undefined;
        }
        return new ResourceDefinition(rawDacObject);
    }

}

/**
 * Classe delegated to perform check of compatibility with a version respect to another, including dependancies
 */
export class UpgradeCompatibilityChecker {

    static readonly DAC_VERSION_3_0_0 = new Version('3.0.0');

    constructor() {}

    public existsUpdatedFor(
        dacInstalledResource:ResourceDefinition | undefined,
        libraryItemToCompare:ResourceDefinition | undefined,
        environmentService: EnvironmentBootstrapperService): UpgradeCompatibilityOutcome{
        //JUST for install test, to remove
        //if(dacInstalledResource.logicalName.indexOf('com.decisyon.widgets.hub.wdgTimeSeries') != -1){
        //    return new UpgradeCompatibilityOutcome(new ResourceDefinition({}),'https://dev.widgethub.decisyon.com/service/api/rest/resources/bc844a86-8a35-4135-be9d-dae91f7db33c/dwp/TimeSeriesChart 9.9_HUB.dwp');
        //}

        //TO_DO check  getInstalled wdg cache, and wrap into resourceDefinition to use inside version, to arrive to retrieve if a version of dac is major, in this scenario return resourceDefinition of upgrade and the url relative, otherwise UpgradeCompatibilityOutcome(undefined, undefined)

        const outcome = new UpgradeCompatibilityOutcome();
        if (!environmentService?.dacEnvironmentInfo) {
            outcome.dacEnvAvailable = false;
            return outcome;
        }
        outcome.dacEnvAvailable = true;
        const dacApiLevel = new Version(environmentService.dacEnvironmentInfo.widgetApplicationApiLevel);
        outcome.newerDACApiLevelVersionRequired = !!libraryItemToCompare?.minApiLevel?.isHigherThan(dacApiLevel);
        outcome.olderDACApiLevelVersionRequired = !!libraryItemToCompare?.maxApiLevel?.isLowerThan(dacApiLevel);
        if (outcome.newerDACApiLevelVersionRequired) {
            outcome.dacApiLevelCompatible = libraryItemToCompare?.minApiLevel?.getParsedVersion();
        }
        if (outcome.olderDACApiLevelVersionRequired) {
            outcome.dacApiLevelCompatible = libraryItemToCompare?.maxApiLevel?.getParsedVersion();
            outcome.compatibleLegacyWidget = dacApiLevel.isLowerThan(UpgradeCompatibilityChecker.DAC_VERSION_3_0_0);
        }

        try{
            if (libraryItemToCompare && dacInstalledResource) {
                if(dacInstalledResource.resourceVersion.isLowerThan(libraryItemToCompare.resourceVersion)){
                    outcome.updateAvailable = true;
                }
                outcome.resourceInstalledOnDac = dacInstalledResource;
                outcome.resourceInstalled = dacInstalledResource != undefined;
                outcome.resourceDifferentVersion = !dacInstalledResource.resourceVersion.isEquals(libraryItemToCompare.resourceVersion)
                return outcome;
            }
        }catch(e){
            console.error('Error during update check',e);
        }
        outcome.resourceInstalled = !!dacInstalledResource;
        outcome.resourceDifferentVersion = false;
        outcome.missingResourceFromHub = !libraryItemToCompare;
        return outcome;
    }

    private getAvailableResourceDefinitioRelatedOf(dacInstalledResource:ResourceDefinition):ResourceDefinition | undefined{
        try{
        }catch(e){
            console.error('Unable to retrieve if widget is available in the installable catalog, assuming not', e);
        }

        return undefined;
    }
}

/**
 * Class delegated to summarize the outcome of a performed compatibility of install/upgrade (or not existing) with reasons messages and codes
 */
export class UpgradeCompatibilityOutcome {

    // compatibility is possible only if dacEnvAvailable is true
    public dacEnvAvailable: boolean  = false;

    public updateAvailable: boolean  = false;
    public resourceInstalledOnDac?: ResourceDefinition;

    // tells if a wdg is installed, independently of the version
    public resourceInstalled: boolean = false;
    public resourceDifferentVersion: boolean = false;
    public missingResourceFromHub: boolean = false;

    // widget min api level is greater than DAC api level.
    public newerDACApiLevelVersionRequired: boolean = false;
    // widget max api level is lower than DAC api level.
    public olderDACApiLevelVersionRequired: boolean = false;
    // all legacy widgets, with min/max api level set to 1.x.x, will be considered also compatible with DAC 2.*
    public compatibleLegacyWidget: boolean = false;
    // minimal (or maximal) DAC api level to be compatible
    public dacApiLevelCompatible?: string;

    //TO_DO add calculated deps in order
    //TO_DO add reasons messages for non-compatibility cases

    /**
     *
     * @param resourceDefinition that represent the new re\source version found
     * @param upgradeUrl that represent the URL to download the resource
     */
    constructor() {}
}

