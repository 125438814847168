import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { SnackBarMessageService, SnackBarType } from 'projects/snack-bar-message/src/public-api';
import { DacCommunicationLayerService } from 'src/app/dacAPIs/dac-communication-layer.service';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { UpgradeCompatibilityOutcome } from 'src/app/services/resource/InstalledResource';
import { ResourceInstallerHandlerService } from 'src/app/services/resource/resource-installer-handler.service';
import { ResourceDetails } from '../../../ResourceDetails';
import { ResourceVersionId } from '../../../ResourceVersionId';
import { LoadingStatusService } from '../../../../../services/loading/loading-status.service';
import { ResourceStateService } from '../../../services/resource-state.service';
import { ResourceService } from '../../../services/resource.service';
import { ResourceKey } from "../../../../../services/resource/ResourceKey";
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroy/destroy-service';
import { ResourceType } from '../../../ResourceType';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-rd-info',
    templateUrl: './rd-info.component.html',
    styleUrls: ['./rd-info.component.scss']
})
export class RdInfoComponent implements OnInit {

    @ViewChild("fileSelectorButton") fileSelectorButton: ElementRef | undefined;

    isInEdit = false;
    isInstallingStatus = false;
    resourceVersions: Array<ResourceVersionId> = [];
    resourceType: string = '';
    upgradeCompatibilityOutcome!: UpgradeCompatibilityOutcome;
    public files: NgxFileDropEntry[] = [];

    constructor(
        public authService: AuthService,
        public resourceService: ResourceService,
        private resourceStateService: ResourceStateService,
        private loadingStatusService: LoadingStatusService,
        private snackBarMessageService: SnackBarMessageService,
        private dacCommunicationLayerService: DacCommunicationLayerService,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService,
        private translateService: TranslateService,
        private destroyService: DestroyService
    ) {
    }

    ngOnInit(): void {
        this.resourceService.resourceInEdit.pipe(takeUntil(this.destroyService)).subscribe({
            next: (resourceInEdit: ResourceDetails) => {
                if (!resourceInEdit) {
                    return;
                }
                if (resourceInEdit.detail) {
                    this.isInEdit = !this.isDwpLoaded();
                    this.resourceService.getResourcesByLogicalName(resourceInEdit.detail.logicalName).subscribe(
                        {
                            next: (resources: ResourceDetails[]) => {
                                this.resourceVersions = [];
                                resources.forEach(resource => {
                                    if (resource.detail && resource.id) {
                                        this.resourceVersions.push({
                                            version: resource.detail?.version.toString(),
                                            id: resource.id,
                                            state: resource.state
                                        });
                                    }
                                })
                            },
                            error: (e) => console.error(e)
                        }
                    );
                    const resourceTypeEnum = this.resourceService.getResourceInEdit()?.detail?.resourceType;
                    const resourceTypeLabel = (<any>ResourceType)[resourceTypeEnum as keyof ResourceType];
                    this.resourceType = resourceTypeLabel ? this.translateService.instant(resourceTypeLabel) : resourceTypeEnum;
                }
                this.resourceInstallerHandlerService.upgradeCompatibilityOutcomeObservable.pipe(takeUntil(this.destroyService)).subscribe(
                    {
                        next: () => {
                            if (resourceInEdit.detail?.logicalName) {
                                this.upgradeCompatibilityOutcome = this.resourceInstallerHandlerService
                                    .getUpgradeCompatibility(resourceInEdit);
                            }
                        },
                        error: (e) => console.error(e)
                    }
                );

                let resourceKey = ResourceKey.fromResourceDetails(resourceInEdit);
                if (resourceKey) {
                    const obsInstalligStatus = this.resourceInstallerHandlerService.obsInstallingStatus(resourceKey);
                    if (obsInstalligStatus) {
                        obsInstalligStatus.pipe(takeUntil(this.destroyService)).subscribe({
                            next: (isInstallingStatus) => {
                                this.isInstallingStatus = isInstallingStatus;
                            },
                            error: (e) => console.error(e)
                        });
                    }
                }
            },
            error: (e) => console.error(e)
        });
    }

    public clickFileSelector() {
        this.fileSelectorButton?.nativeElement.click();
    }

    public dropped(files: NgxFileDropEntry[]) {
        this.files = files;
        for (const droppedFile of files) {

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    // Here you can access the real file
                    console.log(droppedFile.relativePath, file);

                    const formData = new FormData()
                    formData.append('dwp', file, droppedFile.relativePath);
                    const id = this.resourceService.resourceInEdit.getValue().id

                    if (id) {
                        this.loadingStatusService.startLoading();
                        this.resourceService.uploadResourceDWP(id, formData)
                            .then((response: any) => {
                                this.snackBarMessageService.openSnackBar('Dwp uploaded successfully', SnackBarType.SUCCESS);
                                this.isInEdit = false;
                            }, err => {
                                console.log(err);
                                const message = err.error?.message ?? err.message;
                                this.snackBarMessageService.openSnackBar(message, SnackBarType.ERROR);

                            })
                            .finally(() => {
                                this.loadingStatusService.stopLoading();
                            })
                    }


                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    public fileOver(event: any) {
        //console.log(event);
    }

    public fileLeave(event: any) {
        //console.log(event);
    }

    isToShowDownload() {
        return this.resourceService.getResourceInEdit()?.detail?.logicalName &&
            !this.dacCommunicationLayerService.isDacAccessAllowed();
    }

    isToShowInstall() {
        return this.resourceService.getResourceInEdit()?.detail?.logicalName &&
            this.resourceInstallerHandlerService.isToShowInstall(this.upgradeCompatibilityOutcome);
    }

    isToShowUpgrade() {
        return this.resourceService.getResourceInEdit()?.detail?.logicalName &&
            this.resourceInstallerHandlerService.isToShowUpgrade(this.upgradeCompatibilityOutcome);
    }

    isToShowDowngrade() {
        return this.resourceService.getResourceInEdit()?.detail?.logicalName &&
            this.resourceInstallerHandlerService.isToShowDowngrade(this.upgradeCompatibilityOutcome);
    }

    isToShowInstalled() {
        return this.resourceService.getResourceInEdit()?.detail?.logicalName &&
            this.resourceInstallerHandlerService.isToShowInstalled(this.upgradeCompatibilityOutcome, false);
    }

    compatibilityProblemFound(): boolean {
        return this.resourceInstallerHandlerService.compatibilityProblemFound(this.upgradeCompatibilityOutcome);
    }

    getCompatibilityProblemMessage() {
        return this.resourceInstallerHandlerService.getCompatibilityProblemMessage(this.upgradeCompatibilityOutcome);
    }

    isInstallationBlocked(): boolean {
        return this.resourceInstallerHandlerService
            .isInstallationBlocked(this.resourceService.getResourceInEdit(), this.upgradeCompatibilityOutcome);
    }

    downloadResource() {
        const url = this.resourceService.getAbsoluteDwpUri(this.resourceService.resourceInEdit.getValue().id);
        window.open(url, '_blank');
    }

    public isDwpLoaded(): boolean {
        return !!this.resourceService.getResourceInEdit()?.resourceDefined;
    }

    public isStateWip(): boolean {
        if (!!this.resourceService.getResourceInEdit()?.resourceDefined) {
            return this.resourceStateService.isStateWip(this.resourceService.getResourceInEdit()?.state);
        }
        return false;
    }

    installResource() {
        this.resourceInstallerHandlerService.installResource(this.resourceService.getResourceInEdit());
    }

    upgradeResource(isUpgrade: boolean) {
        this.resourceInstallerHandlerService.upgradeResource(isUpgrade, this.resourceService.getResourceInEdit());
    }

}
