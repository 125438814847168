import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Resource } from '../../Resource';
import { ICategoryPathNode } from './iCategoryPathNode';
import { ResourceInstallerHandlerService } from "../../../../services/resource/resource-installer-handler.service";
import { CategoryPath, getCategoryPathByValue } from "./CategoryPath";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CategoryPathService {
    private _selectedPath: ICategoryPathNode | undefined;
    private _sideNav: MatSidenav | undefined;
    private _categoryPathTree: ICategoryPathNode[] = [];
    private _listViewEnabled = true;
    private _selectedCategory: string = CategoryPath.ALL;

    public categoryPathTreeObservable: BehaviorSubject<ICategoryPathNode[]> = new BehaviorSubject(<ICategoryPathNode[]>[]);

    public static CATEGORY_PATH = 'category';

    constructor(
        private translateService: TranslateService,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService
    ) { }

    set selectedPath(path: ICategoryPathNode | undefined) {
        this._selectedPath = path;
    }

    get selectedPath(): ICategoryPathNode | undefined {
        return this._selectedPath;
    }

    resetSelectedPath() {
        this._selectedPath = this._categoryPathTree[0];
        this.selectedCategory = CategoryPath.ALL;
    }

    initSelectedPath(category: string | undefined) {
        if (category) {
            this.alignSelectedPath(category);
            this.selectedCategory = this._selectedPath?.name ?? category;
        } else {
            this.resetSelectedPath();
        }
    }

    alignSelectedPath(category: string | null) {
        if (category) {
            switch (getCategoryPathByValue(category)) {
                case CategoryPath.ALL:
                    this._selectedPath = this._categoryPathTree[0];
                    break;
                case CategoryPath.UPGRADE_AVAILABLE:
                    this._selectedPath = this._categoryPathTree[1];
                    break;
                default :
                    const index = this._categoryPathTree.findIndex(x => x.name.toLowerCase() == category.toLowerCase());
                    this._selectedPath = this._categoryPathTree[index];
            }
        }
    }

    set categoryPathTree(categoryPathTree: ICategoryPathNode[]) {
        this._categoryPathTree = categoryPathTree;
    }

    get categoryPathTree(): ICategoryPathNode[] {
        return this._categoryPathTree;
    }

    get listViewEnabled(): boolean {
        return this._listViewEnabled;
    }

    set listViewEnabled(value: boolean) {
        this._listViewEnabled = value;
    }

    get selectedCategory(): string {
        return this._selectedCategory;
    }

    set selectedCategory(value: string) {
        this._selectedCategory = value;
    }

    initCategoryPathTree(
        resources: Resource[],
        path: string | undefined,
        category: string | undefined) {

        this._categoryPathTree = <ICategoryPathNode[]>[
            ...new Set(resources?.map(resource => resource.categoryPath ?? ""))]
            .map(path => {
                return path
                    ? { name: path }
                    : { name: CategoryPath.OTHERS, showOthers: true };
            })
            .sort((obj1, obj2) => {
                if (obj2.showOthers) {
                    return -1;
                }

                if (obj1.showOthers) {
                    return 1;
                }

                if (obj1.name < obj2.name) {
                    return -1;
                }

                if (obj1.name > obj2.name) {
                    return 1;
                }

                return 0;

            });

        // unshift insert on top of the array

        const labelUpgradeAvailable = this.translateService.instant('WdgHub.CategoryUpgradeAvailable');
        if (this.resourceInstallerHandlerService.countUpgradeAvailable.getValue() > 0) {
            this._categoryPathTree.unshift({
                'name': labelUpgradeAvailable,
                'categoryPath': CategoryPath.UPGRADE_AVAILABLE,
                'showUpgrade': true
            });
        }

        const labelAll = this.translateService.instant('WdgHub.CategoryAll');
        this._categoryPathTree.unshift({
            'name': labelAll,
            'categoryPath': CategoryPath.ALL,
            'showAll': true
        });

        if (path !== 'resource-detail') {
            this.listViewEnabled = !path || path === 'resource-list'
        }

        this.categoryPathTreeObservable.next(this._categoryPathTree);
        this.initSelectedPath(category);
    }

    initSideNav(sideNav: MatSidenav | undefined) {
        this._sideNav = sideNav
    }

    toggleSideNav() {
        this._sideNav?.toggle();
    }

    getResourcePathRoute() {
        console.log("category path listViewEnabled: " + this.listViewEnabled);
        return this.listViewEnabled ? 'home/resource-list/' : 'home/resource-cards/';
    }

    getCategoryParameterMap(target: ICategoryPathNode | undefined): Object {
        const paramMap: { [index: string]: any } = {};
        paramMap[CategoryPathService.CATEGORY_PATH] = target?.categoryPath ?? target?.name;
        console.debug('param ', paramMap);
        return paramMap;
    }

}
