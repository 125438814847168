import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, takeUntil } from 'rxjs';
import { User } from '../../entities/user/User';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../token/token-storage.service';
import { DestroyService } from 'src/app/services/destroy/destroy-service';
import { getRoleName, RoleName } from "../../entities/role/RoleName";

const AUTH_API = environment.pathToService('api/auth/');

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _isLoggedIn = false;

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService,
        private destroyService: DestroyService
    ) {
    }

    login(credentials: User): Observable<any> {
        return this.http.post(AUTH_API + 'signin', {
            username: credentials.username,
            password: credentials.password
        }, httpOptions).pipe(takeUntil(this.destroyService));
    }

    loginToken(token: string): Observable<any> {
        return this.http.post(AUTH_API + 'signin?token=' + token, {
        }, httpOptions).pipe(takeUntil(this.destroyService));
    }

    register(user: User): Observable<any> {
        return this.http.post(AUTH_API + 'signup', {
            name: user.name,
            surname: user.surname,
            username: user.username,
            email: user.email,
            password: user.password
        }, httpOptions).pipe(takeUntil(this.destroyService));
    }

    chgPwd(user: User): Observable<any> {
        return this.http.post(AUTH_API + 'chgpwd', {
            username: user.username,
            password: user.password,
            newPassword: user.newPassword
        }, httpOptions).pipe(takeUntil(this.destroyService));
    }

    refreshToken(token: string) {
        return this.http.post(AUTH_API + 'refreshtoken', {
            refreshToken: token
        }, httpOptions).pipe(takeUntil(this.destroyService));
    }

    gentoken(form: any): Observable<any> {
        let queryParams = new HttpParams();
        if (form.dtpValue) {
            queryParams = queryParams.append("expireTS", form.dtpValue.getTime());
        }
        return this.http.get(AUTH_API + 'gentoken', {
            params: queryParams,
            responseType: 'text'
        }).pipe(takeUntil(this.destroyService));
    }

    logout(userId?: string) {
        return this.http.post(AUTH_API + 'logout', {
            userId: userId
        }, httpOptions).pipe(takeUntil(this.destroyService));
    }

    storeUser(user: User) {
        this.tokenStorage.saveToken(user.accessToken);
        this.tokenStorage.saveRefreshToken(user.refreshToken);
        this.tokenStorage.saveUser(user);
    }

    getUser(): User {
        return this.tokenStorage.getUser();
    }

    getAccessToken(): string | null {
        return this.tokenStorage.getToken();
    }

    get isLoggedIn(): boolean {
        if (!this._isLoggedIn) {
            this._isLoggedIn = !!this.tokenStorage.getToken();
        }
        return this._isLoggedIn;
    }

    loggedOut() {
        this.tokenStorage.signOut();
        this._isLoggedIn = false;
    }

    /**
     * Specify that the current user that is navigating the site has role ROLE_USER or is not logged at all.
     */
    isGuestUser(): boolean {
        return !this.isLoggedIn || this.getUser().roles.includes(getRoleName(RoleName.ROLE_USER));
    }
}
