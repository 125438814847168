<mat-sidenav-container class="catalogue-container" autosize>
    <mat-sidenav #sidenav class="catalogue-sidenav" mode="side" [fixedInViewport]="true" [fixedTopGap]="getFixedTopGap()" opened disableClose="true">
        <app-category-path-tree></app-category-path-tree>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="catalogue-sidenav-content">
            <div>
                <router-outlet ></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>