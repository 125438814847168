import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {PermissionService} from '../permission/permission.service';
import {CategoryPathService} from '../../../entities/resource/services/categoryPath/category-path.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private permissionService: PermissionService,
        private categoryPathService: CategoryPathService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn) {
            // check if route is restricted by role
            if (!this.permissionService.hasAnyRole(route.data['roles'])) {
                // role not authorised so redirect to home page
                this.router.navigate([this.categoryPathService.getResourcePathRoute()]);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to home page
        this.router.navigate([this.categoryPathService.getResourcePathRoute()]);
        return false;
    }
}
