//let hubServiceUri: any | string = window["environmentVariables" as any]["hubServiceUri" as any] ?? "http://localhost:8080/";

export const environment = {
    production: true,
    //hubServiceUri: '/service',//(hubServiceUri as string).replace(/\/?$/, '/')
    pathToService(path: string): string{
        return '/service/api/rest/' + path;
    },
    isRunningOnDevWidgetHUB: window.location.hostname === 'dev.widgethub.decisyon.com'
};


