<div class="description-container">
    <div class="hub-medium-title description-title" *ngIf="isEditable()">
        <span>Description</span>
        <span class="item-spacer"></span>
        <ng-container>
            <button [disabled]="!isDwpLoaded()" mat-icon-button *ngIf="!isInEdit" color="primary" (click)="isDwpLoaded() && goInEdit()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button *ngIf="isInEdit" color="primary" (click)="saveDescription()">
                <mat-icon>save</mat-icon>
            </button>
            <button mat-icon-button *ngIf="isInEdit" color="primary" (click)="discardChanges()">
                <mat-icon>undo</mat-icon>
            </button>
        </ng-container>
    </div>
    <div class="tiny-reset widget-description" *ngIf="!isInEdit || authService.isGuestUser"
        [innerHTML]="resourceService.getResourceInEdit()?.description">
    </div>
    <div *ngIf="isInEdit && !authService.isGuestUser()">
        <editor apiKey="2vug1bw0suzcl5q71t4tqugzsf05t7uur0e7tsb9vp08l2yb" [(ngModel)]="text" [init]="{
            height: 200,
            menubar: false,
            plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'}">
        </editor>
    </div>
</div>
