import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationDialogService {

    constructor(public dialog: MatDialog) { }

    showConfirmDialog(message: string, width? :string): Observable<MatDialog> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: width ?? '300px',
            data: { message },
            autoFocus: false,
            restoreFocus: false
        });

        return dialogRef.afterClosed();
    }
}
