<div class="list-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                Name
            </th>
            <td mat-cell *matCellDef="let listItem"> {{listItem.name}} <div style="display: none">LogicalName:
                    {{listItem.logicalName}}</div>
            </td>
        </ng-container>

        <!-- version Column -->
        <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by version">
                Version
            </th>
            <td mat-cell *matCellDef="let listItem"> {{listItem.version}} </td>
        </ng-container>

        <!-- installed version Column -->
        <ng-container *ngIf="dacCommunicationService.isEmbeddedOnDac" matColumnDef="installedVersion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by version">
                Installed version
            </th>
            <td mat-cell *matCellDef="let listItem"> {{listItem.installedVersion}} </td>
        </ng-container>

        <!-- state Column -->
        <ng-container *ngIf="isToShowStateColumn()" matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by state">
                State
            </th>
            <td mat-cell *matCellDef="let listItem" [ngClass]="resourceStateService.getStateClass(listItem.state)">
                {{resourceStateService.getStateDescription(listItem.state)}}
            </td>
        </ng-container>

        <!-- install & changelog Column -->
        <ng-container *ngIf="dacCommunicationService.isEmbeddedOnDac" matColumnDef="linkChangelog">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Change Log">
                Install & Change Log
            </th>
            <td mat-cell *matCellDef="let listItem">
                <button *ngIf="isToShowInstall(listItem)" mat-icon-button [matTooltip]="getInstallToolTip(listItem)"
                    (click)="installResource(listItem, $event)" [disabled]="isInstallationBlocked(listItem)">
                    <mat-icon *ngIf="!listItem.isInstallingStatus">downloading</mat-icon>
                    <mat-icon *ngIf="listItem.isInstallingStatus" class="installing-rotating-icon">
                        <mat-spinner diameter="24" color="accent"></mat-spinner>
                    </mat-icon>
                </button>
                <div *ngIf="isToShowUpgrade(listItem)" [matTooltip]="getUpgradeToolTip(listItem)" class="secondButtonContainer">
                    <button mat-icon-button (click)="upgradeResource(listItem, $event)"
                        [disabled]="isInstallationBlocked(listItem)"
                        [color]="isInstallationBlocked(listItem) ? '' : 'accent'">
                        <mat-icon *ngIf="!listItem.isInstallingStatus">add_task</mat-icon>
                        <mat-icon *ngIf="listItem.isInstallingStatus" class="installing-rotating-icon">
                            <mat-spinner diameter="24" color="accent"></mat-spinner>
                        </mat-icon>
                    </button>
                </div>

                <button *ngIf="isToShowDowngrade(listItem)" mat-icon-button [matTooltip]="getDowngradeToolTip(listItem)"
                    (click)="downgradeResource(listItem, $event)" [disabled]="isInstallationBlocked(listItem)">
                    <mat-icon *ngIf="!listItem.isInstallingStatus">history</mat-icon>
                    <mat-icon *ngIf="listItem.isInstallingStatus" class="installing-rotating-icon">
                        <mat-spinner diameter="24" color="accent"></mat-spinner>
                    </mat-icon>
                </button>
                <div *ngIf="isToShowInstalled(listItem)" [matTooltip]="getInstalledToolTip(listItem)" class="secondButtonContainer">
                    <button disabled mat-icon-button class="resource-already-installed">
                        <mat-icon>task_alt</mat-icon>
                    </button>
                </div>
                <div (click)="$event.stopPropagation()" *ngIf="isToShowInstallAndChangelog(listItem)"
                    [matTooltip]="getUpdateAndChangelogToolTip(listItem?.linkChangelog)" class="secondButtonContainer">
                    <button (click)="openLink(listItem.linkChangelog, $event)" mat-icon-button
                        [disabled]="!listItem?.linkChangelog"
                        [color]="listItem?.linkChangelog ? 'accent' : ''">
                        <mat-icon fontSet="material-icons-outlined">description</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="categoryPath">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by category">
                Category
            </th>
            <td mat-cell *matCellDef="let listItem"> {{listItem.categoryPath}} </td>
        </ng-container>

        <ng-container matColumnDef="lastUpdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last update">
                Last update
            </th>
            <td mat-cell *matCellDef="let listItem"> {{listItem.lastUpdate | date}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="showResourceDetail(row.id)" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>