import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Resource } from '../../Resource';
import { CategoryPathService } from '../../services/categoryPath/category-path.service';
import { ICategoryPathFlatNode } from '../../services/categoryPath/iCategoryPathFlatNode';
import { ICategoryPathNode } from '../../services/categoryPath/iCategoryPathNode';
import { ResourceService } from '../../services/resource.service';
import { DacCommunicationLayerService } from '../../../../dacAPIs/dac-communication-layer.service';
import { AuthService } from '../../../../security/services/auth/auth.service';
import { FilterElement } from '../../pipe/resourceFilter/FilterElement';
import { ResourceFilterPipe } from '../../pipe/resourceFilter/resource-filter.pipe';
import { RoleName } from "../../../../security/entities/role/RoleName";
import { PermissionService } from "../../../../security/services/permission/permission.service";
import { combineLatest, takeUntil } from "rxjs";
import { ResourceDefinition, UpgradeCompatibilityOutcome } from "../../../../services/resource/InstalledResource";
import { ResourceInstallerHandlerService } from "../../../../services/resource/resource-installer-handler.service";
import { DestroyService } from 'src/app/services/destroy/destroy-service';

@Component({
    selector: 'app-category-path-tree',
    templateUrl: './category-path-tree.component.html',
    styleUrls: ['./category-path-tree.component.scss']
})
export class CategoryPathTreeComponent implements OnInit {

    constructor(private categoryPathService: CategoryPathService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private resourceService: ResourceService,
        private dacCommunicationService: DacCommunicationLayerService,
        private authService: AuthService,
        private resourceFilterPipe: ResourceFilterPipe,
        private permissionService: PermissionService,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService,
        private destroyService: DestroyService
    ) {
    }

    ngOnInit(): void {

        combineLatest([
            this.activeRoute.paramMap,
            this.resourceInstallerHandlerService.observableInstalledItems,
            this.resourceInstallerHandlerService.upgradeCompatibilityOutcomeObservable])
            .pipe(takeUntil(this.destroyService))
            .subscribe((obs: [ParamMap, Map<string, ResourceDefinition>, Map<string, UpgradeCompatibilityOutcome>]) => {
                const filter = FilterElement.buildResourceFilter().addShowSharedLib(this.isToShowSharedLibs());
                this.resourceService.getResources().then(observableResources => {
                    this.resourceFilterPipe
                        .transform(observableResources, filter)
                        .pipe(takeUntil(this.destroyService))
                        .subscribe({
                            next: (resources: Resource[]) => {
                                // if path is only /home then redirect to /home/resource-cards (or list on dac)
                                const path = this.activeRoute.firstChild?.snapshot.url?.length ? this.activeRoute.firstChild?.snapshot.url[0].path : '';
                                if (!path) {
                                    this.categoryPathService.listViewEnabled = this.dacCommunicationService.isEmbeddedOnDac();
                                    this.router.navigate([this.categoryPathService.getResourcePathRoute()]);
                                } else {
                                    // init three highlighting specific category
                                    const category = this.activeRoute.firstChild?.snapshot.url[0].parameterMap.get(CategoryPathService.CATEGORY_PATH) ?? undefined;
                                    this.categoryPathService.initCategoryPathTree(resources, path, category);
                                    this.dataSource.data = this.categoryPathService.categoryPathTree;
                                }
                            },
                            error: (e) => {
                                console.error(e);
                            }
                        });
                })

            });

    }

    private _transformer = (node: ICategoryPathNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            categoryPath: node.categoryPath,
            level: level,
            showAll: node.showAll,
            showUpgrade: node.showUpgrade,
            showOthers: node.showOthers
        };
    };

    treeControl = new FlatTreeControl<ICategoryPathFlatNode>(
        node => node.level,
        node => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ICategoryPathFlatNode) => node.expandable;

    setSelectedPath(node: ICategoryPathNode) {
        this.categoryPathService.selectedPath = node;
        this.categoryPathService.selectedCategory = node.name;
        this.router.navigate([
            this.categoryPathService.getResourcePathRoute(),
            this.categoryPathService.getCategoryParameterMap(node)
        ]);
    }

    isNodeSelected(node: ICategoryPathNode): boolean {
        return this.categoryPathService.selectedPath?.categoryPath
            ? node.categoryPath === this.categoryPathService.selectedPath?.categoryPath
            : node.name.toLowerCase() === this.categoryPathService.selectedPath?.name.toLowerCase();
    }

    isToShowSharedLibs(): boolean {
        return !this.authService.isGuestUser() && !this.permissionService.hasRole(RoleName.ROLE_QA);
    }

    isCategoryUpdateAvailable(node: ICategoryPathNode): boolean {
        return !!node.showUpgrade;
    }

    getUpdateAvailableCount(): number {
        return this.resourceInstallerHandlerService.countUpgradeAvailable.getValue();
    }

    getFolderIcon(node: ICategoryPathNode, isExpanded: boolean) {
        if (node.showAll) {
            return 'topic'
        }
        if (node.showUpgrade) {
            return 'folder_special'
        }
        return isExpanded ? 'folder_open' : 'folder'
    }

}
