import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs';
import { ResourceInstallerHandlerService } from "./services/resource/resource-installer-handler.service";
import { DestroyService } from './services/destroy/destroy-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild("mainContent")
    private mainContentDiv!: ElementRef<HTMLElement>;

    constructor(
        private router: Router,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService,
        private destroyService: DestroyService
    ) { }

    ngOnInit(): void {

        this.router.events.pipe(takeUntil(this.destroyService),
            filter(event => event instanceof NavigationEnd)
        ).subscribe(data => {
            (this.mainContentDiv?.nativeElement as HTMLElement).scrollTop = 0;
            let sidenavContent = (this.mainContentDiv?.nativeElement as HTMLElement).getElementsByTagName('mat-sidenav-content');
            if (sidenavContent.length) {
                sidenavContent[0].scrollTop = 0;
            }
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    handleClose($event: any) {
        if (this.resourceInstallerHandlerService.isAnyInstallingStatus()) {
            console.log('Exit from tab not allowed because some widget install is in progress');
            $event.returnValue = true;
        }
    }

}
