<div class="hub-small-title documentation-title" *ngIf="isToShow()">
    <span color="primary">Widget documentation</span>
    <span class="item-spacer"></span>
    <ng-container *ngIf="isEditable()">
        <button [disabled]="!isDwpLoaded()" mat-icon-button *ngIf="!isInEdit" color="primary"
            (click)="isDwpLoaded() && goInEdit()">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isInEdit" color="primary"
            (click)="saveChanges()">
            <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isInEdit" color="primary"
            (click)="discardChanges()">
            <mat-icon>undo</mat-icon>
        </button>
    </ng-container>
</div>
<div *ngIf="!isInEdit || authService.isGuestUser()">
    <mat-nav-list class="linkManuals">
        <a mat-list-item *ngIf="resourceService.getResourceInEdit()?.linkManuals" [href]="resourceService.getResourceInEdit()?.linkManuals" target="_blank">Manual</a>
        <a mat-list-item *ngIf="resourceService.getResourceInEdit()?.linkWhatsNew" [href]="resourceService.getResourceInEdit()?.linkWhatsNew" target="_blank">What's New</a>
        <a mat-list-item *ngIf="resourceService.getResourceInEdit()?.linkChangelog" [href]="resourceService.getResourceInEdit()?.linkChangelog" target="_blank">Changelog</a>
    </mat-nav-list>
</div>
<div *ngIf="isInEdit && !authService.isGuestUser()">
    <mat-form-field appearance="fill">
        <mat-label>Link manuals</mat-label>
        <input #linkManuals matInput placeholder="Link manuals" autocomplete="off" [value]="resourceService.getResourceInEdit()?.linkManuals" />
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Link What's New</mat-label>
        <input #linkWhatsNew matInput placeholder="Link What's New" autocomplete="off" [value]="resourceService.getResourceInEdit()?.linkWhatsNew" />
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Link Changelog</mat-label>
        <input #linkChangelog matInput placeholder="Link Changelog" autocomplete="off" [value]="resourceService.getResourceInEdit()?.linkChangelog" />
    </mat-form-field>
</div>
