<div>
    <div class="card card-container">
        <img *ngIf="false" id="profile-img" src="/assets/images/avatar_2x.png" class="profile-img-card" />
        <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <mat-form-field class="form-group">
                <mat-label for="password">{{getConfirmPasswordLabel()}}</mat-label>
                <input matInput type="password" class="form-control" id="password" name="password"
                    [(ngModel)]="form.password" required minlength="6" #password="ngModel"
                    autocomplete="new-password" />

                <mat-error *ngIf="password.errors && password.errors['required']">Password is required</mat-error>
                <mat-error *ngIf="password.errors && password.errors['minlength']">
                    Password must be at least 6 characters
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-group" [hidden]="!isAdministrator()">
                <mat-label for="username">{{getTargetUsernameLabel()}}</mat-label>
                <input matInput type="text" class="form-control" id="username" name="username"
                    [(ngModel)]="form.username" required minlength="3" maxlength="20" [disabled]="!isAdministrator()"
                    #username="ngModel" />
                    <mat-error *ngIf="username.errors && username.errors['required']">Username is required</mat-error>
                    <mat-error *ngIf="username.errors && username.errors['minlength']">
                        Username must be at least 3 characters
                    </mat-error>
                    <mat-error *ngIf="username.errors && username.errors['maxlength']">
                        Username must be at most 20 characters
                    </mat-error>
            </mat-form-field>
            <mat-form-field class="form-group">
                <mat-label for="newPassword">{{getNewPasswordLabel()}}</mat-label>
                <input matInput type="password" class="form-control" id="newPassword" name="newPassword"
                    [(ngModel)]="form.newPassword" required minlength="6" #newPassword="ngModel"
                    autocomplete="new-password" />
                    <mat-error *ngIf="newPassword.errors && newPassword.errors['required']">New password is required</mat-error>
                    <mat-error *ngIf="newPassword.errors && newPassword.errors['minlength']">
                        Password must be at least 6 characters
                    </mat-error>
            </mat-form-field>
            <div class="form-group">
                <button mat-flat-button color="primary" class="btn btn-primary btn-block">Change password</button>
            </div>

            <div class="alert alert-warning" *ngIf="f.submitted && isChgPwdFailed">
                Signup failed!<br />{{ errorMessage }}
            </div>
        </form>

        <div class="alert alert-success" *ngIf="isSuccessful">
            Password changed successfully!
        </div>
    </div>
</div>