import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { SnackBarMessageComponent } from './snack-bar-message.component';



@NgModule({
  declarations: [
    SnackBarMessageComponent
  ],
  imports: [
    MaterialModule
  ],
  exports: [
    SnackBarMessageComponent
  ]
})
export class SnackBarMessageModule { }
