import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs';
import { CategoryPathService } from 'src/app/entities/resource/services/categoryPath/category-path.service';
import { ResourceSearchService } from 'src/app/entities/resource/services/resource-search.service';
import { ResourceService } from 'src/app/entities/resource/services/resource.service';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { EventBusService } from 'src/app/services/event/event-bus.service';
import { UiAspectService } from 'src/app/services/ui-aspect/ui-aspect.service';
import { SystemSettingService } from '../../entities/setting/system/service/system-setting.service';
import { PermissionService } from "../../security/services/permission/permission.service";
import { RoleName } from "../../security/entities/role/RoleName";
import { SnackBarType } from "../../../../projects/snack-bar-message/src/lib/snack-bar-message-type";
import { LoadingStatusService } from "../../services/loading/loading-status.service";
import { SnackBarMessageService } from "../../../../projects/snack-bar-message/src/lib/snack-bar-message.service";
import { DestroyService } from 'src/app/services/destroy/destroy-service';
import { RoutingContext } from 'src/app/entities/routing/RoutingContext';
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    private roles?: string[];
    username?: string;
    isResourceCardOpen = false;
    headerTitle = 'WdgHub.HeaderTitleSettings';

    routingContext = new RoutingContext();

    showWatermark = environment.isRunningOnDevWidgetHUB;

    constructor(
        private router: Router,
        private authService: AuthService,
        private eventBusService: EventBusService,
        public categoryPathService: CategoryPathService,
        public uiAspectService: UiAspectService,
        public resourceService: ResourceService,
        public resourceSearchService: ResourceSearchService,
        public loadingStatusService: LoadingStatusService,
        private snackBarMessageService: SnackBarMessageService,
        public permissionService: PermissionService,
        private systemSettingService: SystemSettingService,
        private destroyService: DestroyService
    ) { }

    isLoggedIn(): boolean {
        return this.authService.isLoggedIn;
    }

    isMenuVisible(): boolean {
        return this.isLoggedIn() && this.permissionService.hasAnyRole(
            [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR, RoleName.ROLE_REVIEWER]
        );
    }

    canCreateResource() {
        return this.isLoggedIn() && this.permissionService.hasAnyRole(
            [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR]
        );
    }

    canCloneResource() {
        return this.isResourceCardOpen && this.isLoggedIn() && this.permissionService.hasAnyRole(
            [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR]
        );
    }

    canViewSettings() {
        return this.isLoggedIn() && this.permissionService.hasAnyRole(
            [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR, RoleName.ROLE_REVIEWER]
        );
    }

    ngOnInit(): void {
        if (this.authService.isLoggedIn) {
            const user = this.authService.getUser();
            this.roles = user.roles;

            this.username = user.username;

            this.eventBusService.on('logout', () => {
                this.logoutClient();
            });
        }

        this.resourceService.resourceInEdit.pipe(takeUntil(this.destroyService)).subscribe(res => {
            if (this.resourceService.isResourceInEdit()) {
                this.isResourceCardOpen = true;
            }
            else {
                this.isResourceCardOpen = false;
            }
        });

        this.router.events.pipe(takeUntil(this.destroyService),
            filter(event => event instanceof ActivationEnd),
            map(event => (<ActivationEnd>event).snapshot),
            map(snapshot => (<ActivatedRouteSnapshot>snapshot).data),
        ).subscribe(data => {
            if (data) {
                if (data['routingContext']) {
                    this.routingContext.setRoutingContext(data['routingContext']);
                } else {
                    this.routingContext.reset();
                }
            }
        });

    }

    logout(): void {
        if (this.authService.isLoggedIn) {
            try {
                const user = this.authService.getUser();
                console.log('Notify logout to server');
                this.authService.logout(user.id).subscribe(
                    {
                        next: (data) => {
                            console.log('Logout on server done. ', data);
                            window.location.href = '/home';
                        },
                        error: (err) => {
                            console.error(err.error.message);
                        }
                    }
                );
            } catch (e) {
                console.error(e);
            }
        }
        this.logoutClient();
    }

    logoutClient(): void {
        this.authService.loggedOut();
        this.roles = [];
    }

    toggleSideNav() {
        this.categoryPathService.toggleSideNav();
    }

    showBackButton() {
        return false; //this.categoryPathService.selectedPath && !this.categoryPathService.selectedPath?.showAll;
    }

    backToHome() {
        this.categoryPathService.resetSelectedPath();
        this.router.navigate([this.categoryPathService.getResourcePathRoute()]);
    }

    createResource() {
        this.resourceService.createNewEmptyResource().then(id => {
            this.router.navigate(['home/resource-detail', id]);
        }, err => {
            console.log(err);
        })
    }

    showSettingsSection() {
        this.router.navigate(['settings']);
    }

    returnToList() {
        console.debug('Return to category ' + this.categoryPathService.selectedCategory);
        this.router.navigate([
            this.categoryPathService.getResourcePathRoute(),
            this.categoryPathService.getCategoryParameterMap(this.categoryPathService.selectedPath),
        ]);
    }

    enableListView() {
        this.categoryPathService.listViewEnabled = true;
        this.returnToList();
    }

    enableGridView() {
        this.categoryPathService.listViewEnabled = false;
        this.returnToList();
    }

    cloneResource(event: any) {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            event.target.value = null;
            // Here you can access the real file
            console.log(selectedFile.relativePath, selectedFile);

            const formData = new FormData()
            formData.append('dwp', selectedFile, selectedFile.relativePath);
            const idResourceToClone = this.resourceService.resourceInEdit.getValue().id
            if (idResourceToClone) {
                this.loadingStatusService.startLoading();
                // id not passed because is generated on BE during clone.
                this.resourceService.cloneResourceDWP(idResourceToClone, formData)
                    .then((response: any) => {
                        this.snackBarMessageService.openSnackBar('Clone done successfully', SnackBarType.SUCCESS);
                    }, err => {
                        console.log(err);
                        const message = err.error?.message ?? err.message;
                        this.snackBarMessageService.openSnackBar(message, SnackBarType.ERROR);

                    })
                    .finally(() => {
                        this.loadingStatusService.stopLoading();
                    });
            }

        }
    }
}
