<div class="change-state-container" *ngIf="!authService.isGuestUser()">
    <ng-container *ngIf="showDraftBanner()">
        <span class="container-banner"><mat-icon color="warn">report_problem</mat-icon></span>
        <span class="state-banner"><small>{{'WdgHub.DraftBanner' | translate}}</small></span>
    </ng-container>
    <ng-container *ngIf="showStateLabel()">
        <span class="state-value"><small>{{stateValue | translate}}</small></span>
    </ng-container>
    <ng-container *ngIf="showStateDropDown()">
        <button class="change-state-button" mat-button [matMenuTriggerFor]="menu">
            <span>{{stateValue | translate}}</span>
            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
            <button mat-menu-item *ngFor="let state of possibleResourceStates() | keyvalue: preserveOrder" (click)="changeState(state.key)">
                <span>{{state.value | translate}}</span>
            </button>
        </mat-menu>
    </ng-container>
</div>
