import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, firstValueFrom, map, Observable, Subject, takeUntil, timeout } from 'rxjs';
import { Setting } from '../model/Setting';
import { environment } from '../../../../../environments/environment';
import { ThemeSetting } from '../../../../services/theme/theme-setting';
import { SnackBarMessageService, SnackBarType } from 'projects/snack-bar-message/src/public-api';
import { ServiceBuildSetting } from '../../../../services/build/service-build-setting';
import { DestroyService } from 'src/app/services/destroy/destroy-service';

const API_URL = environment.pathToService('systemSettings');
const SETTING_THEME_NAME = 'THEME';
const SETTING_SERVICE_BUILD_NAME = 'SERVICE_BUILD';
const HEADERS = new HttpHeaders().set('Accept', 'application/hal+json');
const UI_VERSION_URL = '/ui.version';

interface SettingsResponse {
    '_embedded': {
        systemSettings: Setting[]
    }
}

interface UiServiceSettingResponse {
    version: string,
    build: string,
    rev: string
}

@Injectable({
    providedIn: 'root'
})
export class SystemSettingService {

    private _isLoaded = false;
    private _cache = new BehaviorSubject<Setting[]>([]);
    isSectionOpen = false;

    constructor(
        private http: HttpClient,
        private snackBarMessageService: SnackBarMessageService,
        private destroyService: DestroyService
    ) { }

    private getSystemSettingsResponse(): Observable<Setting[]> {
        return this.http.get<SettingsResponse>(API_URL, { headers: HEADERS }).pipe(takeUntil(this.destroyService), map(u => u._embedded.systemSettings));
    }

    patchSystemSetting(newSetting: Setting): Promise<Setting> {
        const path = API_URL + '/' + newSetting.name;
        const body = {
            value: (typeof newSetting.value === 'object')
                ? JSON.stringify(newSetting.value)
                : newSetting.value
        }

        return firstValueFrom(this.http.patch<Setting>(path, body,{ headers: HEADERS })
            .pipe(takeUntil(this.destroyService), map(settingResponse => {
                this.reloadSystemCache();
                return settingResponse;
            }), catchError(err => {
                console.error(err);
                this.snackBarMessageService.openSnackBar(err.message, SnackBarType.ERROR);
                throw err;
            })));

    }

    // use this to get BehaviorSubject of settings so to intercept any change of them
    getSystemSettingsObs(): BehaviorSubject<Setting[]> {
        if (!this._isLoaded) {
            this.getSystemSettingsResponse().subscribe(
                {
                    next: (systemSettings: Setting[]) => {
                        this._isLoaded = true;
                        this._cache.next(systemSettings);
                    },
                    error: (e) => {
                        console.error(e);
                    }
                }
            );
        }
        return this._cache;
    }

    getSystemSettingsObsCache(): BehaviorSubject<Setting[]> {
        return this._cache;
    }

    // force reload of system settings.
    reloadSystemCache(): void {
        this._isLoaded = false;
        this.getSystemSettingsObs();
    }

    // use this so to get system settings as they are available (Promise).
    async getSystemSettings() {
        if (this._isLoaded) {
            return this.getSystemSettingsObs().getValue();
        }
        else {
            return firstValueFrom(this.getSystemSettingsObs());
        }
    }

    async getSettingByName(settingName: string): Promise<Setting | undefined> {
        const settings = await this.getSystemSettings();
        return this.filterSettingByName(settings, settingName);
    }

    async getThemeSettings(): Promise<ThemeSetting | undefined> {
        return this.getSettingByName(SETTING_THEME_NAME);
    }

    async getServiceBuildSettings(): Promise<ServiceBuildSetting | undefined> {
        return this.getSettingByName(SETTING_SERVICE_BUILD_NAME);
    }

    filterSettingByName(settings: Array<Setting>, settingName: string): Setting | undefined {
        return settings.find(s => s.name === settingName);
    }

    filterThemeSettings(settings: Array<Setting>): ThemeSetting | undefined {
        return this.filterSettingByName(settings, SETTING_THEME_NAME);
    }

    filterServiceBuildSettings(settings: Array<Setting>): ServiceBuildSetting | undefined {
        return this.filterSettingByName(settings, SETTING_SERVICE_BUILD_NAME);
    }

    public getUiServiceSettingsResponse(): Observable<UiServiceSettingResponse> {
        return this.http.get(UI_VERSION_URL, { responseType: 'text' })
            .pipe(takeUntil(this.destroyService), map(data => this.jsonize(data)));
    }

    private jsonize(data: string): UiServiceSettingResponse {
        data = '{"' + data
            .replace(/(\r\n|\r|\n)*$/, '')
            .replace(/=/g, '":"')
            .replace(/(\r\n|\r|\n)/g, '","')
            + '"}';
        return JSON.parse(data);
    }

}
