import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceService } from '../../services/resource.service';
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroy/destroy-service';
import { LoadingStatusService } from 'src/app/services/loading/loading-status.service';

@Component({
    selector: 'app-resource-detail',
    templateUrl: './resource-detail.component.html',
    styleUrls: ['./resource-detail.component.scss']
})
export class ResourceDetailComponent implements OnDestroy {

    resourceId!: string;
    constructor(
        private route: ActivatedRoute,
        public resourceService: ResourceService,
        private destroyService: DestroyService,
        private loadingStatusService: LoadingStatusService) {

        loadingStatusService.startLoading();
        this.route.params.pipe(takeUntil(this.destroyService)).subscribe(params => {
            this.resourceId = params['resourceId'];
            this.resourceService.initResourceInEdit(this.resourceId)
                .catch(err => {
                    window.location.href = '/home';
                }).finally(() => {
                    loadingStatusService.stopLoading();
                });
        });
    }

    ngOnDestroy(): void {
        this.resourceService.clearResourceInEdit();
    }

}
