import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { ResourceService } from '../../../services/resource.service';
import { ResourceFile } from '../../../ResourceFile';
import { ResourceFileTarget } from '../../../services/ResourceFileTarget';
import { galleryConfig } from './gallery.config';
import { ResourceDetails } from '../../../ResourceDetails';
import { takeUntil } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogService } from 'src/app/dialogs/confirmation/services/confirmation-dialog.service';
import { RoleName } from "../../../../../security/entities/role/RoleName";
import { PermissionService } from "../../../../../security/services/permission/permission.service";
import { DestroyService } from 'src/app/services/destroy/destroy-service';
import { AuthInterceptor } from "../../../../../security/services/auth/auth.interceptor";

@Component({
    selector: 'app-rd-gallery',
    templateUrl: './rd-gallery.component.html',
    styleUrls: ['./rd-gallery.component.scss']
})
export class RdGalleryComponent implements OnInit {

    isInEdit = false;
    dpr: any = {
        data: {},
        ctx: {}
    };
    theme: any;

    public path: object = {};
    public galleryFiles!: ResourceFile[];
    public previewFiles!: ResourceFile[];
    public requestHeaders: any = {
        'Accept': 'application/hal+json'
    };
    public galleryUri!: string;
    public previewUri!: string;
    public autoUpload = true;

    //slideshow
    public fileInfo = {};
    public config = galleryConfig;
    public isGalleryReady = false;

    constructor(
        public authService: AuthService,
        public resourceService: ResourceService,
        private changeDetectorRef: ChangeDetectorRef,
        private confirmationDialogService: ConfirmationDialogService,
        public permissionService: PermissionService,
        private translateService: TranslateService,
        private destroyService: DestroyService,
        public authInterceptor: AuthInterceptor
    ) {}

    ngOnInit(): void {

        this.theme = {
            accent: "rgba(255, 64, 129, 1)",
            background: "rgba(250, 250, 250, 1)",
            primary: "rgba(63, 81, 181, 1)",
            primaryColor: "#3dcd58",
            secondaryColor: "#219bfd",
            warn: "rgba(255, 87, 34, 1)"
        }

        this.resourceService.resourceInEdit.pipe(takeUntil(this.destroyService)).subscribe({
            next: (resourceDetails: ResourceDetails) => {
                if (resourceDetails?.id) {
                    this.isGalleryReady = false;
                    this.changeDetectorRef.detectChanges();

                    this.fileInfo = {
                        files: this.resourceService.getResourceGalleryFiles(),
                        uri: this.resourceService.getGalleryUri()
                    };

                    this.isGalleryReady = true;

                    this.galleryFiles = this.resourceService.getResourceGalleryFiles();
                    this.previewFiles = this.resourceService.getResourcePreviewFiles();

                    this.galleryUri = this.resourceService.getGalleryUri();
                    this.previewUri = this.resourceService.getPreviewUri();
                }

            },
            error: (e) => {
                console.log(e);
            }
        });

    }

    goInEdit() {
        this.isInEdit = true;
    }

    galleryFileUploaded($event: any) {
        this.resourceService.setResourceInEditByJson($event.e.currentTarget.responseText);
        this.galleryFiles = this.resourceService.getResourceGalleryFiles();
    }

    previewFileUploaded($event: any) {
        this.resourceService.setResourceInEditByJson($event.e.currentTarget.responseText);
        this.previewFiles = this.resourceService.getResourcePreviewFiles();
        this.resourceService.reloadResources();
    }

    galleryFileDelete(filename: string) {
        this.confirmationDialogService.showConfirmDialog(this.translateService.instant('WdgHub.AskDeleteGalleryFile')).subscribe(result => {
            if (result) {
                this.resourceService.deleteResourceFile(filename, ResourceFileTarget.GALLERY).then(res => {
                    this.galleryFiles = this.resourceService.getResourceGalleryFiles();
                });
            }
        });
    }

    previewFileDelete(filename: string) {
        this.confirmationDialogService.showConfirmDialog(this.translateService.instant('WdgHub.AskDeletePreviewFile')).subscribe(result => {
            if (result) {
                this.resourceService.deleteResourceFile(filename, ResourceFileTarget.HOME).then(res => {
                    this.previewFiles = this.resourceService.getResourcePreviewFiles();
                    this.resourceService.reloadResources();
                });
            }
        });

    }

    public isDwpLoaded(): boolean {
        return !!this.resourceService.getResourceInEdit()?.resourceDefined;
    }

    isEditable(): boolean {
        return this.authService.isLoggedIn && this.permissionService.hasAnyRole([RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR]);
    }

}
