import { Injectable } from '@angular/core';
import { filter, map, Subject, Subscription, takeUntil } from 'rxjs';
import { EventData } from './event-data';
import { DestroyService } from '../destroy/destroy-service';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private subject$ = new Subject<EventData>();

  constructor(private destroyService: DestroyService) { }

  emit(event: EventData) {
    this.subject$.next(event);
  }

  on(eventName: string, action: any): Subscription {
    return this.subject$.pipe(
      takeUntil(this.destroyService),
      filter((e: EventData) => e.name === eventName),
      map((e: EventData) => e["value"])).subscribe(action);
  }
}
