import { Injectable } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {getRoleName, RoleName} from '../../entities/role/RoleName';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    constructor(
        private authService: AuthService
    ) { }

    hasRole(roleName: RoleName): boolean {
        return this.authService.getUser().roles.includes(getRoleName(roleName));
    }

    hasAnyRole(roleNames: RoleName[]): boolean {
        return !roleNames || roleNames.some(roleName => this.hasRole(roleName));
    }

}
