import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourceDetails } from '../../entities/resource/ResourceDetails';
import { Wdata } from './wdata';
import { ResourceService } from '../../entities/resource/services/resource.service';
import { WdataEvent } from './enums/wdata-event';
import { WdataEventResult } from './enums/wdata-event-result';
import { EnvironmentBootstrapperService } from '../../startup/environment-bootstrapper.service';
import { ResourceDefinition, Version } from '../resource/InstalledResource';
import { DestroyService } from '../destroy/destroy-service';
import { firstValueFrom, map, takeUntil } from 'rxjs';

const API_URL = environment.pathToService('wdatas');
const HEADERS = new HttpHeaders().set('Accept', 'application/hal+json');


@Injectable({
    providedIn: 'root'
})
export class WdataService {

    constructor(
        private http: HttpClient,
        private resourceService: ResourceService,
        private injector: Injector,
        private destroyService: DestroyService
    ) { }

    postWdata(wdata: Wdata): Promise<ResourceDetails> {
        return new Promise((resolve, reject) => {
            this.http.post<ResourceDetails>(API_URL, wdata, { headers: HEADERS }).pipe(takeUntil(this.destroyService)).subscribe(
                {
                    next: value => {
                        console.log(value);
                        resolve(value);
                    },
                    error: (e) => {
                        reject(e);
                    }
                }
            );
        });
    }

    async collectWdataAndSend(
        event: WdataEvent,
        wdataEventResult: WdataEventResult,
        installingResource: ResourceDetails,
        previousResource?: ResourceDefinition,
        eventDetails?: string) {

        let wdata: Wdata | undefined;

        try {
            const environmentService = this.injector.get(EnvironmentBootstrapperService);
            if (event === WdataEvent.UPGRADE && previousResource && installingResource?.detail?.version) {
                const prevVer = previousResource.resourceVersion;
                const currVer = new Version(installingResource.detail.version);
                if (currVer.isLowerThan(prevVer)) {
                    event = WdataEvent.DOWNGRADE;
                }
            }

            wdata = {
                event: event,
                eventResult: wdataEventResult,
                eventDetails: eventDetails,
                resourceId: installingResource.id,
                widgetLogicalName: installingResource.detail?.logicalName,
                widgetDisplayName: installingResource.name,
                widgetInstalledVersion: previousResource?.resourceVersion.getVersion(),
                widgetVersion: installingResource.detail?.version,
                dacMetadataVersion: environmentService.dacEnvironmentInfo.metadataVersion,
                dacReleasePhase: environmentService.dacEnvironmentInfo.releasePhase,
                dacVersion: environmentService.dacEnvironmentInfo.version,
                dacWidgetApplicationApiLevel: environmentService.dacEnvironmentInfo.widgetApplicationApiLevel
            }
            await this.postWdata(wdata);
        } catch (e) {
            console.error('Error sending wdata information: ', (wdata ? wdata : ''));
            console.error('Error root cause: ', e);
        } finally {
            console.debug('End sending wdata information: ', (wdata ? wdata : ''));
        }
    }
}
