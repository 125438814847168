<div #mainContainer>
    <ejs-uploader #uploader class="uploader" name="file" allowedExtensions = '.jpg,.jpeg,.png,.mp4'
                    [autoUpload]="true" [dropArea]="mainContainer" [asyncSettings]="path" multiple="false"
                    (uploading)="prepareUploadRequest($event)" (success)="uploadSuccess($event)" (failure)="uploadFailure($event)"></ejs-uploader>

    <div *ngFor="let file of files">
        <button mat-icon-button (click)="deleteGalleryFile(file.name)">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-button (click)="openGalleryFile(file.name)">{{file.name}}</button>
    </div>
</div>
