export const galleryConfig = {
    "min-height":{
       "id":"min-height",
       "value":"500",
       "dataType":"STRING"
    },
    "min-width":{
       "id":"min-width",
       "value":"98%",
       "dataType":"STRING"
    },
    "$HideWatch":{
       "id":"$HideWatch",
       "value":true,
       "dataType":"BOOLEAN"
    },
    "$HideFullScreen":{
        "id":"$HideFullScreen",
        "value":true,
        "dataType":"BOOLEAN"
    },
    "$HideFullSize":{
        "id":"$HideFullSize",
        "value":!document.fullscreenEnabled,
        "dataType":"BOOLEAN"
    },
    "$HideBar":{
       "id":"$HideBar",
       "value":false,
       "dataType":"BOOLEAN"
    },
    "$AllowDownload":{
       "id":"$AllowDownload",
       "value":null,
       "dataType":"BOOLEAN"
    },
    "$VideoMuted":{
       "id":"$VideoMuted",
       "value":false,
       "dataType":"BOOLEAN"
    },
    "$VideoLoop":{
       "id":"$VideoLoop",
       "value":false,
       "dataType":"BOOLEAN"
    },
    "$ObjectFit":{
       "id":"$ObjectFit",
       "value":{
          "desc":"Scale no fit. If the image is smaller than its container, it is shown in its original size, otherwise the image is scaled to maintain its aspect ratio while fitting the container",
          "value":"scale-down"
       },
       "dataType":"OBJECT"
    },
    "$PreloadPageNumber":{
       "id":"$PreloadPageNumber",
       "value":5,
       "dataType":"NUMBER"
    },
    "$ScalingLevel":{
       "id":"$ScalingLevel",
       "value":{
          "desc":"Medium quality",
          "value":"1.5"
       },
       "dataType":"OBJECT"
    },
    "$SlidingSpeed":{
       "id":"$SlidingSpeed",
       "value":0,
       "dataType":"NUMBER"
    },
    "$ScreenSaverDelay":{
       "id":"$ScreenSaverDelay",
       "value":0,
       "dataType":"NUMBER"
    }
 };