<div class="path-list-container">
    <span class="hub-small-title wdg-cat-label">{{ 'WdgHub.WidgetCategories' | translate }}</span>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="setSelectedPath(node)" [ngClass]="isNodeSelected(node)?'node-selected':'tree-node'">
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button>
            <mat-icon class="mat-icon-rtl-mirror">
                {{getFolderIcon(node, treeControl.isExpanded(node))}}
              </mat-icon>
          </button>
          <span *ngIf='isCategoryUpdateAvailable(node)'
                class="category-badge"
                matBadge="{{getUpdateAvailableCount()}}"
                matBadgeColor="accent">{{node.name}}
          </span>
          <span *ngIf='!isCategoryUpdateAvailable(node)'>{{node.name}}</span>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle >
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
            </mat-icon>
          </button>
          <span>{{node.name}}</span>
        </mat-tree-node>
      </mat-tree>
</div>
