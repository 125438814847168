export class RoutingContext {

    isToShowHeaderTitle = false;
    isToShowSelectedCategoryButton = false;
    isToShowTextSearch = false;
    isToShowListGridButton = false;

    setRoutingContext(routingContext: any) {
        Object.keys(routingContext || {}).map(key => {
            this[key as keyof RoutingContext] = routingContext[key as keyof RoutingContext];
        });
    }

    setShowHeaderTitle(value: boolean): RoutingContext {
        this.isToShowHeaderTitle = value;
        return this;
    }

    setShowSelectedCategoryButton(value: boolean): RoutingContext {
        this.isToShowSelectedCategoryButton = value;
        return this;
    }

    setShowTextSearch(value: boolean): RoutingContext {
        this.isToShowTextSearch = value;
        return this;
    }

    setShowListGridButton(value: boolean): RoutingContext {
        this.isToShowListGridButton = value;
        return this;
    }

    reset() {
        this.isToShowHeaderTitle = false;
        this.isToShowSelectedCategoryButton = false;
        this.isToShowTextSearch = false;
        this.isToShowListGridButton = false;
    }

}