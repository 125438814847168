import {Component, OnInit, ViewChild} from '@angular/core';
import {TokenStorageService} from '../../services/token/token-storage.service';
import {User} from '../../entities/user/User';
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";
import { environment } from "../../../../environments/environment";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    host: {
        class:'card-centered'
    }
})
export class ProfileComponent implements OnInit {

    currentUser: User | undefined;
    form: any = {};
    formGroup: any = {};
    public accessToken = '';
    completeAccessLink = '';
    errorMessage = '';
    isGenTokenFailed = false;

    showRoles = environment.isRunningOnDevWidgetHUB || !environment.production;

    // datetimepicker
    minDate = new Date();
    maxDate = null;

    @ViewChild('dtpValue') dtpValue: any;

    constructor(
        private router: Router,
        private authService: AuthService,
        private token: TokenStorageService
    ) {
    }

    ngOnInit(): void {
        this.currentUser = this.token.getUser();
    }

    onSubmit(): void {
        this.isGenTokenFailed = false;
        this.authService.gentoken(this.form).subscribe(
            (accessToken: string) => {
                if (!accessToken) {
                    throw new Error('Invalid token or unauthorized user');
                }
                this.accessToken = accessToken;
                let port = window.location.port;
                if (port) {
                    port = ':' + port;
                }
                else {
                    port = '';
                }
                this.completeAccessLink =
                    window.location.protocol + '//' +
                    window.location.hostname +
                    port +
                    '/login?token=' + accessToken +
                    '&dac_embed=true';
            },
            err => {
                this.errorMessage = err.error.message ? err.error.message : err.message;
                this.isGenTokenFailed = true;
            }
        );
    }


    redirectToChangePassword() {
        this.router.navigate(['chgpwd']);
    }

}
