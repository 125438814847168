import {Pipe, PipeTransform} from '@angular/core';
import {map, Observable} from 'rxjs';
import {ResourceDetails} from '../../ResourceDetails';
import {IFilterElement} from './iFilterElement';

@Pipe({
  name: 'resourceFilter'
})
export class ResourceFilterPipe implements PipeTransform {

    transform(data: Observable<ResourceDetails[]>, filter: IFilterElement): Observable<ResourceDetails[]> {
        if (!data || !filter) {
            return data;
        }
        return data.pipe(
            map(items =>
                items.filter(item =>
                    this.filterByCategoryPath(item, filter) &&
                    this.filterByText(item, filter) &&
                    this.filterShareLib(item, filter)
                )));
    }

    filterByCategoryPath(item: ResourceDetails, filter: IFilterElement) {
        return !filter.categoryPath ||
            filter.categoryPath.showAll ||
            filter.categoryPath.showUpgrade ||
            filter.categoryPath.name === item.categoryPath ||
            (filter.categoryPath.showOthers && !item.categoryPath);
    }

    filterByText(item: ResourceDetails, filter: IFilterElement) {
        if (filter.textSearch) {
            return item.name?.toUpperCase().includes(filter.textSearch.toUpperCase());
        }
        return true;
    }

    filterShareLib(item: ResourceDetails, filter: IFilterElement) {
        if (!filter.showSharedLib) {
            return item.detail?.resourceType !== 'SHARED_LIB';
        }
        return true;
    }

}
