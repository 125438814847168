import { Component, OnInit, ViewChild } from '@angular/core';
import { ResourceDetails } from '../../ResourceDetails';
import { ResourceService } from '../../services/resource.service';
import { CategoryPathService } from '../../services/categoryPath/category-path.service';
import { ResourceSearchService } from '../../services/resource-search.service';
import { ListItem } from './ListItem';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ResourceFilterPipe } from '../../pipe/resourceFilter/resource-filter.pipe';
import { AuthService } from '../../../../security/services/auth/auth.service';
import { ResourceInstallerHandlerService } from '../../../../services/resource/resource-installer-handler.service';
import { combineLatest, takeUntil } from 'rxjs';
import { DacCommunicationLayerService } from '../../../../dacAPIs/dac-communication-layer.service';
import { ResourceStateService } from '../../services/resource-state.service';
import { FilterElement } from '../../pipe/resourceFilter/FilterElement';
import { UpgradeCompatibilityOutcome } from '../../../../services/resource/InstalledResource';
import { TranslateService } from "@ngx-translate/core";
import { PermissionService } from "../../../../security/services/permission/permission.service";
import { RoleName } from "../../../../security/entities/role/RoleName";
import { ResourceKey } from "../../../../services/resource/ResourceKey";
import { CategoryPath } from "../../services/categoryPath/CategoryPath";
import { DestroyService } from 'src/app/services/destroy/destroy-service';
import { ICategoryPathNode } from '../../services/categoryPath/iCategoryPathNode';

@Component({
    selector: 'app-resource-list',
    templateUrl: './resource-list.component.html',
    styleUrls: ['./resource-list.component.scss']
})
export class ResourceListComponent implements OnInit {
    listItems = new Array<ListItem>();
    dataSource = new MatTableDataSource(this.listItems);
    private _displayedColumns = new Array<string>();
    @ViewChild(MatSort) sort!: MatSort;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private resourceService: ResourceService,
        public resourceStateService: ResourceStateService,
        public categoryPathService: CategoryPathService,
        private resourceFilterPipe: ResourceFilterPipe,
        private authService: AuthService,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService,
        public resourceSearchService: ResourceSearchService,
        public dacCommunicationService: DacCommunicationLayerService,
        private translateService: TranslateService,
        public permissionService: PermissionService,
        private destroyService: DestroyService
    ) {
    }

    ngOnInit(): void {
        combineLatest([
            this.activeRoute.paramMap,
            this.resourceSearchService.obsTextSearch,
            this.resourceInstallerHandlerService.upgradeCompatibilityOutcomeObservable,
            this.categoryPathService.categoryPathTreeObservable])
            .pipe(takeUntil(this.destroyService))
            .subscribe((obs: [ParamMap, string, Map<string, UpgradeCompatibilityOutcome>, ICategoryPathNode[]]) => {
                this.categoryPathService.listViewEnabled = true;
                const category = obs[0].get(CategoryPathService.CATEGORY_PATH);
                const textSearch = obs[1];
                this.categoryPathService.alignSelectedPath(category);
                const filter = FilterElement.buildResourceFilter()
                    .addCategoryPath(this.categoryPathService.selectedPath)
                    .addTextSearch(textSearch)
                    .addShowSharedLib(this.isToShowSharedLibs());
                this.resourceService.getResources().then(observableResources => {
                    this.resourceFilterPipe
                        .transform(observableResources, filter)
                        .pipe(takeUntil(this.destroyService))
                        .subscribe(
                            {
                                next: (resources: ResourceDetails[]) => {
                                    this.listItems = resources
                                        .map(rd => new ListItem(
                                            rd,
                                            this.installedDisplayVersion(rd),
                                            this.updateAvailable(rd),
                                            this.translateService,
                                            this.resourceInstallerHandlerService,
                                            this.destroyService))
                                        .filter(listItem =>
                                            this.categoryPathService.selectedPath?.categoryPath !==
                                            CategoryPath.UPGRADE_AVAILABLE || this.isToShowUpgrade(listItem));
                                    this.dataSource = new MatTableDataSource(this.listItems);
                                    this.dataSource.sort = this.sort;
                                },
                                error: (e) => {
                                    console.error(e);
                                }
                            }
                        );
                })
            });
    }

    installedDisplayVersion(resource: ResourceDetails): string {
        let installedWdg = this.resourceInstallerHandlerService.getInstalledWidget(resource?.detail?.logicalName);
        if (installedWdg) {
            return installedWdg.resourceVersion.getDisplayVersion(this.translateService);
        }
        return '';
    }

    updateAvailable(resource: ResourceDetails): UpgradeCompatibilityOutcome {
        const outcome = this.resourceInstallerHandlerService.getUpgradeCompatibility(resource);
        return outcome;
    }

    showResourceDetail(id: string | undefined) {
        this.router.navigate(['home/resource-detail', id]);
    }

    openLink(link: string | undefined, e: Event) {
        if (link) {
            window.open(link, '_blank')
        }
    }

    getUpdateAndChangelogToolTip(link: string | undefined) {
        if (link) {
            return this.translateService.instant('WdgHub.UpgradeAvailableWithChangelog');
        }
        else {
            return this.translateService.instant('WdgHub.UpgradeAvailableWithoutChangelog');
        }
    }

    get displayedColumns(): Array<string> {
        this._displayedColumns = ['name', 'version'];
        if (this.isToShowStateColumn()) {
            this._displayedColumns.push('state');
        }
        if (this.dacCommunicationService.isEmbeddedOnDac()) {
            this._displayedColumns.push('installedVersion');
            this._displayedColumns.push('linkChangelog');
        }
        if (this.isToShowExtraColumns()) {
            this._displayedColumns.push('categoryPath');
        }
        this._displayedColumns.push('lastUpdate');
        return this._displayedColumns;
    }

    isToShowStateColumn(): boolean {
        return !this.authService.isGuestUser() && !this.permissionService.hasRole(RoleName.ROLE_QA);
    }

    isToShowSharedLibs(): boolean {
        return !this.authService.isGuestUser() && !this.permissionService.hasRole(RoleName.ROLE_QA);
    }

    isToShowExtraColumns(): boolean {
        let categoryPath = this.categoryPathService.selectedPath?.categoryPath
        if (categoryPath) {
            switch (categoryPath) {
                case CategoryPath.ALL:
                case CategoryPath.UPGRADE_AVAILABLE:
                    return true;
            }
        }
        return false;
    }

    getInstalledToolTip(listItem: ListItem): string {
        if (this.compatibilityProblemFound(listItem)) {
            return this.getCompatibilityProblemMessage(listItem);
        }
        return this.translateService.instant('WdgHub.InstalledWidgetTooltip');
    }

    getInstallToolTip(listItem: ListItem): string {
        if (!listItem.isInstallingStatus) {
            if (this.compatibilityProblemFound(listItem)) {
                return this.getCompatibilityProblemMessage(listItem);
            }
            return this.translateService.instant('WdgHub.InstallWidgetTooltip');
        }
        return '';
    }

    getUpgradeToolTip(listItem: ListItem): string {
        if (!listItem.isInstallingStatus) {
            if (this.compatibilityProblemFound(listItem)) {
                return this.getCompatibilityProblemMessage(listItem);
            }
            return this.translateService.instant('WdgHub.UpgradeWidgetTooltip');
        }
        return '';
    }

    getDowngradeToolTip(listItem: ListItem): string {
        if (!listItem.isInstallingStatus) {
            if (this.compatibilityProblemFound(listItem)) {
                return this.getCompatibilityProblemMessage(listItem);
            }
            return this.translateService.instant('WdgHub.DowngradeWidgetTooltip');
        }
        return '';
    }

    isToShowInstallAndChangelog(listItem: ListItem) {
        return this.isToShowUpgrade(listItem) && listItem.linkChangelog?.trim();
    }

    isToShowInstall(listItem: ListItem) {
        return this.resourceInstallerHandlerService.isToShowInstall(listItem.compatibilityOutcome);
    }

    isToShowUpgrade(listItem: ListItem) {
        return this.resourceInstallerHandlerService.isToShowUpgrade(listItem.compatibilityOutcome);
    }

    isToShowDowngrade(listItem: ListItem) {
        return this.resourceInstallerHandlerService.isToShowDowngrade(listItem.compatibilityOutcome);
    }

    isToShowInstalled(listItem: ListItem) {
        return this.resourceInstallerHandlerService.isToShowInstalled(listItem.compatibilityOutcome, false);
    }

    compatibilityProblemFound(listItem: ListItem): boolean {
        return this.resourceInstallerHandlerService.compatibilityProblemFound(listItem.compatibilityOutcome);
    }

    getCompatibilityProblemMessage(listItem: ListItem) {
        return this.resourceInstallerHandlerService.getCompatibilityProblemMessage(listItem.compatibilityOutcome);
    }

    isInstallationBlocked(listItem: ListItem): boolean {
        return this.resourceInstallerHandlerService
            .isInstallationBlocked(listItem.resource, listItem.compatibilityOutcome);
    }

    installResource(listItem: ListItem, e: Event) {
        e.stopPropagation();
        return this.resourceInstallerHandlerService.installResource(listItem.resource);
    }

    upgradeResource(listItem: ListItem, e: Event) {
        e.stopPropagation();
        return this.resourceInstallerHandlerService.upgradeResource(true, listItem.resource);
    }

    downgradeResource(listItem: ListItem, e: Event) {
        e.stopPropagation();
        return this.resourceInstallerHandlerService.upgradeResource(false, listItem.resource);
    }

}
