import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { LoginComponent } from './security/components/login/login.component';
import { RegisterComponent } from './security/components/register/register.component';
import { ChangePasswordComponent } from "./security/components/change-password/change-password.component";
import { ProfileComponent } from './security/components/profile/profile.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation/confirmation-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { authInterceptorProviders } from './security/services/auth/auth.interceptor';
import { MaterialModule } from './modules/material.module';
import { ResourceCardComponent } from './entities/resource/component/resource-card/resource-card.component';
import { ResourceCatalogueComponent } from './entities/resource/component/resource-catalogue/resource-catalogue.component';
import { CategoryPathTreeComponent } from './entities/resource/component/category-path-tree/category-path-tree.component';
import { ResourceCardsComponent } from './entities/resource/component/resource-cards/resource-cards.component';
import { ResourceDetailComponent } from './entities/resource/component/resource-detail/resource-detail.component';
import { ResourceFilterPipe } from './entities/resource/pipe/resourceFilter/resource-filter.pipe';
import { EnvironmentBootstrapperService } from './startup/environment-bootstrapper.service';
import { HeaderComponent } from './components/header/header.component';
import { RdHeaderComponent } from './entities/resource/component/resource-detail-components/rd-header/rd-header.component';
import { RdDescriptionComponent } from './entities/resource/component/resource-detail-components/rd-description/rd-description.component';
import { RdGalleryComponent } from './entities/resource/component/resource-detail-components/rd-gallery/rd-gallery.component';
import { RdInfoComponent } from './entities/resource/component/resource-detail-components/rd-info/rd-info.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileManagerComponent } from './entities/resource/component/resource-detail-components/file-manager/file-manager.component';
import { RdManualsComponent } from './entities/resource/component/resource-detail-components/rd-manuals/rd-manuals.component';
import { ResourceListComponent } from './entities/resource/component/resource-list/resource-list.component';
import { RdVersionSelectorComponent } from './entities/resource/component/resource-detail-components/rd-info-components/rd-version-selector/rd-version-selector.component';
import { RdChangeStateComponent } from './entities/resource/component/resource-detail-components/rd-header-components/rd-change-state/rd-change-state.component';
import { SettingListComponent } from './entities/setting/setting-list/setting-list.component';
import { DcyTranslateModule } from '@decisyon/dcy-translate-lib';
import { ClipboardModule } from "@angular/cdk/clipboard";
import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { DestroyService } from './services/destroy/destroy-service';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { SecurePipe } from "./security/services/auth/secure.pipe";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        ChangePasswordComponent,
        HomeComponent,
        ProfileComponent,
        ConfirmationDialogComponent,
        ResourceCardComponent,
        ResourceCatalogueComponent,
        CategoryPathTreeComponent,
        ResourceCardsComponent,
        ResourceDetailComponent,
        ResourceFilterPipe,
        SecurePipe,
        HeaderComponent,
        RdHeaderComponent,
        RdDescriptionComponent,
        RdGalleryComponent,
        RdInfoComponent,
        FileManagerComponent,
        RdManualsComponent,
        ResourceListComponent,
        RdVersionSelectorComponent,
        RdChangeStateComponent,
        SettingListComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        EditorModule,
        NgxFileDropModule,
        DcyTranslateModule,
        ClipboardModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        UploaderModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        DestroyService,
        EnvironmentBootstrapperService,
        { provide: APP_INITIALIZER, useFactory: bootStrapStarter, deps: [EnvironmentBootstrapperService], multi: true },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
                showDelay: 1000
            }
        },
        authInterceptorProviders,
        ResourceFilterPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function bootStrapStarter(config: EnvironmentBootstrapperService) {
    return () => config.init();
}
