import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './security/components/login/login.component';
import { RegisterComponent } from './security/components/register/register.component';
import { ChangePasswordComponent } from "./security/components/change-password/change-password.component";
import { ProfileComponent } from './security/components/profile/profile.component';
import { HomeComponent } from './components/home/home.component';
import { ResourceCardsComponent } from './entities/resource/component/resource-cards/resource-cards.component';
import { ResourceDetailComponent } from './entities/resource/component/resource-detail/resource-detail.component';
import { ResourceListComponent } from './entities/resource/component/resource-list/resource-list.component';
import { SettingListComponent } from './entities/setting/setting-list/setting-list.component';
import { RoleName } from './security/entities/role/RoleName';
import { AuthGuard } from './security/services/auth/auth.guard';
import { RoutingContext } from './entities/routing/RoutingContext';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        children: [
            {
                path: '',
                redirectTo: '',
                pathMatch: 'full'
            },
            {
                path: 'resource-cards',
                component: ResourceCardsComponent,
                data: {
                    routingContext: new RoutingContext()
                        .setShowSelectedCategoryButton(true)
                        .setShowTextSearch(true)
                        .setShowListGridButton(true)
                }
            },
            {
                path: 'resource-list',
                component: ResourceListComponent,
                data: {
                    routingContext: new RoutingContext()
                        .setShowSelectedCategoryButton(true)
                        .setShowTextSearch(true)
                        .setShowListGridButton(true)
                }
            },
            {
                path: 'resource-detail/:resourceId',
                component: ResourceDetailComponent,
                data: {
                    routingContext: new RoutingContext().setShowSelectedCategoryButton(true)
                }
            }
        ],
        data: {
            routingContext: {}
        }
    },
    { path: 'login', component: LoginComponent },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AuthGuard],
        data: { roles: [RoleName.ROLE_ADMIN] }
    },
    {
        path: 'chgpwd',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        data: { roles: [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR, RoleName.ROLE_REVIEWER, RoleName.ROLE_QA, RoleName.ROLE_USER] }
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR, RoleName.ROLE_REVIEWER, RoleName.ROLE_QA, RoleName.ROLE_USER] }
    },
    {
        path: 'settings',
        component: SettingListComponent,
        canActivate: [AuthGuard],
        data: {
            roles: [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR, RoleName.ROLE_REVIEWER],
            routingContext: new RoutingContext().setShowHeaderTitle(true)
        }
    },
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
