import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { ResourceDefinition, UpgradeCompatibilityOutcome } from 'src/app/services/resource/InstalledResource';
import { ResourceInstallerHandlerService } from 'src/app/services/resource/resource-installer-handler.service';
import { ResourceDetails } from '../../ResourceDetails';
import { ResourceStateService } from '../../services/resource-state.service';
import { ResourceService } from '../../services/resource.service';
import { TranslateService } from "@ngx-translate/core";
import { RoleName } from "../../../../security/entities/role/RoleName";
import { PermissionService } from "../../../../security/services/permission/permission.service";
import { ResourceKey } from "../../../../services/resource/ResourceKey";
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroy/destroy-service';

@Component({
    selector: 'app-resource-card',
    templateUrl: './resource-card.component.html',
    styleUrls: ['./resource-card.component.css']
})
export class ResourceCardComponent implements OnInit {

    resourceInfo: ResourceDetails | undefined;
    resourcePreviewUri: string = '/assets/images/no-image.jpg'
    noImage = true;
    isInstallingStatus = false;

    private compatibilityOutcome!: UpgradeCompatibilityOutcome;
    @Input()
    set resource(res: ResourceDetails | undefined) {
        this.resourceInfo = res;
        if (res?.id && res?.previewHome) {
            this.resourcePreviewUri = this.resourceService.getResourcePreviewUri(res.id, res.previewHome);
            this.noImage = false;
        }
    };


    constructor(
        private router: Router,
        private resourceService: ResourceService,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService,
        public resourceStateService: ResourceStateService,
        public authService: AuthService,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private destroyService: DestroyService
    ) {
    }

    ngOnInit(): void {
        if (this.resourceInfo?.detail?.logicalName) {
            this.resourceInstallerHandlerService.upgradeCompatibilityOutcomeObservable
                .pipe(takeUntil(this.destroyService))
                .subscribe({
                    next: (items: Map<string, UpgradeCompatibilityOutcome>) => {
                        if (this.resourceInfo) {
                            this.compatibilityOutcome = this.resourceInstallerHandlerService
                                .getUpgradeCompatibility(this.resourceInfo);
                            console.debug(`resource: ${this.resourceInfo.name} compatibilityOutcome:`, this.compatibilityOutcome);
                        }
                    },
                    error: (e) => {
                        console.error(e);
                    }
                });

            let resourceKey = ResourceKey.fromResourceDetails(this.resourceInfo);
            if (resourceKey) {
                const obsInstalligStatus = this.resourceInstallerHandlerService.obsInstallingStatus(resourceKey);
                if (obsInstalligStatus) {
                    obsInstalligStatus.pipe(takeUntil(this.destroyService)).subscribe({
                        next: (isInstallingStatus) => {
                            this.isInstallingStatus = isInstallingStatus;
                        },
                        error: (e) => console.error(e)
                    });
                }
            }
        }
    }

    showResourceDetail(id: string | undefined) {
        this.router.navigate(['home/resource-detail', id]);
    }

    openLink(link: string | undefined, e: Event) {
        e.stopPropagation();
        if (link) {
            window.open(link, '_blank')
        }
    }

    getUpdateAndChangelogToolTip(link: string | undefined) {
        if (link) {
            return this.translateService.instant('WdgHub.UpgradeAvailableWithChangelog');
        } else {
            return this.translateService.instant('WdgHub.UpgradeAvailableWithoutChangelog');
        }
    }

    getInstalledToolTip(): string {
        if (this.compatibilityProblemFound()) {
            return this.getCompatibilityProblemMessage();
        }
        return this.translateService.instant('WdgHub.InstalledWidgetTooltip');
    }

    getInstallToolTip(): string {
        if (!this.isInstallingStatus) {
            if (this.compatibilityProblemFound()) {
                return this.getCompatibilityProblemMessage();
            }
            return this.translateService.instant('WdgHub.InstallWidgetTooltip');
        }
        return '';
    }

    getUpgradeToolTip(): string {
        if (!this.isInstallingStatus) {
            if (this.compatibilityProblemFound()) {
                return this.getCompatibilityProblemMessage();
            }
            return this.translateService.instant('WdgHub.UpgradeWidgetTooltip');
        }
        return '';
    }

    getDowngradeToolTip(): string {
        if (!this.isInstallingStatus) {
            if (this.compatibilityProblemFound()) {
                return this.getCompatibilityProblemMessage();
            }
            return this.translateService.instant('WdgHub.DowngradeWidgetTooltip');
        }
        return '';
    }

    isToShowInstallAndChangelog() {
        return this.isToShowUpgrade() && this.resourceInfo?.linkChangelog?.trim();
    }

    isToShowInstall() {
        return this.resourceInstallerHandlerService.isToShowInstall(this.compatibilityOutcome);
    }

    isToShowUpgrade() {
        return this.resourceInstallerHandlerService.isToShowUpgrade(this.compatibilityOutcome);
    }

    isToShowDowngrade() {
        return this.resourceInstallerHandlerService.isToShowDowngrade(this.compatibilityOutcome);
    }

    isToShowInstalled() {
        return this.resourceInstallerHandlerService.isToShowInstalled(this.compatibilityOutcome, false);
    }

    compatibilityProblemFound(): boolean {
        return this.resourceInstallerHandlerService.compatibilityProblemFound(this.compatibilityOutcome);
    }

    getCompatibilityProblemMessage() {
        return this.resourceInstallerHandlerService.getCompatibilityProblemMessage(this.compatibilityOutcome);
    }

    isInstallationBlocked(): boolean {
        return this.resourceInstallerHandlerService
            .isInstallationBlocked(this.resourceInfo, this.compatibilityOutcome);
    }

    installResource(e: Event) {
        e.stopPropagation();
        return this.resourceInstallerHandlerService.installResource(this.resourceInfo);
    }

    upgradeResource(e: Event) {
        e.stopPropagation();
        return this.resourceInstallerHandlerService.upgradeResource(true, this.resourceInfo);
    }

    downgradeResource(e: Event) {
        e.stopPropagation();
        return this.resourceInstallerHandlerService.upgradeResource(false, this.resourceInfo);
    }

    isToShowStateColumn(): boolean {
        return !this.authService.isGuestUser() && !this.permissionService.hasRole(RoleName.ROLE_QA);
    }

}
