// Built-in roles.
export enum RoleName {

  ROLE_ADMIN,
  ROLE_EDITOR,
  ROLE_REVIEWER,
  ROLE_QA,
  ROLE_USER

}

export function getRoleName(roleName: RoleName): string {
    return RoleName[roleName];
}
