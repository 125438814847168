<div *ngIf="isDwpLoaded()" class="dcyw-widget-info-heading hub-small-title">
    <span>About widget</span>
    <span class="item-spacer"></span>
    <ng-container *ngIf="!authService.isGuestUser() && isStateWip()">
        <button mat-icon-button *ngIf="!isInEdit" color="primary" (click)="isInEdit = true">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isInEdit" color="primary" (click)="isInEdit = false">
            <mat-icon>done_outline</mat-icon>
        </button>
    </ng-container>
</div>
<div *ngIf="isDwpLoaded()" class="widget-info-body">
    <p class="widget-info-label">Version:</p>
    <p class="widget-info-label-small">
        <app-rd-version-selector [resourceVersions]="resourceVersions"
                    [selectedVersion]="resourceService.getResourceInEdit()?.detail?.version"
                    [selectedId]="resourceService.getResourceInEdit()?.id">
        </app-rd-version-selector>
    </p>
    <p class="widget-info-label">Type:</p>
    <p class="widget-info-label-small" [attr.dcy-wdg-type]="resourceService.getResourceInEdit()?.detail?.resourceType">{{resourceType}}</p>
    <p class="widget-info-label">Last update:</p>
    <p class="widget-info-label-small">{{resourceService.getResourceInEdit()?.detail?.updateMillis | date:'EEEE, MMMM d, y'}}</p>
    <p class="widget-info-label">Author:</p>
    <p class="widget-info-label-small">{{resourceService.getResourceInEdit()?.detail?.authors}}</p>
</div>
<app-rd-manuals *ngIf="isDwpLoaded()"></app-rd-manuals>
<div class="actions hub-small-title">
    {{ (isDwpLoaded() ? 'WdgHub.ActionWidget' : 'WdgHub.ActionRequiredUploadWidget') | translate }}
</div>
<div class="actions-container">
    <ng-container *ngIf="!isInEdit || authService.isGuestUser()">
        <button mat-raised-button color="primary" *ngIf="isToShowDownload()" (click)="downloadResource()">
            {{ 'WdgHub.DownloadWidget' | translate | uppercase }}
        </button>
        <div *ngIf="isToShowInstall()">
            <button mat-raised-button color="primary" (click)="installResource()" [disabled]="isInstallationBlocked()">
                {{ 'WdgHub.InstallWidget' | translate | uppercase }}
                <mat-icon *ngIf="isInstallingStatus" class="installing-rotating-icon">
                    <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-icon>
            </button>
            <div class="wdg-version" *ngIf="compatibilityProblemFound()">
                <span><mat-icon color="warn">report_problem</mat-icon></span>
                <span>{{ getCompatibilityProblemMessage() }}</span>
            </div>
        </div>
        <div *ngIf="isToShowUpgrade()">
            <button mat-raised-button color="primary" (click)="upgradeResource(true)" [disabled]="isInstallationBlocked()">
                {{ 'WdgHub.UpgradeWidget' | translate | uppercase }}
                <mat-icon *ngIf="isInstallingStatus" class="installing-rotating-icon">
                    <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-icon>
            </button>
            <div class="wdg-version" *ngIf="upgradeCompatibilityOutcome.resourceInstalledOnDac?.resourceVersion">
                <mat-icon color="primary">info</mat-icon>
                <span>{{ 'WdgHub.ActualWidgetVersion' | translate: {version: upgradeCompatibilityOutcome.resourceInstalledOnDac?.resourceVersion?.getVersion()} }}</span>
            </div>
            <div class="wdg-version" *ngIf="compatibilityProblemFound()">
                <span><mat-icon color="warn">report_problem</mat-icon></span>
                <span>{{ getCompatibilityProblemMessage() }}</span>
            </div>
        </div>
        <div *ngIf="isToShowDowngrade()">
            <button mat-raised-button color="primary" (click)="upgradeResource(false)" [disabled]="isInstallationBlocked()">
                {{ 'WdgHub.DowngradeWidget' | translate | uppercase }}
                <mat-icon *ngIf="isInstallingStatus" class="installing-rotating-icon">
                    <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-icon>
            </button>
            <div class="wdg-version" *ngIf="upgradeCompatibilityOutcome.resourceInstalledOnDac?.resourceVersion">
                <span><mat-icon color="warn">info</mat-icon></span>
                <span>{{ 'WdgHub.PreviousWidgetVersion' | translate: {version: upgradeCompatibilityOutcome.resourceInstalledOnDac?.resourceVersion?.getVersion()} }}</span>
            </div>
            <div class="wdg-version" *ngIf="compatibilityProblemFound()">
                <span><mat-icon color="warn">report_problem</mat-icon></span>
                <span>{{ getCompatibilityProblemMessage() }}</span>
            </div>
        </div>
        <button mat-raised-button color="primary" *ngIf="isToShowInstalled()" [disabled]="true" class="wgd-installed">
            {{ 'WdgHub.WidgetInstalled' | translate | uppercase }}
        </button>
    </ng-container>
    <ng-container *ngIf="isInEdit && !authService.isGuestUser()">
        <ngx-file-drop dropZoneClassName="ngx-file-drop-zone" contentClassName="ngx-file-drop-content" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (click)="clickFileSelector()">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <span>{{ 'WdgHub.UploadDwp' | translate | uppercase }}</span>
                <button #fileSelectorButton type="button" class="fileSelector" (click)="openFileSelector()">{{ 'WdgHub.BrowseFiles' | translate }}</button>
            </ng-template>
        </ngx-file-drop>

    </ng-container>
</div>
