<div *ngIf="loginReady">
    <div class="card card-container">
        <img id="profile-img" src="/assets/images/avatar_2x.png" class="profile-img-card" />
        <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <mat-form-field class="form-group">
                <mat-label for="username">{{'WdgHub.Username' | translate}}</mat-label>
                <input matInput id="username" type="text" class="form-control" name="username"
                    [(ngModel)]="form.username" required #username="ngModel" />
                <mat-error *ngIf="username.invalid">
                    {{'WdgHub.UsernameRequired' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-group">
                <mat-label for="password">{{'WdgHub.Password' | translate}}</mat-label>
                <input matInput id="password" type="password" class="form-control" name="password"
                    [(ngModel)]="form.password" required minlength="6" #password="ngModel" />
                <mat-error *ngIf="password.errors && password.errors['required']">{{'WdgHub.PasswordRequired' |
                    translate}}</mat-error>
                <mat-error *ngIf="password.errors && password.errors['minlength']">
                    {{'WdgHub.PasswordMinLength' | translate}}
                </mat-error>
            </mat-form-field>
            <div class="form-group">
                <button mat-flat-button class="btn btn-primary btn-block" color="primary">
                    {{'WdgHub.Login' | translate}}
                </button>
            </div>
            <div class="form-group">
                <div class="alert alert-danger" role="alert" *ngIf="isLoginFailed">
                    {{'WdgHub.LoginFailed' | translate}}: {{ errorMessage }}
                </div>
            </div>
        </form>
    </div>
</div>