import {ResourceDetails} from "../../entities/resource/ResourceDetails";

export class ResourceKey {
    constructor(
        public id: string,
        public logicalName: string
    ) {}

    static fromResourceDetails(resource: ResourceDetails): ResourceKey | undefined {
        if (!resource.id || !resource.detail?.logicalName) {
            return undefined;
        }
        return new ResourceKey(
            resource.id,
            resource.detail.logicalName,
        );
    }
}
