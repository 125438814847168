import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ResourceDetails } from 'src/app/entities/resource/ResourceDetails';
import { ResourceState } from 'src/app/entities/resource/ResourceState';
import { LoadingStatusService } from 'src/app/services/loading/loading-status.service';
import { ResourceStateService } from 'src/app/entities/resource/services/resource-state.service';
import { ResourceService } from 'src/app/entities/resource/services/resource.service';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { RoleName } from '../../../../../../security/entities/role/RoleName';
import { PermissionService } from '../../../../../../security/services/permission/permission.service';
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogService } from 'src/app/dialogs/confirmation/services/confirmation-dialog.service';
import { Version } from "../../../../../../services/resource/InstalledResource";
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroy/destroy-service';

@Component({
    selector: 'app-rd-change-state',
    templateUrl: './rd-change-state.component.html',
    styleUrls: ['./rd-change-state.component.scss']
})
export class RdChangeStateComponent implements OnInit {

    stateEditable = false;
    stateValue!: ResourceState;
    constructor(public authService: AuthService,
        public resourceService: ResourceService,
        private resourceStateService: ResourceStateService,
        public loadingStatusService: LoadingStatusService,
        public permissionService: PermissionService,
        private confirmationDialogService: ConfirmationDialogService,
        private translateService: TranslateService,
        private destroyService: DestroyService) { }

    ngOnInit(): void {
        if (!this.authService.isGuestUser()) {
            this.stateEditable = this.isDwpLoaded() && this.permissionService.hasAnyRole(
                [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR, RoleName.ROLE_REVIEWER]);
        }

        this.resourceService.resourceInEdit.pipe(takeUntil(this.destroyService)).subscribe({
            next: (resourceInEdit: ResourceDetails) => {
                if (resourceInEdit) {
                    this.stateValue = this.resourceStateService.getStateEnum(resourceInEdit.state);
                    this.stateEditable = this.isDwpLoaded() && this.permissionService.hasAnyRole(
                        [RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR, RoleName.ROLE_REVIEWER]);
                }
            },
            error: (e) => {
                console.log(e);
            }
        });
    }

    preserveOrder = (a: KeyValue<string, ResourceState>, b: KeyValue<string, ResourceState>): number => {
        return 0;
    }

    changeState(state: string) {
        if (this.resourceService.getResourceInEdit()?.state !== state) {
            const isPublishing = this.resourceStateService.isStatePublished(state);
            const isUnpublishing = this.resourceStateService.isStatePublished(this.resourceService.getResourceInEdit()?.state);
            let label: string;
            let promise = Promise.resolve();
            if (isPublishing) {
                promise = this.resourceService.checkIfExistAnotherWidgetAlreadyPublished().then(exist => {
                    label = exist ? 'WdgHub.AskPublishThisWidget' : 'WdgHub.AskPublishWidget';
                });
            } else if (isUnpublishing) {
                label = 'WdgHub.AskUnpublishWidget';
            } else {
                label = 'WdgHub.AskChangeStateWidget';
            }
            promise.then(() => {
                this.confirmationDialogService.showConfirmDialog(this.translateService.instant(label)).subscribe(result => {
                    if (result) {
                        this.setWidgetState(state)
                    }
                });
            })
        }

    }

    setWidgetState(state: string) {
        this.loadingStatusService.startLoading();
        this.resourceService.updateResource(this.resourceService.getResourceInEdit()?.id, {
            state: state
        }).then(res => {
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.loadingStatusService.stopLoading();
            this.resourceService.reloadResources();
        });
    }

    public isDwpLoaded(): boolean {
        return !!this.resourceService.getResourceInEdit()?.resourceDefined;
    }

    possibleResourceStates(): Record<string, ResourceState> {
        const v = this.resourceService?.resourceInEdit?.getValue()?.detail?.version;
        if (v && new Version(v).existsFourthN()) {
            return {
                'WIP': ResourceState.WIP,
                'DRAFT': ResourceState.DRAFT,
            }
        }
        return ResourceState;
    }

    showStateDropDown(): boolean {
        return this.stateEditable && !!this.stateValue;
    }

    showStateLabel(): boolean {
        return !this.stateEditable && !this.permissionService.hasRole(RoleName.ROLE_QA);
    }

    showDraftBanner(): boolean {
        return !this.stateEditable && this.stateValue == ResourceState.DRAFT;
    }

}
