import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: 'confirmation-dialog.html',
})
export class ConfirmationDialogComponent {
    message = "";
    confirmButtonText = "";
    cancelButtonText = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private translateService: TranslateService) {
        this.message = this.translateService.instant('WdgHub.AreYouSure');
        this.confirmButtonText = this.translateService.instant('WdgHub.Yes');
        this.cancelButtonText = this.translateService.instant('WdgHub.Cancel');

        if (data) {
            this.message = data.message || this.message;
            if (data.buttonText) {
                this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
                this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
            }
        }
    }

    onConfirmClick(): void {
        this.dialogRef.close(true);
    }

}
