import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarType } from './snack-bar-message-type';

@Component({
    selector: 'snack-bar-message',
    templateUrl: './snack-bar-message.component.html',
    styleUrls: ['./snack-bar-message.component.scss']
})
export class SnackBarMessageComponent implements OnInit {

    color!: string;
    icon!: string;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private _snackRef: MatSnackBarRef<any>) {
        this.initIconAndColor();
    }

    ngOnInit(): void {
    }

    initIconAndColor() {
        switch (this.data.snackType as SnackBarType) {
            case SnackBarType.SUCCESS:
                this.color = 'success';
                this.icon = 'done'
                break;
            case SnackBarType.ERROR:
                this.color = 'error';
                this.icon = 'error'
                break;
            case SnackBarType.WARN:
                this.color = 'warning';
                this.icon = 'warning'
                break;
            case SnackBarType.INFO:
                this.color = 'error';
                this.icon = 'error'
                break;
        }
    }

    closeSnack() {
        this._snackRef.dismiss();
    }
}

