import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { ResourceVersionId } from 'src/app/entities/resource/ResourceVersionId';
import { Version } from 'src/app/services/resource/InstalledResource';
import { TranslateService } from "@ngx-translate/core";
import { ResourceState } from 'src/app/entities/resource/ResourceState';

@Component({
    selector: 'app-rd-version-selector',
    templateUrl: './rd-version-selector.component.html',
    styleUrls: ['./rd-version-selector.component.css']
})
export class RdVersionSelectorComponent implements OnInit {

    sortedResourceVersions!: Array<ResourceVersionId>;

    @Input()
    set resourceVersions(rv: Array<ResourceVersionId>) {
        if (rv.length > 1) {
            this.sortedResourceVersions = rv.sort((obj1: ResourceVersionId, obj2: ResourceVersionId) => {
                const v1 = new Version(obj1.version);
                const v2 = new Version(obj2.version);
                if (v1.isHigherThan(v2)) {
                    return -1;
                }
                if (v2.isHigherThan(v1)) {
                    return 1;
                }
                return 0;
            });
        }
    }

    @Input() selectedVersion: string | undefined;
    @Input() selectedId: string | undefined;

    constructor(
        private router: Router,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
    }

    selectVersion(event: MatSelectChange) {
        this.router.navigate(['home/resource-detail', event.value]);
    }

    isLatest(resourceVersionId: ResourceVersionId): boolean {
        return (<any>ResourceState)[resourceVersionId.state as keyof ResourceState] === ResourceState.APPROVED_PUBLISHED;
    }

    viewResourceOfVersion(rv: ResourceVersionId) {
        // This is a workaround so solve the problem of routing after cloning resource,
        // that remember the link of the source resource.
        // The problem was that after cloning, if you select the source version, the resource was not navigated.
        if (!this.router.navigated) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['home/resource-detail', rv.id]);
            })
        }
        else {
            this.router.navigate(['home/resource-detail', rv.id]);
        }
    }

    selectedVersionToShow(selectedVersion: string | undefined): string | undefined {
        if (selectedVersion) {
            return new Version(selectedVersion).getDisplayVersion(this.translateService);
        }
        return selectedVersion;
    }
}
