<mat-toolbar>
    <span class="textPrimary">{{resourceService.getResourceInEdit()?.name}}</span>
    <span class="item-spacer"></span>
    <span class="content-banner">
        <span>
            <app-rd-change-state></app-rd-change-state>
        </span>
        <span class="item-spacer"></span>
        <button mat-icon-button *ngIf="canDeleteResource()" (click)="deleteResource()">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </span>

</mat-toolbar>
