import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResourceSearchService {
    private _textSearch: string = '';
    public obsTextSearch = new BehaviorSubject<string>(this._textSearch);

    get textSearch(): string {
        return this._textSearch;
    }

    set textSearch(value: string) {
        this._textSearch = value;
        this.obsTextSearch.next(value);
    }

    constructor() { }

}
