import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, firstValueFrom, map, mergeMap, Observable, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ResourceDetails } from '../ResourceDetails';
import { ResourceFile } from '../ResourceFile';
import { ResourceFileTarget } from './ResourceFileTarget';
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { DestroyService } from 'src/app/services/destroy/destroy-service';

const API_RESOURCE_URL = environment.pathToService('resources');
const API_RESOURCE_URL_DETAILS = environment.pathToService('resources?projection=all&sort=name,asc');
const API_PUBLISHED_RESOURCE_URL_DETAILS = environment.pathToService('resources/search/state?state=APPROVED_PUBLISHED&projection=all&sort=name,asc');

const HEADERS = new HttpHeaders().set('Accept', 'application/hal+json');

interface ResourcesResponse {
    '_embedded': {
        resources: ResourceDetails[]
    }
}

@Injectable({
    providedIn: 'root'
})
export class ResourceService {

    private _isLoaded = false;
    private _resources: BehaviorSubject<ResourceDetails[]> = new BehaviorSubject(<ResourceDetails[]>[]);

    resourceInEdit: BehaviorSubject<ResourceDetails> = new BehaviorSubject(<ResourceDetails>{});

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private router: Router,
        private location: Location,
        private destroyService: DestroyService
    ) { }

    public async reloadResources(): Promise<boolean> {
        this._isLoaded = false;
        return firstValueFrom((await this.getResources()).pipe(map((_resources: ResourceDetails[]) => true)));
    }

    private getResourceResponse(): Observable<ResourceDetails[]> {
        return this.http.get<ResourcesResponse>(
            !this.authService.isGuestUser() ? API_RESOURCE_URL_DETAILS : API_PUBLISHED_RESOURCE_URL_DETAILS,
            { headers: HEADERS }).pipe(takeUntil(this.destroyService), map(u => u._embedded.resources));
    }

    getResources(): Promise<BehaviorSubject<ResourceDetails[]>> {
        if (this._isLoaded) {
            return Promise.resolve(this._resources);
        }
        this._isLoaded = true;
        return firstValueFrom(this.getResourceResponse().pipe(map((resources: ResourceDetails[]) => {
            this._resources.next(resources);
            return this._resources;
        }), catchError(err => {
            console.log(err);
            throw err;
        })));

    }

    getResourcePreviewUri(id: string, name: string): string {
        return API_RESOURCE_URL + '/' + id + '/files/home/' + name;
    }

    getResourceDetails(id: string): Observable<ResourceDetails> {
        return this.http.get<ResourceDetails>(API_RESOURCE_URL + '/' + id, { headers: HEADERS }).pipe(takeUntil(this.destroyService));
    }

    initResourceInEdit(id: string): Promise<boolean> {
        return firstValueFrom(this.getResourceDetails(id).pipe(map((resourceDetails: ResourceDetails) => {
            this.resourceInEdit.next(resourceDetails);
            return true;
        })));
    }

    isResourceInEdit(): boolean {
        return !this.resourceInEdit?.getValue()
            || Object.keys(this.resourceInEdit?.getValue()).length !== 0;
    }

    getResourceInEdit(): ResourceDetails | undefined {
        return this.resourceInEdit?.getValue();
    }

    clearResourceInEdit() {
        this.resourceInEdit.next(<ResourceDetails>{});
    }

    setResourceInEditByJson(jsonString: string) {
        this.resourceInEdit.next(JSON.parse(jsonString));
    }

    updateResource(id: string | undefined, body: any): Promise<boolean> {
        if (id) {
            this.reloadResources();
            return firstValueFrom(this.http.patch<ResourceDetails>(API_RESOURCE_URL + '/' + id, body, { headers: HEADERS })
                .pipe(takeUntil(this.destroyService), map((resourceDetails: ResourceDetails) => {
                    this.resourceInEdit.next(resourceDetails);
                    return true;
                })));
        }
        return Promise.reject();
    }

    createNewEmptyResource(): Promise<string> {
        let body = {}
        return firstValueFrom(this.http.post<ResourceDetails>(API_RESOURCE_URL, body, { headers: HEADERS })
            .pipe(takeUntil(this.destroyService), map((resourceDetails: ResourceDetails) => {
                this.resourceInEdit.next(resourceDetails);
                this.reloadResources();
                if (resourceDetails.id) {
                    return resourceDetails.id;
                } else {
                    throw new Error('Cannot get id of the resource');
                }
            })));

    }

    deleteResource(): Promise<boolean> {
        return firstValueFrom(this.http.delete<any>(API_RESOURCE_URL + '/' + this.resourceInEdit.getValue().id, { headers: HEADERS })
            .pipe(takeUntil(this.destroyService), mergeMap(async (_response) => {
                await this.reloadResources();
                return true;
            }
            )));
    }

    uploadResourceDWP(id: string, body: any): Promise<boolean> {
        const path = API_RESOURCE_URL + '/' + id + '/dwp';
        return firstValueFrom(this.http.post<any>(path, body, { headers: HEADERS })
            .pipe(takeUntil(this.destroyService), mergeMap(async (response) => {
                this.reloadResources();
                await this.initResourceInEdit(response.id);
                return response;
            }
            )));

    }

    cloneResourceDWP(id: string, body: any): Promise<boolean> {
        const path = API_RESOURCE_URL + '/' + id + '/clone';

        return firstValueFrom(this.http.post<any>(path, body, { headers: HEADERS })
            .pipe(takeUntil(this.destroyService), mergeMap(async (response) => {
                this.reloadResources();
                this.location.replaceState('home/resource-detail/' + response.id);
                this.router.navigated = false;
                // this.router.navigate(['home/resource-detail', response.id]);
                await this.initResourceInEdit(response.id);
                return response;
            }
            )));

    }

    getResourceDownloadDwpUri(): string {
        return API_RESOURCE_URL + '/' + this.resourceInEdit.getValue().id + '/dwp';
    }

    getAbsoluteDwpUri(id: string | undefined): string {
        if (id) {
            let uri = document.baseURI.replace(/\/$|$/, '') + API_RESOURCE_URL + '/' + id + '/dwp';
            if (!this.authService.isGuestUser()) {
                uri = uri + '?token=' + this.authService.getAccessToken();
            }
            return uri;
        }
        return "";
    }

    getGalleryUri(): string {
        return API_RESOURCE_URL + '/' + this.resourceInEdit.getValue().id + '/files/gallery'
    }

    getPreviewUri(): string {
        return API_RESOURCE_URL + '/' + this.resourceInEdit.getValue().id + '/files/home'
    }

    deleteResourceFile(filename: string, target: ResourceFileTarget): Promise<boolean> {
        const path = API_RESOURCE_URL + '/' + this.resourceInEdit.getValue().id + '/files/' + target + '/' + filename;

        return firstValueFrom(this.http.delete<ResourceDetails>(path, { headers: HEADERS })
            .pipe(takeUntil(this.destroyService), map((resourceDetails: ResourceDetails) => {
                this.resourceInEdit.next(resourceDetails);
                return true;
            })));

    }

    getResourceGalleryFiles(): ResourceFile[] {
        return this.resourceInEdit?.getValue().files?.filter(file => {
            return file.targetView === ResourceFileTarget.GALLERY
        }) ?? [];
    }

    getResourcePreviewFiles(): ResourceFile[] {
        return this.resourceInEdit?.getValue().files?.filter(file => {
            return file.targetView === ResourceFileTarget.HOME
        }) ?? [];
    }

    getResourcesByLogicalName(logicalName: string): Observable<ResourceDetails[]> {
        if (!this.authService.isGuestUser()) {
            return this.http.get<ResourcesResponse>(API_RESOURCE_URL + `/search/logicalName?projection=all&logicalName=${logicalName}&sort=detail.version,desc`, { headers: HEADERS }).pipe(takeUntil(this.destroyService), map(u => u._embedded.resources));
        } else {
            return this.http.get<ResourcesResponse>(API_RESOURCE_URL + `/search/logicalNameAndState?projection=all&state=APPROVED_PUBLISHED,APPROVED_VISIBLE&logicalName=${logicalName}&sort=detail.version,desc`, { headers: HEADERS }).pipe(takeUntil(this.destroyService), map(u => u._embedded.resources));
        }
    }

    checkIfExistAnotherWidgetAlreadyPublished(): Promise<boolean> {
        const id = this.resourceInEdit?.getValue().id;
        const logicalName = this.resourceInEdit?.getValue().detail?.logicalName;

        const path = API_RESOURCE_URL + `/search/logicalNameAndState?projection=all&state=APPROVED_PUBLISHED&logicalName=${logicalName}`;

        return firstValueFrom(this.http.get<ResourcesResponse>(path, { headers: HEADERS })
            .pipe(takeUntil(this.destroyService),
                map((response: ResourcesResponse) => response._embedded.resources.some((resource: ResourceDetails) => resource.id !== id))));

    }

}
