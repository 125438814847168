import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdEJiWH1ecHxRRGVZ');

if (environment.production) {
    console.log('Starting widget hub website in production mode...');
    enableProdMode();
} else {
    console.log('Starting widget hub website in development mode...');
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(success => console.log(`bootstrap completed`))
    .catch(err => console.error(err));
