import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UiAspectService } from 'src/app/services/ui-aspect/ui-aspect.service';
import { Resource } from '../../Resource';
import { CategoryPathService } from '../../services/categoryPath/category-path.service';
import { ResourceService } from '../../services/resource.service';
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroy/destroy-service';

@Component({
  selector: 'app-resource-catalogue',
  templateUrl: './resource-catalogue.component.html',
  styleUrls: ['./resource-catalogue.component.scss']
})
export class ResourceCatalogueComponent implements OnInit, AfterViewInit {

    @ViewChild('sidenav', {static: true}) sidenav!:MatSidenav;

    /*
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        if (event.target.innerWidth < 600) {
            this.sidenav.close();
        } else {
            this.sidenav.open();
        }
    }
    */

    resources: Resource[] | undefined;
    constructor(private resourceService: ResourceService,
                private categoryPathService: CategoryPathService,
                private uiAspectService: UiAspectService,
                private destroyService: DestroyService) {

    }

    ngAfterViewInit(): void {
        this.categoryPathService.initSideNav(this.sidenav);
    }

    ngOnInit(): void {
        this.resourceService.getResources().then(observableResources => {
            observableResources.pipe(takeUntil(this.destroyService)).subscribe({
                next: (resources: Resource[]) => {
                    this.setResources(resources);
                },
                error: (e) => console.log(e)
            });
        });
    }

    setResources(resources: Resource[]) {
        this.resources = resources;
    }

    public toggleSidenav() {
        this.sidenav?.toggle();
    }

    getFixedTopGap(): number {
        return this.uiAspectService.isPrimaryHeaderVisible() ? 91 : 43;
    }

}
