import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { TranslateService } from "@ngx-translate/core";
import { User } from '../../entities/user/User';
import { Observable, takeUntil } from "rxjs";
import { DestroyService } from 'src/app/services/destroy/destroy-service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    host: {
        class:'card-centered'
    }
})
export class LoginComponent implements OnInit {

    form: any = {};
    isLoginFailed = false;
    errorMessage = '';
    roles: string[] = [];
    token?: string | null;
    loginReady = false;
    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private destroyService: DestroyService) {

        if (!this.isLoggedIn) {
            this.route.queryParamMap.pipe(takeUntil(this.destroyService)).subscribe(params => {
                this.token = params.get('token');
                this.onToken();
            });
        }
    }

    ngOnInit(): void {
        if (this.isLoggedIn) {
            this.roles = this.authService.getUser().roles;
            this.router.navigate(['home']);
        }
    }

    onSubmit(): void {
        this.isLoginFailed = false;
        let loginResponse = this.authService.login(this.form);
        this.loginConsumeResponse(loginResponse);
    }

    onToken(): void {
        this.isLoginFailed = false;
        if (this.token) {
            let loginResponse = this.authService.loginToken(this.token);
            this.loginConsumeResponse(loginResponse);
        } else {
            this.loginReady = true;
        }
    }

    loginConsumeResponse(loginResponse: Observable<any>): void {
       loginResponse.subscribe(
            (user: User) => {
                if (!user.accessToken) {
                    throw new Error('Unauthorized user');
                }
                this.authService.storeUser(user)

                this.isLoginFailed = false;
                this.roles = this.authService.getUser().roles;
                this.reloadPage();
            },
            err => {
                this.errorMessage = err.error?.message
                    ?? this.translateService.instant('WdgHub.GenericError');
                this.isLoginFailed = true;
                this.loginReady = true;
            }
        );
    }

    reloadPage(): void {
        this.loginReady = false;
        window.location.reload();
    }

    get isLoggedIn(): boolean {
        return this.authService.isLoggedIn;
    }

}
