import { Component, OnInit } from '@angular/core';
import { ResourceDetails } from '../../ResourceDetails';
import { CategoryPathService } from '../../services/categoryPath/category-path.service';
import { ResourceService } from '../../services/resource.service';
import { combineLatest, Observable, of, takeUntil } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ResourceFilterPipe } from '../../pipe/resourceFilter/resource-filter.pipe';
import { ResourceSearchService } from '../../services/resource-search.service';
import { AuthService } from '../../../../security/services/auth/auth.service';
import { FilterElement } from '../../pipe/resourceFilter/FilterElement';
import { RoleName } from "../../../../security/entities/role/RoleName";
import { PermissionService } from "../../../../security/services/permission/permission.service";
import { ResourceInstallerHandlerService } from "../../../../services/resource/resource-installer-handler.service";
import { UpgradeCompatibilityOutcome } from "../../../../services/resource/InstalledResource";
import { ResourceKey } from "../../../../services/resource/ResourceKey";
import { CategoryPath } from "../../services/categoryPath/CategoryPath";
import { DestroyService } from 'src/app/services/destroy/destroy-service';
import { ICategoryPathNode } from '../../services/categoryPath/iCategoryPathNode';

@Component({
    selector: 'app-resource-cards',
    templateUrl: './resource-cards.component.html',
    styleUrls: ['./resource-cards.component.css']
})
export class ResourceCardsComponent implements OnInit {

    obsRes: Observable<ResourceDetails[]> = of([]);
    resources = new Array<ResourceDetails>();
    constructor(
        private resourceService: ResourceService,
        private activeRoute: ActivatedRoute,
        public categoryPathService: CategoryPathService,
        private resourceFilterPipe: ResourceFilterPipe,
        private resourceSearchService: ResourceSearchService,
        private authService: AuthService,
        private permissionService: PermissionService,
        private resourceInstallerHandlerService: ResourceInstallerHandlerService,
        private destroyService: DestroyService
    ) {
    }

    ngOnInit(): void {
        combineLatest([
            this.activeRoute.paramMap,
            this.resourceSearchService.obsTextSearch,
            this.resourceInstallerHandlerService.upgradeCompatibilityOutcomeObservable,
            this.categoryPathService.categoryPathTreeObservable])
            .pipe(takeUntil(this.destroyService))
            .subscribe((obs: [ParamMap, string, Map<string, UpgradeCompatibilityOutcome>, ICategoryPathNode[]]) => {
                this.categoryPathService.listViewEnabled = false;
                const category = obs[0].get(CategoryPathService.CATEGORY_PATH);
                const textSearch = obs[1];
                this.categoryPathService.alignSelectedPath(category);
                const filter = FilterElement.buildResourceFilter()
                    .addCategoryPath(this.categoryPathService.selectedPath)
                    .addTextSearch(textSearch)
                    .addShowSharedLib(this.isToShowSharedLibs());
                this.resourceService.getResources().then(observableResources => {
                    this.resourceFilterPipe
                        .transform(observableResources, filter)
                        .pipe(takeUntil(this.destroyService))
                        .subscribe({
                            next: (resources: ResourceDetails[]) => {
                                this.resources = resources;
                                if (this.categoryPathService.selectedPath?.categoryPath == CategoryPath.UPGRADE_AVAILABLE) {
                                    this.resources = this.resources
                                        .filter(resource => this.resourceInstallerHandlerService
                                            .getUpgradeCompatibility(resource).updateAvailable);
                                }
                            },
                            error: (e) => {
                                console.error(e);
                            }
                        });
                });
            });

    }

    isToShowSharedLibs(): boolean {
        return !this.authService.isGuestUser() && !this.permissionService.hasRole(RoleName.ROLE_QA);
    }

}
