<div class="detail-container" *ngIf="resourceService.getResourceInEdit()?.detail">
    <app-rd-header ></app-rd-header>
    <div class="inner-container">
        <div class="widget-details">
            <app-rd-info class="widget-info"></app-rd-info>

            <div class="widget-main">
                <app-rd-gallery></app-rd-gallery>
                <app-rd-description ></app-rd-description>
            </div>
        </div>
    </div>
</div>
