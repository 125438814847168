import {IFilterElement} from './iFilterElement';
import {ICategoryPathNode} from '../../services/categoryPath/iCategoryPathNode';

export class FilterElement implements IFilterElement {
    private _categoryPath: ICategoryPathNode | undefined;
    private _showSharedLib: boolean | undefined;
    private _textSearch: string | undefined;

    constructor() { }

    get categoryPath(): ICategoryPathNode | undefined {
        return this._categoryPath;
    }

    addCategoryPath(categoryPath: ICategoryPathNode | undefined): IFilterElement {
        this._categoryPath = categoryPath;
        return this;
    }

    get showSharedLib(): boolean | undefined {
        return this._showSharedLib;
    }

    addShowSharedLib(showSharedLib: boolean | undefined): IFilterElement {
        this._showSharedLib = showSharedLib;
        return this;
    }

    get textSearch(): string | undefined {
        return this._textSearch;
    }

    addTextSearch(textSearch: string | undefined): IFilterElement {
        this._textSearch = textSearch;
        return this;
    }

    static buildResourceFilter(): IFilterElement {
        return new FilterElement();
    }

}
