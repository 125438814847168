import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TokenStorageService } from '../token/token-storage.service';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take } from 'rxjs';
import { AuthService } from './auth.service';

const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end
// const TOKEN_HEADER_KEY = 'x-access-token';   // for Node.js Express back-end

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private tokenStorageService: TokenStorageService, private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = this.prepareHeader(req);
        return next.handle(authReq).pipe(catchError(error => this.handleError(error, authReq, next)));
    }

    public prepareHeader(req: HttpRequest<any>): HttpRequest<any> {
        let authReq = req;
        const token = this.tokenStorageService.getToken();
        if (token != null) {
            authReq = this.addTokenHeader(req, token);
        }
        return authReq;
    }

    public handleError(error: any, authReq: HttpRequest<any>, next: HttpHandler) {
        {
            if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/signin') && error.status === 401) {
                return this.handle401Error(authReq, next);
            }
            throw error;
        }
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const token = this.tokenStorageService.getRefreshToken();

            if (token)
                return this.authService.refreshToken(token).pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;

                        this.tokenStorageService.saveToken(token.accessToken);
                        const user = this.tokenStorageService.getUser();
                        if (user.id) {
                            user.accessToken = token;
                            this.tokenStorageService.saveUser(user);
                        }
                        this.refreshTokenSubject.next(token.accessToken);

                        return next.handle(this.addTokenHeader(request, token.accessToken));
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        this.authService.loggedOut();
                        throw err;
                    })
                );
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        /* for Spring Boot back-end */
        return request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)});

        /* for Node.js Express back-end */
        // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
    }
}

export const authInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];
