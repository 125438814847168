<div class="setting-list">
    <div class="setting-action-buttons">
        <button mat-raised-button (click)="accordion.openAll()">{{'WdgHub.SettingsExpandAll' | translate}}</button>
        <button mat-raised-button (click)="accordion.closeAll()" class="neighbour-button">{{'WdgHub.SettingsCollapseAll' | translate}}</button>
    </div>
    <mat-accordion class="setting-headers-align" multi>

        <!-- SERVICE VERSION SETTING -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'WdgHub.ServiceVersionTitle' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                    {{'WdgHub.ServiceVersionDescription' | translate}}
                    <mat-icon>info</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p>version: {{serviceBuild.version}}</p>
            <p>build: {{serviceBuild.build}}</p>
            <p>revision: {{serviceBuild.rev}}</p>
        </mat-expansion-panel>

        <!-- UI VERSION SETTING -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'WdgHub.UIVersionTitle' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                    {{'WdgHub.UIVersionDescription' | translate}}
                    <mat-icon>info</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p>version: {{uiBuild.version}}</p>
            <p>build: {{uiBuild.build}}</p>
            <p>revision: {{uiBuild.rev}}</p>
        </mat-expansion-panel>

        <!-- THEME SETTING -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'WdgHub.SystemThemeTitle' | translate}}
                    <button *ngIf="themeChanged" mat-icon-button (click)="saveTheme($event)">
                        <mat-icon>save</mat-icon>
                    </button>
                </mat-panel-title>
                <mat-panel-description>
                    {{(isThemeEditable() ? 'WdgHub.SystemThemeDescriptionEdit' : 'WdgHub.SystemThemeDescriptionView') | translate}}
                    <mat-icon>palette</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field class="setting-full-width" appearance="fill">
                <mat-label>{{(isThemeEditable() ? 'WdgHub.EditThemeSetting' : 'WdgHub.ViewThemeSetting') | translate}}</mat-label>
                <textarea
                    [readonly]="!isThemeEditable()"
                    class="setting-textarea"
                    matInput
                    [placeholder]="'WdgHub.PutHereTheme' | translate"
                    rows="30"
                    [(ngModel)]='themeText'></textarea>
            </mat-form-field>
            <button
                mat-raised-button
                color="accent"
                (click)="saveTheme($event)"
                [disabled] ="!themeChanged">{{'WdgHub.Save' | translate}}
            </button>
            <button
                mat-raised-button
                color="accent"
                class="neighbour-button"
                (click)="cancelSaveTheme()"
                [disabled] ="!themeChanged">{{'WdgHub.Cancel' | translate}}
            </button>
        </mat-expansion-panel>
    </mat-accordion>
</div>
