import {Injectable} from '@angular/core';
import {ResourceState} from '../ResourceState';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ResourceStateService {

    constructor(private translateService: TranslateService) { }

    getStateClass(state?: string): string {
        if (!state) {
            return '';
        }
        const stateEnum = (<any>ResourceState)[state as keyof ResourceState];
        switch (stateEnum) {
            case ResourceState.APPROVED_PUBLISHED:
                return '';
            case ResourceState.APPROVED_VISIBLE:
            case ResourceState.APPROVED_HIDE:
                return 'textAccent';
        }
        return 'textWarn';
    }

    getStateEnum(state: string): ResourceState {
        return (<any>ResourceState)[state as keyof ResourceState];
    }

    getStateDescription(state?: string): string {
        if (state) {
            return this.translateService.instant(this.getStateEnum(state));
        }
        return '';
    }

    isStatePublished(state?: string): boolean {
        if (state) {
            return this.getStateEnum(state) === ResourceState.APPROVED_PUBLISHED
        }
        return false;
    }

    isStateWip(state?: string): boolean {
        if (state) {
            return this.getStateEnum(state) === ResourceState.WIP
        }
        return false;
    }

    isStateDraft(state?: string): boolean {
        if (state) {
            return this.getStateEnum(state) === ResourceState.DRAFT
        }
        return false;
    }

}
