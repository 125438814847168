import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingStatusService {

    isLoading = false;

    startLoading() {
        this.isLoading = true;
    }

    stopLoading() {
        this.isLoading = false;
    }

    constructor() { }
}
