import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ResourceFile } from '../../../ResourceFile';
import {TokenStorageService} from '../../../../../security/services/token/token-storage.service';

@Component({
    selector: 'app-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit {

    path: any = {};
    previewFileUri!: string;

    @Input() files!: ResourceFile[];
    @Input() requestHeaders: any = {};

    @Input()
    set fileManagerUri(uri: string) {
        this.path.saveUrl = uri;
        this.previewFileUri = uri;
    }

    @Output() fileUploaded: EventEmitter<any> = new EventEmitter();
    @Output() fileDelete: EventEmitter<any> = new EventEmitter();

    constructor(
        private tokenStorageService: TokenStorageService
    ) { }

    ngOnInit(): void {
    }

    prepareUploadRequest(evt: any): void {
        if (this.requestHeaders) {
            console.log(this.requestHeaders);
            Object.keys(this.requestHeaders).forEach(h => {
                console.log("setting header: " + h + ":" + this.requestHeaders[h]);
                evt.currentRequest.setRequestHeader(h, this.requestHeaders[h]);
            });
            const token = this.tokenStorageService.getToken();
            if (token != null) {
                evt.currentRequest.setRequestHeader("Authorization", 'Bearer ' + token);
            }
        }
    }

    uploadSuccess(evt: any): void {
        console.log("uploadSuccess");
        this.fileUploaded.emit(evt);
    }

    uploadFailure(evt: any): void {
        console.log("uploadFailure");
    }

    deleteGalleryFile(name: string) {
        console.log("deleteGalleryFile:" + name);
        this.fileDelete.emit(name);
    }

    openGalleryFile(name: string) {
        window.open(this.previewFileUri + '/' + name);
    }

}
