import { Injectable } from '@angular/core';
import { DacCommunicationLayerService } from '../../dacAPIs/dac-communication-layer.service';

@Injectable({
    providedIn: 'root'
})
export class UiAspectService {

    constructor(private dacCommunicationLayerService: DacCommunicationLayerService) { }

    isPrimaryHeaderVisible() {
        return !this.dacCommunicationLayerService.isEmbeddedOnDac();
    }
}
