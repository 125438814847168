import { Injectable } from '@angular/core';
import {ThemeSetting} from './theme-setting';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    defaultThemeKeys = ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900", "A100", "A200", "A400", "A700"];
    defaultTheme = {
        "primary": "rgba(196, 211, 129, 1)",
        "accent": "rgba(255, 138, 96, 1)",
        "warn": "rgba(255, 87, 34, 1)",
        "background": "rgba(250, 250, 250, 1)",
        "primaryColor": "rgb(196, 211, 129)",
        "secondaryColor": "#ff8a60",
        "primaryColorPalette": {
            "50": "#ffffff",
            "100": "#ffffff",
            "200": "#f2f5e3",
            "300": "#d9e3ae",
            "400": "#cfdb98",
            "500": "#c4d381",
            "600": "#b9cb6a",
            "700": "#afc354",
            "800": "#a2b840",
            "900": "#8ea138",
            "A100": "#f3fad5",
            "A200": "#e9f5b0",
            "A400": "#d3ec63",
            "A700": "#c5e631",
            "contrastDefaultColor": "dark",
            "contrastDarkColors": [
                "50",
                "100",
                "200",
                "300",
                "400",
                "A100",
                "A200"
            ]
        },
        "accentColorPalette": {
            "50": "#ffffff",
            "100": "#ffffff",
            "200": "#ffece5",
            "300": "#ffb79d",
            "400": "#ffa17f",
            "500": "#ff8a60",
            "600": "#ff7341",
            "700": "#ff5d23",
            "800": "#ff4604",
            "900": "#e53c00",
            "A100": "#ffe4da",
            "A200": "#ffc6b2",
            "A400": "#ff865b",
            "A700": "#ff5d23",
            "contrastDefaultColor": "dark",
            "contrastDarkColors": [
                "50",
                "100",
                "200",
                "300",
                "400",
                "A100",
                "A200"
            ]
        }
    };

    private themeSetting: ThemeSetting | undefined;

    constructor() { }

    setThemeVariables(prefix: string, themeSetting?: ThemeSetting) {
        this.themeSetting = themeSetting ?? this.getDefaultThemeSetting();
        let theme = this.themeSetting.value.theme;
        let themeKeys = this.themeSetting.value.themeKeys;
        document.documentElement.style.setProperty('--' + prefix + '-primary-color', theme.primary);
        document.documentElement.style.setProperty('--' + prefix + '-accent-color', theme.accent);

        themeKeys.forEach((colorKey: string) => {
            document.documentElement.style.setProperty('--' + prefix + '-primary-color-' + colorKey, theme.primaryColorPalette[colorKey]);
            document.documentElement.style.setProperty('--' + prefix + '-contrast-primary-color-' + colorKey, this.invertColor(theme.primaryColorPalette[colorKey]));

            document.documentElement.style.setProperty('--' + prefix + '-accent-color-' + colorKey, theme.accentColorPalette[colorKey]);
            document.documentElement.style.setProperty('--' + prefix + '-contrast-accent-color-' + colorKey, this.invertColor(theme.accentColorPalette[colorKey]));
        })
    }

    private getDefaultThemeSetting(): ThemeSetting {
        return {
            name: 'THEME',
            value: {
                theme: this.defaultTheme,
                themeKeys: this.defaultThemeKeys
            }
        };
    }

    getThemeSetting(): ThemeSetting {
        return this.themeSetting ?? this.getDefaultThemeSetting();
    }

    invertColor(color: any) {
        const defaultColor = 'rgb(80,80,80)';
        if (color === '') {
            return defaultColor;
        } else {
            let ctx = document.createElement('canvas').getContext('2d');
            if (ctx) {
                ctx.fillStyle = color;
                let colorTrim = ctx.fillStyle;
                colorTrim = (colorTrim as string).replace('#', '');
                let r = parseInt(colorTrim.substring(0, 2), 16);
                let g = parseInt(colorTrim.substring(2, 4), 16);
                let b = parseInt(colorTrim.substring(4, 6), 16);
                return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? defaultColor : 'rgb(255,255,255)';
            }
            return defaultColor;
        }
    }
}
