<div class="profile-container" *ngIf="currentUser; else loggedOut">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="avatar"></div>
            <mat-card-title><strong>{{ currentUser.username }}</strong></mat-card-title>
            <mat-card-subtitle>{{ currentUser.email }}</mat-card-subtitle>
            <span class="item-spacer"></span>
            <div class="form-group">
                <button mat-flat-button color="primary" class="change-password-button"
                    (click)="redirectToChangePassword()">
                    {{'WdgHub.profile.ChangePassword' | translate}}
                </button>
            </div>
        </mat-card-header>
        <mat-card-content>
            <p>
                <strong>Name:</strong>
                {{ currentUser.name }}
            </p>
            <p>
                <strong>Surname:</strong>
                {{ currentUser.surname }}
            </p>
            <p>
                <strong>Username:</strong>
                {{ currentUser.username }}
            </p>
            <p>
                <strong>Email:</strong>
                {{ currentUser.email }}
            </p>
            <span *ngIf="showRoles">
                <strong>Roles:</strong>
                <ul>
                    <li *ngFor="let role of currentUser.roles">
                        {{ role }}
                    </li>
                </ul>
            </span>
        </mat-card-content>
        <mat-card-actions>
            <div class="profile-form-container">
                <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>{{'WdgHub.profile.expireTS' | translate}}:</mat-label>
                            <input matInput [ngxMatDatetimePicker]="dtpValue" placeholder="Choose a date"
                                [min]="minDate" [max]="maxDate" name="dtpValue" [(ngModel)]="form.dtpValue">
                            <mat-datepicker-toggle matSuffix [for]="$any(dtpValue)"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #dtpValue [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                                [stepMinute]="1" [stepSecond]="1" [enableMeridian]="false" [disableMinute]="false"
                                [hideTime]="false">
                            </ngx-mat-datetime-picker>
                        </mat-form-field>
                        <span class="item-spacer"></span>
                        <button mat-flat-button color="primary" class="btn btn-primary btn-block" type="submit">
                            {{'WdgHub.profile.genToken' | translate}}
                        </button>
                    </div>
                </form>
                <div class="form-group">
                    <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isGenTokenFailed">
                        {{'WdgHub.profile.GenTokenFailed' | translate}}: {{ errorMessage }}
                    </div>
                </div>

                <div *ngIf="accessToken">
                    <div class="form-group">
                        <mat-form-field class="access-token-generated">
                            <mat-label for="access-token-generated">Access token generated:</mat-label>
                            <input matInput id="access-token-generated" readonly [(ngModel)]="accessToken" />
                            <mat-icon matSuffix color="primary" [cdkCopyToClipboard]="accessToken"
                                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">content_copy</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="access-token-generated">
                            <mat-label for="access-token-generated">Complete link to access:</mat-label>
                            <input matInput id="access-token-generated" readonly [(ngModel)]="completeAccessLink" />
                            <mat-icon matSuffix color="primary" [cdkCopyToClipboard]="completeAccessLink"
                                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">content_copy</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>



<ng-template #loggedOut>
    Please login.
</ng-template>
