<div>
    <h2 class="title textPrimary" *ngIf="isEditable()">
        <span class="item-spacer"></span>
        <ng-container>
            <button [disabled]="!isDwpLoaded()" mat-icon-button *ngIf="!isInEdit" (click)="isDwpLoaded() && goInEdit()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button *ngIf="isInEdit" (click)="isInEdit = false">
                <mat-icon>done_outline</mat-icon>
            </button>
        </ng-container>
    </h2>
    <div *ngIf="!isInEdit || authService.isGuestUser()">
        <dcy-slideshow *ngIf="isGalleryReady" id="dcy-slideshow" [theme]="theme" [fileInfo]="fileInfo" [context]="config" [authInterceptor]="this.authInterceptor"></dcy-slideshow>
    </div>
    <div *ngIf="isInEdit && !authService.isGuestUser()" class="file-manager-container">
        <app-file-manager [fileManagerUri]="galleryUri" [files]="galleryFiles" [requestHeaders]="requestHeaders"
            (fileUploaded)="galleryFileUploaded($event)" (fileDelete)="galleryFileDelete($event)"></app-file-manager>

        <br/><br/><br/>

        <h2><span>Preview</span></h2>
        <app-file-manager [fileManagerUri]="previewUri" [files]="previewFiles" [requestHeaders]="requestHeaders"
            (fileUploaded)="previewFileUploaded($event)" (fileDelete)="previewFileDelete($event)"></app-file-manager>
    </div>
</div>
