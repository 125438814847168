import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { SystemSettingService } from '../system/service/system-setting.service';
import { ThemeSetting } from '../../../services/theme/theme-setting';
import { ThemeService } from '../../../services/theme/theme.service';
import { ServiceBuildSetting } from '../../../services/build/service-build-setting';
import { UiBuildSetting } from '../../../services/build/ui-build-setting';
import { Setting } from '../system/model/Setting';
import { PermissionService } from "../../../security/services/permission/permission.service";
import { RoleName } from "../../../security/entities/role/RoleName";
import { AuthService } from "../../../security/services/auth/auth.service";
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroy/destroy-service';

@Component({
    selector: 'app-setting',
    templateUrl: './setting-list.component.html',
    styleUrls: ['./setting-list.component.css']
})
export class SettingListComponent implements OnInit, OnDestroy {

    @ViewChild(MatAccordion) accordion!: MatAccordion;

    private themeSetting: ThemeSetting | undefined;
    private resetThemeSetting: ThemeSetting | undefined;
    public themeChanged = false;

    private serviceBuildSetting: ServiceBuildSetting | undefined;
    private uiBuildSetting: UiBuildSetting | undefined;
    constructor(
        public authService: AuthService,
        public permissionService: PermissionService,
        private systemSettingService: SystemSettingService,
        private themeService: ThemeService,
        private destroyService: DestroyService
    ) {
        this.themeSetting = themeService.getThemeSetting();
        this.resetThemeSetting = Object.assign({}, this.themeSetting);
    }

    ngOnInit(): void {
        this.systemSettingService.getUiServiceSettingsResponse().subscribe({
            next: uiVersion => {
                this.uiBuildSetting = {
                    name: 'UI_BUILD',
                    value: uiVersion,
                    description: 'Shows the version of hub service'
                }
            },
            error: err => {
                console.error(err);
            }
        });
        this.systemSettingService.isSectionOpen = true;
        this.systemSettingService.getSystemSettingsObsCache().pipe(takeUntil(this.destroyService)).subscribe({
            next: settings => {
                this.applySettings(settings);
            }
        });
    }

    ngOnDestroy() {
        this.systemSettingService.isSectionOpen = false;
    }

    applySettings(settings: Setting[]) {
        this.applyThemeSetting(settings);
        this.applyServiceBuildSetting(settings);
    }

    private applyThemeSetting(settings: Setting[]) {
        this.themeSetting = this.systemSettingService.filterThemeSettings(settings);
        this.themeService.setThemeVariables('hub', this.themeSetting);
    }

    get themeText() {
        if (this.themeSetting) {
            return JSON.stringify(this.themeSetting.value, undefined, 4);
        }
        return "";
    }

    set themeText(value) {
        if (this.themeSetting) {
            this.themeChanged = true;
            try {
                this.themeSetting.value = JSON.parse(value);
            } catch (e) {
                console.error(e);
            }
        }
    }

    async saveTheme(e: Event) {
        e.stopPropagation();
        if (this.themeSetting) {
            try {
                await this.systemSettingService.patchSystemSetting(this.themeSetting);
                this.resetThemeSetting = Object.assign({}, this.themeSetting);
                this.themeChanged = false;
            } catch (e) {
                this.cancelSaveTheme();
            }
        }
    }

    cancelSaveTheme() {
        this.themeSetting = Object.assign({}, this.resetThemeSetting);
        this.themeChanged = false;
    }

    private applyServiceBuildSetting(settings: Setting[]) {
        this.serviceBuildSetting = this.systemSettingService.filterServiceBuildSettings(settings);
    }

    get serviceBuild() {
        if (this.serviceBuildSetting) {
            return this.serviceBuildSetting.value;
        }
        return "";
    }

    get uiBuild() {
        if (this.uiBuildSetting) {
            return this.uiBuildSetting.value;
        }
        return "";
    }

    isThemeEditable(): boolean {
        return this.authService.isLoggedIn && this.permissionService.hasAnyRole([RoleName.ROLE_ADMIN]);
    }

}
