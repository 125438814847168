import { Injectable, OnDestroy } from '@angular/core';
import { ResourceInstallerHandlerService } from '../services/resource/resource-installer-handler.service';
import { EnvironmentBootstrapperService } from '../startup/environment-bootstrapper.service';
import { DAC_MESSAGING_IDS } from './dac-messages-ids';
import { SmartPromise } from '@decisyon/common-utils-lib';
import { ResourceDetails } from "../entities/resource/ResourceDetails";
import { DacMessage } from "./DacMessage";
import { ResourceDefinition } from "../services/resource/InstalledResource";
import { RejectEvent } from "./RejectEvent";

@Injectable({
    providedIn: 'root'
})

export class DacCommunicationLayerService implements OnDestroy {

    private dacWinRef: any = undefined;
    private dacAccessAllowed: boolean = true;
    private injectedEnvService!: EnvironmentBootstrapperService;
    private injectedResourceInstallerHandler!: ResourceInstallerHandlerService;
    private singleMessageSemaphore = new SmartPromise<DacMessage>();
    private communicationSemaphore: SmartPromise<boolean>;

    constructor(public resourceInstallerHandler: ResourceInstallerHandlerService) {
        this.communicationSemaphore = this.buildCommunicationSemaphore(true);
    }

    private buildCommunicationSemaphore(startGreen: boolean): SmartPromise<boolean> {
        const semaphore = new SmartPromise<boolean>();
        semaphore.getPromise().then(onResolved => {
            console.debug('Ready to send new message');
        }).catch(onRejection => {
            // rarely case, not normal flux.
            console.debug('Ready to send new message after communication error event');
        });
        if (startGreen) {
            semaphore.resolve(true);
        }
        return semaphore;
    }

    ngOnDestroy(): void {
        window.removeEventListener("message", this.processReceivedMessage, false);
    }

    public bindDacReference(injectedEnvService: EnvironmentBootstrapperService, injectedResourceInstallerHandler: ResourceInstallerHandlerService): void {
        try {
            this.injectedEnvService = injectedEnvService;
            this.injectedResourceInstallerHandler = injectedResourceInstallerHandler;
            this.dacWinRef = this.isRequestOpenOnDac() ? this.getWindowOpener() : null;
            this.setDacAccessAllowed(this.isEmbeddedOnDac());

            if (this.isEmbeddedOnDac()) {
                this.addFrameListener();
            }
        } catch (e) {
            console.error("Error detecting dac frame", e);
            this.dacWinRef = null;
        } finally {
            console.debug('Dac frame detected:', this.isEmbeddedOnDac());
            console.debug('Dac access allowed:', this.isDacAccessAllowed());
        }
    }

    private isRequestOpenOnDac(): boolean {
        return (new URL(window.location.href)).searchParams.has('dac_embed');
    }

    private getWindowOpener(): Window {
        return (window.opener ? window.opener : (window.parent != window) ? window.parent : null);
    }

    private addFrameListener(): boolean {
        try {
            if ('function' != typeof this.dacWinRef.postMessage)
                throw new Error('PostMessage not defined in the parent iframe');


            if (window.addEventListener) {
                window.addEventListener("message", this.processReceivedMessage.bind(this), false);
            } else {
                (<any>window).attachEvent("onmessage", this.processReceivedMessage.bind(this));
            }
            return true;
        } catch (e) {
            console.error("Error adding dac listeners", e);
            return false;
        }

    }

    /**
     * @returns if a parent iframe exist (but is not sure that communication is allowed)
     */
    public isEmbeddedOnDac(): boolean {
        return !!this.dacWinRef;
    }

    /**
     * @returns if dac replies to messages in the bootstrap phase (otherwise a message or some operation can't be performed)
     */
    public isDacAccessAllowed(): boolean {
        return this.dacAccessAllowed;
    }

    public setDacAccessAllowed(allowed: boolean): void {
        this.dacAccessAllowed = allowed;
    }

    /**
     * Send message request and wait for async response from dac (response content actually is delegated from delegated services)
     * @param message_id
     * @param message_data
     * @param specificTimeoutMillis
     * @returns
     */
    public async sendMessageAndWait(message_id: DAC_MESSAGING_IDS, message_data?: any,
                                    specificTimeoutMillis?: number): Promise<any> {
        let message: any;
        if (message_data == undefined) {
            message = {
                'method': message_id
            };
        } else {
            message = message_data;
            message['method'] = message_id;
        }

        const startId = message_id + (message.logicalName ? '_' + message.logicalName : '');

        console.debug('Sending message to dac:' + startId, message);

        try {
            const startTime = new Date().getTime();

            console.debug('Waiting on communication semaphore for message: ' + startId);
            //wait for previous elaborations (can be managed multiple operations, but are not tested on dac side)
            await this.communicationSemaphore.getPromise();
            this.communicationSemaphore = this.buildCommunicationSemaphore(false);
            console.debug('Communication semaphore green for message: ' + startId);

            const timeoutMillis = specificTimeoutMillis ? specificTimeoutMillis : 90000;
            const currentMessageTimeoutID = window.setTimeout(
                () => {
                    const errMsg = 'Timeout of ' + timeoutMillis + ' occurred waiting for dac message';
                    this.singleMessageSemaphore.reject({ errMsg } as RejectEvent);
                },
                timeoutMillis);

            console.debug('Set timeout ' + currentMessageTimeoutID);
            this.singleMessageSemaphore = new SmartPromise<DacMessage>();
            this.singleMessageSemaphore.getPromise().then(message => {
                console.debug('Previous message ' + startId + ' answered in ' + (new Date().getTime() - startTime));
                console.debug('Clear timeout ' + currentMessageTimeoutID);
                window.clearTimeout(currentMessageTimeoutID);
                this.communicationSemaphore.resolve(true);
                this.resolveDacMessage(message);
            }).catch((reject: RejectEvent) => {
                console.error('Rejected operation message: ' + reject.errMsg);
                console.error('Rejected operation event: ', reject.event);
                console.debug('Clear timeout ' + currentMessageTimeoutID);
                window.clearTimeout(currentMessageTimeoutID);
                this.communicationSemaphore.resolve(true);
                throw new Error(reject.errMsg);
            });

            console.debug('Post message to DAC and waiting for processing message ' + startId);
            this.dacWinRef.postMessage({message: JSON.stringify(message)}, '*');

            const startMillis = new Date().getTime();
            const promise = await this.singleMessageSemaphore.getPromise();
            const endId = message_id + (message.logicalName ? '_' + message.logicalName : '');
            if (startId != endId) {
                console.error(`Error: sendMessageAndWait startId: ${startId} differs from endId: ${endId}`);
            }
            console.debug('Message ' + startId + ' answered in ' + (new Date().getTime() - startMillis));
            return promise;
        } catch (e: any) {
            console.error('Error sending message', e);
            this.rejectDacMessage(e.message, message.method);
            throw e;
        }
    }

    private processReceivedMessage(event: any): void {
        // skipping unwanted messages that can be received also from other actors of communication (eg. browser extensions)
        if (!event.data || (!event.data.message && !event.data.result)) {
            // console.debug('Skipped event message received and will be ignored');
            return undefined;
        }
        try {
            const receivedMessage: DacMessage = event.data.message ? JSON.parse(event.data.message) : JSON.parse(event.data.result);
            if (!receivedMessage || !receivedMessage.successData) {
                let errMsg = '';
                switch (receivedMessage.method) {
                    case DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET:
                    case DAC_MESSAGING_IDS.METHOD_UPGRADE_WIDGET:
                        errMsg = receivedMessage?.rejectionData?.data?.errorMsg
                            ? 'Installation failed: ' + receivedMessage.rejectionData.data.errorMsg
                            : 'Installation of widget failed for unknown reasons. Message received:' + JSON.stringify(receivedMessage);
                        break;
                    default:
                        errMsg = 'Error received from DAC with unknown reasons. Message received:' + JSON.stringify(receivedMessage);
                }
                this.singleMessageSemaphore.reject({ errMsg, event } as RejectEvent);
            } else {
                switch (receivedMessage.method) {
                    case DAC_MESSAGING_IDS.METHOD_GET_ENV_INFO:
                    case DAC_MESSAGING_IDS.METHOD_GET_PUB_WIDGETS:
                    case DAC_MESSAGING_IDS.METHOD_GET_PUB_WIDGET:
                    case DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET:
                    case DAC_MESSAGING_IDS.METHOD_UPGRADE_WIDGET:
                        this.singleMessageSemaphore.resolve(receivedMessage);
                        break;
                    default:
                        console.error('Unrecognized received message from DAC:', event);
                        const errMsg = 'Nothing to do: unrecognized received message'
                        this.singleMessageSemaphore.reject({ errMsg, event } as RejectEvent);
                        break;
                }
            }
        } catch (ex) {
            console.error('Error parsing received message from DAC', ex);
            const errMsg = 'Nothing to do: error parsing received message';
            this.singleMessageSemaphore.reject({ errMsg, event } as RejectEvent);
        }
    };


    private resolveDacMessage(message: DacMessage): void {
        console.debug('resolveDacMessage', message);
        switch (message.method) {
            case DAC_MESSAGING_IDS.METHOD_GET_ENV_INFO:
                console.debug('Detected dac information:', message.successData);
                this.injectedEnvService.dacEnvironmentInfo = message.successData;
                break;
            case DAC_MESSAGING_IDS.METHOD_GET_PUB_WIDGETS:
                console.debug(message, message.successData);
                this.resourceInstallerHandler.setInstalledItems(message.successData);
                break;
            case DAC_MESSAGING_IDS.METHOD_GET_PUB_WIDGET:
                console.debug(message, message.successData);
                break;
            case DAC_MESSAGING_IDS.METHOD_INSTALL_WIDGET:
                console.debug(message, message.successData);
                this.resourceInstallerHandler.upgradeWdgDefAfterInstall(message);
                break;
            case DAC_MESSAGING_IDS.METHOD_UPGRADE_WIDGET:
                console.debug(message, message.successData); //message.successData will contain refreshed widget infos
                this.resourceInstallerHandler.upgradeWdgDefAfterInstall(message);
                break;
        }
    }

    private rejectDacMessage(errMsg: string, messageMethod: string): void {
        this.resourceInstallerHandler.upgradeWdgDefAfterError(errMsg, messageMethod);
    }

}


