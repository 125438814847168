<mat-toolbar>
    <mat-toolbar-row *ngIf="uiAspectService.isPrimaryHeaderVisible()" class="main-header">
        <span>
            <a href="/home" [ngClass]="showWatermark ? 'home-link-dev' : 'home-link'" (click)="categoryPathService.resetSelectedPath()">
                <img *ngIf="!showWatermark" src="/assets/images/logo-decisyon.png">
                <img *ngIf="showWatermark" src="/assets/images/logo-decisyon-DEV.png">
            </a>
        </span>
        <span class="item-spacer"></span>
        <ng-container *ngIf="isLoggedIn()">
            <span><button mat-button class="nav-link" routerLink="profile">{{ username }}</button></span>
            <button mat-icon-button (click)="logout()">
                <mat-icon>logout</mat-icon>
            </button>
        </ng-container>
    </mat-toolbar-row>
    <mat-toolbar-row class="dense-row primary">
        <button mat-icon-button *ngIf="showBackButton()" (click)="backToHome()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span *ngIf="routingContext.isToShowSelectedCategoryButton">
            <button mat-button class="path-link"
                (click)="returnToList()">{{categoryPathService.selectedPath?.name}}
            </button>
        </span>
        <span *ngIf="routingContext.isToShowHeaderTitle" class="header-title">
            {{ headerTitle | translate }}
        </span>
        <span class="item-spacer"></span>
        <span *ngIf="routingContext.isToShowTextSearch" class="search-container">
            <mat-form-field class="search-form-field" [floatLabel]="'auto'" appearance="fill" color="accent">
                <input matInput placeholder="Search" [(ngModel)]="resourceSearchService.textSearch" autocomplete="off" />
                <button *ngIf="resourceSearchService.textSearch"
                        matSuffix mat-icon-button
                        (click)="resourceSearchService.textSearch=''">
                    <mat-icon>close</mat-icon>
                </button>
                <button *ngIf="!resourceSearchService.textSearch" matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </span>
        <button *ngIf="routingContext.isToShowListGridButton && categoryPathService.listViewEnabled"
            (click)="enableGridView()"
            mat-icon-button
            matTooltip="Cards view">
            <mat-icon>grid_on</mat-icon>
        </button>
        <button *ngIf="routingContext.isToShowListGridButton && !categoryPathService.listViewEnabled"
            (click)="enableListView()"
            mat-icon-button
            matTooltip="List view">
            <mat-icon>format_list_bulleted</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="isMenuVisible()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button *ngIf="canCreateResource()" mat-menu-item (click)="createResource()">
                <mat-icon>addchart</mat-icon>
                <span>New Widget</span>
            </button>
            <input #hiddenfileinput style="display: none" type="file" (change)="cloneResource($event)">
            <button *ngIf="canCloneResource()" mat-menu-item (click)="hiddenfileinput.click()">
                <mat-icon>control_point_duplicate</mat-icon>
                <span>Clone Widget</span>
            </button>
            <button *ngIf="canViewSettings()" mat-menu-item (click)="showSettingsSection()">
                <mat-icon>settings</mat-icon>
                <span>{{'WdgHub.HeaderTitleSettings' | translate}}</span>
            </button>
        </mat-menu>
    </mat-toolbar-row>
        <mat-progress-bar *ngIf="loadingStatusService.isLoading" mode="indeterminate"></mat-progress-bar>
</mat-toolbar>


<div class="authUserBar">
    <ng-container *ngIf="isLoggedIn()">
        <span><a href="/profile" routerLink="profile">&nbsp;&nbsp;&nbsp;</a></span>
        <button mat-icon-button (click)="logout()">
            <mat-icon></mat-icon>
        </button>
    </ng-container>
</div>
