import { User } from "./User";

export class AnonymousUser extends User {
    constructor() {
        super();
        this.id = 'anonymous';
        this.name = 'anonymous';
        this.surname = 'anonymous';
        this.username = 'anonymous';
    }
}
