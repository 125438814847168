import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { LoadingStatusService } from '../../../../../services/loading/loading-status.service';
import { ResourceService } from '../../../services/resource.service';
import { RoleName } from "../../../../../security/entities/role/RoleName";
import { PermissionService } from "../../../../../security/services/permission/permission.service";

@Component({
    selector: 'app-rd-description',
    templateUrl: './rd-description.component.html',
    styleUrls: ['./rd-description.component.scss']
})
export class RdDescriptionComponent implements OnInit {

    isInEdit = false;
    text: string | undefined;

    constructor(public authService: AuthService,
        public resourceService: ResourceService,
        public permissionService: PermissionService,
        private loadingStatusService: LoadingStatusService) {
    }

    ngOnInit(): void {
    }

    saveDescription() {
        this.loadingStatusService.startLoading();
        this.resourceService.updateResource(this.resourceService.getResourceInEdit()?.id, {
            description: this.text
        }).then(res => {
            this.isInEdit = false;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.loadingStatusService.stopLoading();
        });
    }

    goInEdit() {
        this.text = this.resourceService.getResourceInEdit()?.description;
        this.isInEdit = true;
    }

    discardChanges() {
        this.isInEdit = false;
    }

    public isDwpLoaded(): boolean {
        return !!this.resourceService.getResourceInEdit()?.resourceDefined;
    }

    isEditable(): boolean {
        return this.authService.isLoggedIn && this.permissionService.hasAnyRole([RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR]);
    }

}
