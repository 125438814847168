import { Injectable } from '@angular/core';
import { DacCommunicationLayerService } from '../dacAPIs/dac-communication-layer.service';
import { DAC_MESSAGING_IDS } from '../dacAPIs/dac-messages-ids';
import { ResourceInstallerHandlerService } from '../services/resource/resource-installer-handler.service';
import { ThemeService } from '../services/theme/theme.service';
import { SystemSettingService } from '../entities/setting/system/service/system-setting.service';
import { DcyTranslateService } from '@decisyon/dcy-translate-lib';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentBootstrapperService {

    dacEnvironmentInfo: any;
    constructor(private dacCommunicationService: DacCommunicationLayerService,
        private resourceInstallerHandler: ResourceInstallerHandlerService,
        private themeService: ThemeService,
        private systemSettingService: SystemSettingService,
        private dcyTranslateService: DcyTranslateService
    ) { }

    init(): Promise<Boolean> {
        return new Promise<Boolean>(async (bootstrapPromiseResolve) => {
            try {
                await this.dcyTranslateService.hasLoaded.getPromise();
            } catch(error) {
                console.log('WdgHub: error loading translations:', error);
            } finally {
                this.dacCommunicationService.bindDacReference(this, this.resourceInstallerHandler);
                if (this.dacCommunicationService.isEmbeddedOnDac()) {
                    this.initEmbeddOnDAC(bootstrapPromiseResolve)
                } else {
                    this.initWebSite(bootstrapPromiseResolve);
                }
            }

        });
    }

    private initEmbeddOnDAC(bootstrapPromiseResolve: (value: (PromiseLike<Boolean> | Boolean)) => void) {
        const systemSettingsPromise = this.systemSettingService.getThemeSettings();
        systemSettingsPromise
            .catch(rejects => {
                console.error('Unable to retrieve theme setting. Default value will be applied', rejects);
                this.themeService.setThemeVariables('hub');
            });
        //SC: timeout so short because using postMessage to exchange communications, when opened website in another iframe, website waits for know if is inside in dac or not (e.g. sometimes happened in gmail)
        const envInfoPromise = this.dacCommunicationService
            // .sendMessageAndWait(DAC_MESSAGING_IDS.METHOD_GET_ENV_INFO, null, undefined, undefined,8000);
            .sendMessageAndWait(DAC_MESSAGING_IDS.METHOD_GET_ENV_INFO, null,8000);
        envInfoPromise
            .catch(rejects => {
                console.error('Timeout communication with frame. Communication with dac frame will be disabled', rejects);
                this.dacCommunicationService.setDacAccessAllowed(false);
                bootstrapPromiseResolve(true);
            });
        Promise.all([envInfoPromise, systemSettingsPromise])
            .then((values) => {
                // theme
                const themeSetting = values[1];
                this.themeService.setThemeVariables('hub', themeSetting);
                console.log("themeSetting: ", themeSetting);
                // env info
                this.resourceInstallerHandler.setCommunicationServiceInstance(this.dacCommunicationService);
                console.info('Bootstrap infos received from DAC:', JSON.stringify(this.dacEnvironmentInfo));
                bootstrapPromiseResolve(true);
                this.resourceInstallerHandler.reloadInstalledResources(true);
            });
    }

    private initWebSite(bootstrapPromiseResolve: (value: (PromiseLike<Boolean> | Boolean)) => void) {
        console.info('Bootstrap concluded outside of dac');
        this.systemSettingService.getThemeSettings()
            .then((themeSetting) => {
                console.log("themeSetting: ", themeSetting);
                this.themeService.setThemeVariables('hub', themeSetting);
                bootstrapPromiseResolve(true);
            })
            .catch((rejects) => {
                console.error('Unable to retrieve theme setting. Default value will be applied', rejects);
                this.themeService.setThemeVariables('hub');
                bootstrapPromiseResolve(true);
            });
    }

}
