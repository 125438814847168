export class User {

	accessToken = '';
	refreshToken = '';
	type = 'Bearer';
	id?: string;
	name?: string;
	surname?: string;
	username?: string;
	password?: string;
	newPassword?: string;
	email?: string;
	roles = new Array<string>();

}
