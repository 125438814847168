import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/security/services/auth/auth.service';
import { LoadingStatusService } from '../../../../../services/loading/loading-status.service';
import { ResourceService } from '../../../services/resource.service';
import { RoleName } from "../../../../../security/entities/role/RoleName";
import { PermissionService } from "../../../../../security/services/permission/permission.service";

@Component({
    selector: 'app-rd-manuals',
    templateUrl: './rd-manuals.component.html',
    styleUrls: ['./rd-manuals.component.scss']
})
export class RdManualsComponent implements OnInit {

    isInEdit = false;

    @ViewChild("linkManuals") linkManuals!: ElementRef;
    @ViewChild("linkWhatsNew") linkWhatsNew!: ElementRef;
    @ViewChild("linkChangelog") linkChangelog!: ElementRef;

    constructor(public authService: AuthService,
        public resourceService: ResourceService,
        public permissionService: PermissionService,
        private loadingStatusService: LoadingStatusService) { }

    ngOnInit(): void {
    }

    goInEdit() {
        this.isInEdit = true;
    }

    discardChanges() {
        this.isInEdit = false;
    }

    saveChanges() {
        this.loadingStatusService.startLoading();
        this.resourceService.updateResource(this.resourceService.getResourceInEdit()?.id, {
            linkManuals: this.linkManuals.nativeElement.value,
            linkWhatsNew: this.linkWhatsNew.nativeElement.value,
            linkChangelog: this.linkChangelog.nativeElement.value
        }).then(res => {
            this.isInEdit = false;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.loadingStatusService.stopLoading();
            this.resourceService.reloadResources();
        });
    }

    isToShow() {
        return !this.authService.isGuestUser() ||
            this.resourceService.getResourceInEdit()?.linkManuals ||
            this.resourceService.getResourceInEdit()?.linkWhatsNew ||
            this.resourceService.getResourceInEdit()?.linkChangelog;
    }

    public isDwpLoaded(): boolean {
        return !!this.resourceService.getResourceInEdit()?.resourceDefined;
    }

    isEditable(): boolean {
        return this.authService.isLoggedIn && this.permissionService.hasAnyRole([RoleName.ROLE_ADMIN, RoleName.ROLE_EDITOR]);
    }

}
