<div class="card-wrapper">
    <mat-card class="resource-card" [ngClass]="{'less-bottom-padding':!authService.isGuestUser()}">
        <div class="image-container">
            <img mat-card-image (click)="showResourceDetail(resourceInfo?.id)" [src]="noImage ? (resourcePreviewUri) : (resourcePreviewUri | secure | async)"
                onerror="this.onerror=null;this.src='/assets/images/no-image.jpg';">
        </div>
        <mat-card-content *ngIf="false">
            <p>
                {{resourceInfo?.description}}
            </p>
        </mat-card-content>

        <mat-card-content>
            <mat-card-title>
                <span class="title">{{resourceInfo?.name}}</span>
                <span class="item-spacer"></span>
                <button *ngIf="isToShowInstall()" mat-icon-button (click)="installResource($event)"
                    [disabled]="isInstallationBlocked()" [matTooltip]="getInstallToolTip()">
                    <mat-icon *ngIf="!isInstallingStatus">downloading</mat-icon>
                    <mat-icon *ngIf="isInstallingStatus" class="installing-rotating-icon">
                        <mat-spinner diameter="24" color="accent"></mat-spinner>
                    </mat-icon>
                </button>
                <div *ngIf="isToShowUpgrade()" [matTooltip]="getUpgradeToolTip()">
                    <button mat-icon-button (click)="upgradeResource($event)"
                        [disabled]="isInstallationBlocked()" [color]="isInstallationBlocked() ? '' : 'accent'">
                        <mat-icon *ngIf="!isInstallingStatus">add_task</mat-icon>
                        <mat-icon *ngIf="isInstallingStatus" class="installing-rotating-icon">
                            <mat-spinner diameter="24" color="accent"></mat-spinner>
                        </mat-icon>
                    </button>
                </div>
                <button *ngIf="isToShowDowngrade()" mat-icon-button (click)="downgradeResource($event)"
                    [disabled]="isInstallationBlocked()" [matTooltip]="getDowngradeToolTip()">
                    <mat-icon *ngIf="!isInstallingStatus">history</mat-icon>
                    <mat-icon *ngIf="isInstallingStatus" class="installing-rotating-icon">
                        <mat-spinner diameter="24" color="accent"></mat-spinner>
                    </mat-icon>
                </button>
                <div *ngIf="isToShowInstalled()" [matTooltip]="getInstalledToolTip()">
                    <button disabled mat-icon-button class="resource-already-installed">
                        <mat-icon>task_alt</mat-icon>
                    </button>
                </div>
                <div *ngIf="isToShowInstallAndChangelog()" [matTooltip]="getUpdateAndChangelogToolTip(resourceInfo?.linkChangelog)">
                    <button (click)="openLink(resourceInfo?.linkChangelog, $event)" mat-icon-button
                        [disabled]="!resourceInfo?.linkChangelog"
                        [color]="resourceInfo?.linkChangelog ? 'accent' : ''">
                        <mat-icon fontSet="material-icons-outlined">description</mat-icon>
                    </button>
                </div>
            </mat-card-title>
            <mat-card-subtitle *ngIf="this.isToShowStateColumn()"
                [ngClass]="resourceStateService.getStateClass(resourceInfo?.state)">{{resourceStateService.getStateDescription(resourceInfo?.state)}}</mat-card-subtitle>
        </mat-card-content>
    </mat-card>
</div>
